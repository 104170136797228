import types from "../actions/types";
const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case types.GET_ERRORS:
            return action.payload;
        default:
            return state;
    }
}