import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  ButtonGroup,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Snackbar
} from "@material-ui/core";
import { SkipPrevious, SkipNext, Comment, Brightness2, Brightness4, Forum } from "@material-ui/icons";
import Modal from "../modal/modal";
import CommentsModal from './comment-modal';
import QuestionFeedback from './question-feedback';
import "../exam-header/exam-header.scss";
import themes from '../../constants/themes';
import { useDispatch, useSelector } from "react-redux";
import { setPreference, resetQuesFeedback } from "../../actions";
const logo = require("../../images/logo_dark.png").default;

const ExamHeader = (props) => {
  const {
    onSelectQuestion,
    currentQuestion,
    questionData,
    onTextZoom,
    examHistoryData,
    onExamHistoryChange,
    isReview,
    feedbackOpen,
    onFeedbackClose,
    questionMetaData,
    goToHomePage
  } = props;
  const [currentQuestionNum, setCurrentQuestionNum] = useState(currentQuestion);
  const [marked, setMarked] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isTutor, setIsTutor] = useState(false);
  const [isCommentModal, setIsCommentModal] = useState(false);
  const [quesFeedbackModal, setQuesFeedbackModal] = useState(false);
  const [currentFont, setCurrentFont] = useState('smallFont');
  const [currentTheme, setCurrentTheme] = useState('light');
  const [open, setOpen] = useState(false);
  const [feedbackErr, setFeedbackErr] = useState(false);

  const dispatch = useDispatch();

  const quesFeedbackRes = useSelector((state) => {
    return state.setQuesFeedback;
  });

  const userPreferenceData = useSelector((state) => {
    return state.setUserPreference?.preference || state.getUserPreference?.preference;
  });

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setFeedbackErr(false), 500);
  };

  useEffect(() => {
    const role = window.localStorage.getItem('role');
    setIsStudent(role === 'ROLE_STUDENT' || role === 'ROLE_TUTOR');
    setIsTutor(role === 'ROLE_TUTOR');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const id = window.localStorage.getItem("userId");

    if (id) {
      const newTheme = userPreferenceData?.darkTheme ? 'dark' : 'light';
      setCurrentTheme(newTheme);
      setTheme(newTheme);
    }
  }, [dispatch, userPreferenceData]);

  useEffect(() => {
    if (feedbackOpen) {
      setQuesFeedbackModal(true);
    }
  }, [feedbackOpen]);


  useEffect(() => {
    if (quesFeedbackRes?.quesFeedback && quesFeedbackRes?.loading === false) {
      if (quesFeedbackRes?.errorMsg) {
        setFeedbackErr(quesFeedbackRes?.errorMsg);
      }
      setOpen(true)
      dispatch(resetQuesFeedback());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quesFeedbackRes]);

  useEffect(() => {
    const number = parseInt(currentQuestion);
    setCurrentQuestionNum(number);
    setMarked(questionMetaData?.[currentQuestion - 1]?.marked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion, questionMetaData]);

  const onThemeChange = () => {
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    setCurrentTheme(newTheme);
    setTheme(newTheme);
    const userId = window.localStorage.getItem("userId");
    dispatch(setPreference({ userId, body: { darkTheme: newTheme === 'dark' } }));
  };

  const setTheme = (newTheme) => {
    const theme = themes[newTheme];
    Object.keys(theme).forEach((key) => {
      const cssKey = `--${key}`;
      const cssValue = theme[key];
      document.body.style.setProperty(cssKey, cssValue);
    });
  }

  const handleChange = (event) => {
    const val = event.target.checked;
    setMarked(val);
    const questionId = questionData[currentQuestion - 1].questionId;

    if (!examHistoryData[questionId]) {
      examHistoryData[questionId] = {};
    }

    examHistoryData[questionId].marked = val;
    questionData[currentQuestion - 1].marked = val;
    questionMetaData[currentQuestion - 1].marked = val;

    onExamHistoryChange(examHistoryData, "marked", questionData);
  };

  const goToPreviousPage = () => {
    const number = currentQuestionNum - 1;
    setCurrentQuestionNum(number);
    onSelectQuestion(number);
  };
  const toToNextPage = () => {
    const number = currentQuestionNum + 1;
    setCurrentQuestionNum(number);
    onSelectQuestion(number);
  };

  const changeFont = (className) => {
    onTextZoom(className);
    setCurrentFont(className);
  };

  const onModalStateChange = (state) => {
    setIsCommentModal(state);
  };

  const goToHome = () => {
    goToHomePage();
  };

  const onQuesFeedbackStateChange = (state) => {
    setQuesFeedbackModal(state);
    if (!state) {
      onFeedbackClose(false);
    }
  };

  const GreenCheckbox = withStyles({
    root: {
      color: "#fff",
    },
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <header className="header-container">
      <Snackbar open={open} onClose={handleClose} autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <>
          {!feedbackErr &&
            <Box className="success-alert">
              Feedback has been sent successfully. Thank you...
            </Box>
          }
          {feedbackErr &&
            <Box className="success-warn">
              {feedbackErr}
            </Box>
          }
        </>
      </Snackbar>

      <Grid container alignItems="center">
        <Grid container item xs={3} sm={3} direction="row" alignItems="center">
          <Grid item xs={12} sm={7} md={6} lg={4} className="blockContainer">
            <div>
              Item: {currentQuestion} of {questionData.length}
            </div>
            <div>Block: 1 of 1</div>
          </Grid>
          <Grid item xs={12} sm={5} md={6} lg={8} className="mark-cont">
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={marked}
                  onChange={handleChange}
                  name="checkedG"
                />
              }
              label="Mark"
            />
          </Grid>
        </Grid>
        <Grid item xs={5} sm={4} container justifyContent="center" className="next-btn-wrap">
          <img alt="logo" src={logo} width='60' height='50' className="med-logo1"></img>
          <Box component="div">
            <IconButton
              disabled={currentQuestionNum === 1}
              className="nextBtn"
              onClick={goToPreviousPage}
            >
              <SkipPrevious />
              Previous
            </IconButton>
            <IconButton
              className="nextBtn"
              disabled={currentQuestionNum === questionData.length}
              onClick={toToNextPage}
            >
              <SkipNext />
              Next
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={4} sm={5} container justifyContent="flex-end" className="font-btn-wrap">
          <Box>
            {isReview && isStudent && !isTutor &&
              <IconButton
                className="nextBtn"
                onClick={() => onQuesFeedbackStateChange(true)}
                title="Question Feedback"
              >
                <Forum />
              </IconButton>
            }

            {!isStudent &&
              <IconButton
                className="nextBtn"
                onClick={() => onModalStateChange(true)}
              >
                <Comment />
                Comment
              </IconButton>
            }

            <IconButton className="nextBtn marg-rt" onClick={() => onThemeChange()} title="Theme">
              {currentTheme === 'light' && <Brightness2 />}
              {currentTheme !== 'light' && <Brightness4 />}
            </IconButton>
          </Box>

          <Box component="div" m={1}>
            <ButtonGroup color="primary" size="small" className="fontContainer">
              <Button
                className={`smallFont fontBtn ${currentFont === 'smallFont' ? 'active-font-btn' : null}`}
                onClick={() => changeFont("smallFont")}
              >
                A
              </Button>
              <Button
                className={`mediumFont fontBtn ${currentFont === 'mediumFont' ? 'active-font-btn' : null}`}
                onClick={() => changeFont("mediumFont")}
              >
                A
              </Button>
              <Button
                className={`largeFont fontBtn ${currentFont === 'largeFont' ? 'active-font-btn' : null}`}
                onClick={() => changeFont("largeFont")}
              >
                A
              </Button>
            </ButtonGroup>
            <Box component="div" className="mediumFont">
              Text Zoom
            </Box>
          </Box>
          <Box>
            <img alt="logo" src={logo} width='60' height='50' className="med-logo go-to-home" onClick={goToHome}></img>
          </Box>
        </Grid>
      </Grid>
      {isCommentModal && (
        <Modal modalBody={
          <CommentsModal
            questionData={questionData}
            currentQuestion={currentQuestion}
            changeCommentModalStatus={onModalStateChange} />}></Modal>
      )}
      {quesFeedbackModal && (
        <Modal clsNm={`ques-feedbk-modal ${isTutor ? ' tutor-mode' : ''}`} modalBody={
          <QuestionFeedback
            className="ques-feedbk-modal"
            questionData={questionData}
            currentQuestion={currentQuestion}
            changeQuesFeedbackStatus={onQuesFeedbackStateChange} />}></Modal>
      )}
    </header>
  );
};

export default ExamHeader;
