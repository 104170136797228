import jwt_decode from "jwt-decode";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// then run the saga
sagaMiddleware.run(rootSaga);

export default store;
let decodedToken, token, isExpired = false;

// Interceptor
(function() {
    const originalFetch = window.fetch;
    window.fetch = function(url, options) {
        let mainArguments = arguments;

        if (!url.match("/auth") && !url.match(".segment") && !url.match("/js/") ) {
            if (!options) {
                options = {
                    headers: {},
                };
                mainArguments = Array.prototype.slice.call(arguments);
                mainArguments.push(options);
            }

            token = window.localStorage.getItem("token");

            if (token) {
                decodedToken = jwt_decode(token);
                isExpired = new Date().getTime() >= decodedToken.exp * 1000;
            }

            if (!token || isExpired) {
                window.localStorage.clear();
                window.location = "/";
                return;
            }

            options.headers["Authorization"] = "Bearer " + token;
        }

        return originalFetch.apply(this, mainArguments);
    };
})();