import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import AppRoutes from './routes';
import mixpanel from 'mixpanel-browser';
import './App.scss';
import Hotjar from '@hotjar/browser';

const App = () => {

    // Mixpanel 
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
    // Hotjar
    const siteId = process.env.REACT_APP_HOTJAR_KEY;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;
    Hotjar.init(siteId, hotjarVersion);

    return (
        <Provider store={store}>
            <AppRoutes />
        </Provider>
    );
}

export default App;