export const CONSTANTS = {
    roles: [{
        value: "student",
        label: "Student",
    },
    {
        value: "tutor",
        label: "Tutor",
    }
    ],
    errorMsgs: {
        400: "Email already exists, please use another.",
        401: "Email or password does not match.",
        500: "Something went wrong. Please try again."
    },
    subscriptionType: [
        {
            name: 'BASIC',
            features: [
                "Limited PDF Access",
                "Storage for uploading documents: 400MB",
                "Page views per month: 300"
            ]
        },
        {
            name: 'PREMIUM',
            features: [
                "PRO PDF Access",
                "Storage for uploading documents: 1GB",
                "Page views per month: 1000"
            ]
        }
    ],
    durationObj: {
        '7': '1 week',
        '30': '1 month',
        '90': '3 months',
        '180': '6 months',
        '360': '1 Year',
    },
    maxImageZoom: 1200,
    minImageZoom: 200,

    examTypeList: [
        {
            name: 'ABSITE',
            value: 'ABSITE'
        },
        {
            name: 'Step 1',
            value: 'Step_1'
        },
        {
            name: 'Step 2',
            value: 'Step_2'
        },
        {
            name: 'Step 3',
            value: 'Step_3'
        },
        {
            name: 'MCAT',
            value: 'MCAT'
        },
        {
            name: 'COMLEX',
            value: 'COMLEX'
        },
        {
            name: 'OMM',
            value: 'OMM'
        },
        {
            name: 'ABR Core',
            value: 'ABR_CORE'
        },
        {
            name: 'ABIM',
            value: 'ABIM'
        },
        {
            name: 'ABFM',
            value: 'ABFM'
        }
    ],
    planStatusType: [{
        name: 'Active',
        value: 'ACTIVE'
    }, {
        name: 'Inactive',
        value: 'INACTIVE'
    }, {
        name: 'Deprecated',
        value: 'DEPRECATED'
    }]
};