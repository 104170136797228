import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  FormControlLabel,
  Box,
  RadioGroup,
  Radio,
  Button,
  Tooltip,
  Modal,
  Fade,
  Backdrop,
  IconButton
} from "@material-ui/core";
import { Check, Clear, ArrowBackIos, Visibility, ZoomIn, ZoomOut } from "@material-ui/icons";
import ExamReviewDrawer from "../exam-review-drawer/exam-review-drawer";
import { TokenAnnotator } from 'react-text-annotate';
import { CONSTANTS } from "../../constants/app-constant";
import "./exam-question-container.scss";

const pdfQuesData = [{
  page: 10,
  text: 'transfusion'
}, {
  page: 11,
  text: 'Circulatory changes at birth'
}];
let hasAnswered = [];

const ExamQuestionContainer = (props) => {
  const {
    currentQuestionNum,
    questionData,
    dataLength,
    onProceedToNextQuestion,
    isReview,
    isTutor,
    isGuide,
    examHistoryData,
    onExamHistoryChange,
    questionMetadata,
    quizHistoryData,
    onShowEndBlock,
    imageData,
    onFeedbackOpen,
    strikedHistoryData,
    onStrikeDataChange
  } = props;
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [quesMetadata, setQuesMetadata] = useState({});
  const [quizHistory, setQuizHistory] = useState({});
  const [pdfData, setPdfData] = useState(pdfQuesData[0]);
  const [openReviewDrawer, setOpenReviewDrawer] = useState(true);
  const [hideAnswer, setHideAnswer] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [isTutorRole, setIsTutorRole] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [highlightValues, setHighlightValues] = useState({});
  const [strikeValues, setStrikeValues] = useState([]);

  const imgRef = useRef(null);

  useEffect(() => {
    const role = window.localStorage.getItem('role');
    setIsTutorRole(role === 'ROLE_TUTOR');
  }, []);


  useEffect(() => {
    hasAnswered = questionMetadata.filter((item) => examHistoryData[item.id]?.answerGiven);

    if (!isReview) {
      setSelectedAnswer(examHistoryData[questionData?.questionId]?.answerGiven);
    }

    if (isTutor && !isReview) {
      setOpenReviewDrawer(false);
    }

    setStrikeValues(strikedHistoryData[questionData.questionId] || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData]);

  useEffect(() => {
    if ((isReview || isTutor) && questionMetadata && questionMetadata.length) {
      const currentQues = questionMetadata.filter(
        (item) => item.id === questionData?.questionId
      );
      setQuesMetadata(currentQues[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionMetadata, questionData]);

  useEffect(() => {
    if (isReview && quizHistoryData && quizHistoryData.length) {
      const currentQues = quizHistoryData.filter(
        (item) => item.questionId === questionData?.questionId
      );
      setQuizHistory(currentQues[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizHistoryData, questionData, currentQuestionNum]);

  const handleChange = (event, selectedValue = '') => {
    if (selectedValue && !!isReview) {
      return;
    }

    const val = event ? event.target.value : selectedValue;
    setSelectedAnswer(val);

    if (isTutor) {
      setOpenReviewDrawer(true);
    }

    if (!examHistoryData[questionData?.questionId || questionData?.id]) {
      if (!isTutor && currentQuestionNum === dataLength) {
        onShowEndBlock(true);
      }
      examHistoryData[questionData?.questionId || questionData?.id] = {};
    }

    examHistoryData[questionData?.questionId || questionData?.id].answerGiven = val;

    onExamHistoryChange(examHistoryData);
  };

  const proceedToNextQuestion = () => {
    onProceedToNextQuestion();
  };

  const onOpenReviewDrawerToggle = () => {
    setOpenReviewDrawer(!openReviewDrawer);
  };

  const onHideAnswerToggle = () => {
    setHideAnswer(!hideAnswer);
  };

  const onStrikeAnswerToggle = (index) => {
    const arr = strikedHistoryData[questionData.questionId] || [...strikeValues];
    const valIndex = arr.indexOf(index);

    if (valIndex === -1) {
      arr.push(index);
    } else {
      arr.splice(valIndex, 1);
    }

    const updatedData = {
      ...strikedHistoryData,
      [questionData.questionId]: arr
    }

    onStrikeDataChange(updatedData);
    setStrikeValues([...arr]);
  };

  const onImageZoom = (isZoomIn) => {
    const imgHeight = imgRef.current.clientHeight;

    if ((!isZoomIn && imgHeight < CONSTANTS.minImageZoom) || (isZoomIn && imgHeight > CONSTANTS.maxImageZoom)) {
      return;
    }

    if (isZoomIn) {
      imgRef.current.style.height = (imgHeight + 100) + "px";
    } else {
      imgRef.current.style.height = (imgHeight - 100) + "px";
    }
  };

  // const onOpenPdf = () => {
  //   setOpenPdf(true);
  //   setPdfData(pdfQuesData[(currentQuestionNum - 1) % 2]);
  // };

  const setQuesHighlight = (value) => {
    const localVal = JSON.parse(JSON.stringify(highlightValues));
    localVal[questionData?.questionId] = value;
    setHighlightValues(localVal);
  };

  if (!questionData) {
    return "Loading...";
  }

  const selectedAns =
    isReview && quizHistory && quizHistory.answerGiven
      ? quizHistory.answerGiven
      : selectedAnswer;

  return (
    <Box component="div" className="container">
      {!isGuide &&
        <Box component="div" m={1} className="question-wrapper">
          {/* <Box component="div" m={1} className="question-div" onClick={onOpenPdf}> */}
          <Box component="div" m={1} className={isTutorRole ? 'user-tutor question-div' : 'question-div'}>
            <Box className="watermark"></Box>
            {isTutorRole &&
              <TokenAnnotator
                tokens={questionData["stem"]?.split(' ')}
                value={highlightValues[questionData?.questionId] || []}
                onChange={value => setQuesHighlight(value)}
                getSpan={span => ({
                  ...span,
                  color: '#f3f311'
                })}
              />
            }
            {!isTutorRole &&
              <>{questionData["stem"]}</>
            }
          </Box>
          {imageData?.length > 0 &&
            <Box onClick={() => setOpenImageModal(true)} className="ques-img-cont">
              <img src={imageData} alt="" className="img-cont" />
            </Box>
          }
          <Box component="div" className="answer-container">
            <FormControl component="fieldset" className="answer-box">
              <RadioGroup
                name="answer"
                value={selectedAns?.trim()}
                onChange={(evt) => handleChange(evt)}
              >
                {questionData?.option?.optionList?.map((item, index) => {
                  return (
                    <Box component="div" className="answer-wrapper" key={index}>
                      {((isReview && !hideAnswer) || (isTutor && selectedAnswer)) && (
                        <>
                          {quesMetadata?.option?.correctAnswer?.toLowerCase() === item?.toLowerCase() && (
                            <Check className="correct-icon"></Check>
                          )}
                          {quesMetadata?.option?.correctAnswer?.toLowerCase() !== item?.toLowerCase() && (
                            <Clear className="incorrect-icon"></Clear>
                          )}
                        </>
                      )}

                      {!hideAnswer &&
                        <FormControlLabel
                          className="ans-label-rad"
                          value={item.trim()}
                          disabled={!!isReview}
                          control={<Radio color="primary" />}
                        />
                      }
                      <Box component="span" className="ans-char">{String.fromCharCode(65 + (index))}. &nbsp;</Box>
                      <Box onClick={() => handleChange(null, item)}
                        className={`ans-label ${strikeValues.indexOf(index) > -1 ? "strike-ans" : null} ${!!isReview ? null : 'ans-pointer'} `}
                        onContextMenu={() => onStrikeAnswerToggle(index)}>{item}</Box>
                    </Box>
                  );
                })}
              </RadioGroup>
              {isReview &&
                <Tooltip title={`${hideAnswer ? 'Show Answers' : 'Hide Answers'}`}>
                  <Visibility onClick={onHideAnswerToggle}
                    className={`eye-icon ${hideAnswer ? 'selected' : 'disabled'}`}></Visibility>
                </Tooltip>
              }
            </FormControl>

            {currentQuestionNum !== dataLength &&
              <Button
                variant="contained"
                color="primary"
                className="proceed-btn"
                onClick={proceedToNextQuestion}
              >
                Proceed to Next Question
              </Button>
            }
          </Box>
          <Box className="ques-id">{questionData.questionId}</Box>
        </Box>
      }
      {(isReview || ((selectedAnswer || hasAnswered.length > 0) && isTutor)) && (
        <Box
          component="div"
          className={`drawer ${openReviewDrawer ? "selected" : "dismiss"}`}
        >
          <Box component="div" onClick={onOpenReviewDrawerToggle} className="drawer-close-btn">
            <ArrowBackIos />
          </Box>
          <ExamReviewDrawer
            questionData={quesMetadata}
            currentQuestionNum={currentQuestionNum}
            quizHistory={quizHistory}
            openDrawer={openReviewDrawer}
            isOpenPdf={openPdf}
            pdfData={pdfData}
            isTutor={isTutor}
            isReview={isReview}
            onPdfClose={() => setOpenPdf(false)}
            onFeedbackOpen={() => onFeedbackOpen(true)}
            onGuideOpen={() => setOpenReviewDrawer(false)}
          ></ExamReviewDrawer>
        </Box>
      )}

      <Modal
        className='image-enlarge-modal'
        open={openImageModal}
        onClose={() => setOpenImageModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openImageModal}>
          <Box className='modal-container'>
            <img src={imageData} alt="Qlearn" ref={imgRef} className="img-cont" />
            <Box className="zoom-btn-container">
              <IconButton className='zoom-btn' size="medium" title="Zoom Out" onClick={() => onImageZoom(false)}>
                <ZoomOut fontSize="medium" />
              </IconButton>
              <IconButton className='zoom-btn' size="medium" title="Zoom In" onClick={() => onImageZoom(true)}>
                <ZoomIn fontSize="medium" />
              </IconButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default ExamQuestionContainer;
