import React, { useState, useEffect, Fragment } from "react";
import {
  Box, List, ListItem, ListItemText, Typography,
  Divider, TextField, FormLabel, Button, Table, TableHead, Tooltip,
  TableRow, TableCell, TableBody, IconButton, Snackbar, Modal, Fade, Backdrop
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANTS } from "../../constants/app-constant";
import {
  getInvitations, sendInvitation, resendInvitation,
  resetSendInvitation, resetResendInvitation
} from "../../actions";
import moment from "moment";
import RefreshIcon from '@material-ui/icons/Refresh';
import "./freeMembership.scss";

const FreeMembership = () => {
  const [packageList, setPackageList] = useState([]);
  const [invitationList, setInvitationList] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState('BASIC');
  const [selectedSub, setSelectedSub] = useState(-1);
  const [email, setEmail] = useState('');
  const [hasEmailErr, setHasEmailErr] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [open, setOpen] = useState(false);
  const [generateModal, setGenerateModal] = useState(false);
  const dispatch = useDispatch();

  // const packageListRes = useSelector((state) => {
  //   return state.getPackageList;
  // });

  const invitationsListRes = useSelector((state) => {
    return state.invitationsList;
  });

  const sendInvitationRes = useSelector((state) => {
    return state.sendInvitation;
  });

  const resendInvitationRes = useSelector((state) => {
    return state.resendInvitation;
  });

  // useEffect(() => {
  //   if (!packageListRes?.packageList) {
  //     dispatch(getPackageList());
  //     dispatch(getInvitations());
  //   }

  //   if (packageListRes?.packageList) {
  //     setPackageList(packageListRes?.packageList);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [packageListRes?.packageList]);

  useEffect(() => {
    if (invitationsListRes?.invitationList) {
      setInvitationList(invitationsListRes?.invitationList);
    }
  }, [invitationsListRes?.invitationList]);

  useEffect(() => {
    if (sendInvitationRes?.sendInvitation) {
      setOpen(true);
      dispatch(resetSendInvitation());
      dispatch(getInvitations());
    }
  }, [dispatch, sendInvitationRes?.sendInvitation]);

  useEffect(() => {
    if (resendInvitationRes?.resendInvitation) {
      setOpen(true);
      dispatch(resetResendInvitation());
      dispatch(getInvitations());
    }
  }, [dispatch, resendInvitationRes?.resendInvitation]);

  const onSubscriptionSelect = (item, index) => {
    setSelectedSub(index);
    setSelectedPackage(item);
  };

  const onSubTypeSelction = (type) => {
    setSelectedSubType(type.name);
  };

  const onEmailTextChange = (evt) => {
    const val = evt.target.value;
    setEmail(val);

    if ((/\S+@\S+\.\S+/i).test(val)) {
      setHasEmailErr(false);
    } else {
      setHasEmailErr(true);
    }
  };

  const onGenerateBtnClck = () => {
    handleGenerateModal(false);
    const param = {
      email,
      packageId: selectedPackage.id
    };
    dispatch(sendInvitation(param));
  };

  const onResendBtnClck = (inivitaionId) => {
    dispatch(resendInvitation(inivitaionId));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGenerateModal = (isOpen) => {
    setGenerateModal(isOpen);

    if (isOpen) {
      setEmail('');
      setSelectedSubType('BASIC');
      setSelectedPackage({});
      setSelectedSub(-1);
    }
  };


  return (
    <Box className='enrollment-container'>
      <Snackbar open={open} onClose={handleClose} autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>

        <Box className="success-alert">
          Invitation has been sent successfully...
        </Box>
      </Snackbar>

      <Box className="enrollment-body">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Package Name</TableCell>
              <TableCell>Invitation Send Date</TableCell>
              <TableCell>Expiration Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitationList && invitationList?.length > 0 &&
              <>
                {invitationList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {row.email}
                    </TableCell>
                    <TableCell>
                      {row.invitedPackage.name}
                    </TableCell>
                    <TableCell>
                      {row.invitationSendDate && moment(row.invitationSendDate).format("ll")}
                    </TableCell>
                    <TableCell>
                      {row.expirationDate && moment(row.expirationDate).format("ll")}
                    </TableCell>
                    <TableCell>
                      {row.status}
                    </TableCell>
                    <TableCell>
                      {!row.acceptDate &&
                        <Tooltip title="Resend invitation">
                          <IconButton
                            onClick={() => onResendBtnClck(row.id)}
                          >
                            <RefreshIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </>
            }
            {(!invitationList || invitationList?.length === 0) &&
              <TableRow><TableCell colSpan="6" className="row-center">No data found</TableCell></TableRow>
            }
          </TableBody>
        </Table>
        <Button variant="contained" className="generate-modal-btn" color="primary" onClick={() => handleGenerateModal(true)}>
          Generate Invitaion Link
        </Button>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='coupon-modal sub-coupon-modal subscription-card invitation-modal'
        open={generateModal}
        onClose={() => handleGenerateModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={generateModal}>
          <Box className='modal-container'>
            <Typography variant="h5" className="header">Send Invitation</Typography>
            <Box>
              <Box>
                <Box className="sub-type-wrapper">
                  {CONSTANTS.subscriptionType.map((type, index) => {
                    return <Box key={index} className={selectedSubType === type.name ? 'selected' : ''}
                      onClick={() => onSubTypeSelction(type)}>
                      <Typography variant="subtitle2" gutterBottom>
                        {type.name}
                      </Typography>
                      {type.features.map((item, index1) => {
                        return <Typography key={index1} variant="caption" display="block">
                          {item}
                        </Typography>
                      })}
                    </Box>
                  })}
                </Box>

                <List component="nav" className="sub-list">
                  {packageList?.map((item, index) => {
                    return <Fragment key={index}>
                      {item.category === selectedSubType &&
                        <Fragment>
                          <ListItem button
                            selected={selectedSub === index}
                            onClick={() => onSubscriptionSelect(item, index)}>
                            <ListItemText primary={item.name}
                              secondary={<Typography variant="caption">
                                Quiz limit: {item.quizLimit}, Question Limit: {item.questionLimit}
                              </Typography>} />
                            <Box className="price-cont">
                              <Typography className="price-text">${item.price} </Typography>
                            </Box>
                          </ListItem>

                          <Divider />
                        </Fragment>
                      }
                    </Fragment>
                  })}
                </List>
              </Box>
              {selectedPackage?.name &&
                <Box className='email-container'>
                  <FormLabel className="email-label">Student's mail:</FormLabel>
                  <TextField
                    label=""
                    className="email-input"
                    error={hasEmailErr}
                    value={email}
                    helperText={hasEmailErr ? "Email not valid" : ''}
                    onChange={onEmailTextChange}
                    variant="outlined"
                  />
                </Box>
              }
            </Box>

            <Box className="apply-btn">
              <Button color="primary" onClick={() => handleGenerateModal(false)}>
                Cancel
              </Button>
              <Button variant="contained" color="primary"
                disabled={!email || hasEmailErr}
                onClick={onGenerateBtnClck}>
                Send Invite
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default FreeMembership;
