import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersByRole, getPaymentsByUser, refundBooking,
  cancelSubscription, resetRefundBooking, resetCancelSubscription
} from "../../actions";
import Menu from "../menu/menu";
import { ArrowForward } from "@material-ui/icons";
import moment from "moment";
import './admin.scss';

import {
  Box,
  Backdrop,
  Button,
  Snackbar,
  CircularProgress,
  TextField,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

export default function Refund() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [responseSuccessTxt, setResponseSuccessTxt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchTxt, setSearchTxt] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [userList, setUserList] = useState([]);

  const dispatch = useDispatch();

  const usersByRoleDataRes = useSelector((state) => {
    return state.getUsersByRole;
  });

  const paymentsByUserRes = useSelector((state) => {
    return state.getPaymentsByUser;
  });

  const cancelSubscriptionReq = useSelector((state) => {
    return state.cancelSubscription;
  });
  const refundBookingRes = useSelector((state) => {
    return state.refundBooking;
  });

  useEffect(() => {
    dispatch(getUsersByRole());
  }, [dispatch]);

  useEffect(() => {
    if (usersByRoleDataRes?.loading === false) {
      setUserList(usersByRoleDataRes?.usersByRole || []);
    }
  }, [usersByRoleDataRes]);

  useEffect(() => {
    if (paymentsByUserRes?.loading !== undefined) {
      setIsLoading(paymentsByUserRes.loading);
    }
  }, [paymentsByUserRes]);

  useEffect(() => {
    if (refundBookingRes?.loading !== undefined) {
      setIsLoading(refundBookingRes.loading);
    }
    if (refundBookingRes?.refundBooking?.id) {
      setResponseSuccessTxt('Plan amount successfully refunded...');
      setSnackbarOpen(true);
      dispatch(getPaymentsByUser(selectedUser.id));
      dispatch(resetRefundBooking());
    }
  }, [dispatch, refundBookingRes, selectedUser.id]);

  useEffect(() => {
    if (cancelSubscriptionReq?.loading !== undefined) {
      setIsLoading(cancelSubscriptionReq.loading);
    }

    if (cancelSubscriptionReq?.cancelSubscription?.id) {
      setResponseSuccessTxt('Plan has been cancelled successfully...');
      setSnackbarOpen(true);
      dispatch(getPaymentsByUser(selectedUser.id));
      dispatch(resetCancelSubscription());
    }
  }, [dispatch, cancelSubscriptionReq, selectedUser.id]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  }

  const onSearchTextChange = (e) => {
    const val = e.target.value;
    let userListData = JSON.parse(JSON.stringify(usersByRoleDataRes?.usersByRole));
    userListData = userListData?.filter((item) => item.email.includes(val));
    setUserList(userListData || []);
    setSnackbarOpen(setSearchTxt(val));
  }

  const getUserPaymentDetails = (item) => {
    setSelectedUser(item);
    dispatch(getPaymentsByUser(item.id));
  }

  const onActionSelection = (item, isRefund = true) => {
    if (!isRefund) {
      dispatch(cancelSubscription(item.id));
    } else {
      const payload = {
        refundAmount: item.price,
        refundReason: "REQUESTED_BY_CUSTOMER",
        bookingId: item.id
      }
      dispatch(refundBooking(payload));
    }
  }

  return (
    <Box component="div" className='body-cont-over'>
      <Menu />

      <Backdrop open={isLoading} className="quiz-loader">
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={snackbarOpen} onClose={handleSnackbarClose} autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Box className="success-alert">
          {responseSuccessTxt}
        </Box>
      </Snackbar>

      <Box className='admin-refund-container'>
        <Box className="search-list-cont">
          <TextField
            className="search-inpt"
            variant="outlined"
            label="Search User"
            onChange={onSearchTextChange}
            value={searchTxt}
          />

          <Box className="user-list-cont">
            <Box className="list-label">Users List:</Box>
            <Box className="user-list">
              <>
                {userList?.length > 0 && userList?.map((user, index) => {
                  return <Box key={index} className="list-item">
                    <Box>
                      <Box>Name: {user?.displayName}</Box>
                      <Box>Email: {user?.email}</Box>
                    </Box>
                    <IconButton
                      className="close-btn"
                      onClick={() => getUserPaymentDetails(user)}
                    >
                      <ArrowForward />
                    </IconButton>
                  </Box>
                })}
                {userList?.length === 0 &&
                  <Box className="no-user">No user found.</Box>
                }
              </>
            </Box>
          </Box>
        </Box>
        {paymentsByUserRes?.paymentsByUser &&
          <Box className="plan-table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Plan</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Order Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentsByUserRes?.paymentsByUser?.length > 0 &&
                  <>
                    {paymentsByUserRes?.paymentsByUser.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {row?.plan?.name}
                        </TableCell>
                        <TableCell>
                          ${row.price}
                        </TableCell>
                        <TableCell>
                          {row.status}
                        </TableCell>
                        <TableCell>
                          {row?.subscriptionStartDate && moment(row?.subscriptionStartDate).format("ll")}
                        </TableCell>
                        <TableCell>
                          {row.status === 'SUCCESS' &&
                            <Button variant="contained" color="primary" onClick={() => onActionSelection(row, false)}>
                              Cancel Plan
                            </Button>
                          }
                          {row.status === 'CANCELED' &&
                            <Button variant="contained" color="primary" onClick={() => onActionSelection(row)}>
                              Refund
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                }
                {paymentsByUserRes?.paymentsByUser?.length === 0 &&
                  <TableRow><TableCell colSpan="6" className="row-center">No billing history found for the user.</TableCell></TableRow>
                }
              </TableBody>
            </Table>
          </Box>
        }
      </Box>
    </Box>
  );
};

