import { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  CardActions,
  Button,
  Modal,
  Backdrop,
  Fade,
  CircularProgress
} from "@material-ui/core";
import { KeyboardBackspace, Close } from '@material-ui/icons';
import { CONSTANTS } from '../../constants/app-constant';
import ReactMarkdown from 'react-markdown';
import "./subscriptions-list.scss";

export default function SubscriptionsListView(props) {
  const { packageList, userData, onConfirmation, onPaymentLoadSuccess, showPlanDetailsModal, hidePlanDetailsModal } = props;
  const [selectedSub, setSelectedSub] = useState(-1);
  const [selectedPackage, setSelectedPackage] = useState({});
  // const [selectedSubType, setSelectedSubType] = useState('BASIC');
  const [showPaymentBtn, setShowPaymentBtn] = useState(false);
  const [processType, setProcessType] = useState('sub-list');
  const [couponList, setCouponList] = useState([]);
  const [couponModal, setCouponModal] = useState(false);
  const [planDetailsModal, setPlanDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState({});
  const [appliedCoupon, setAppliedCoupon] = useState({});
  const [planDetails, setPlanDetails] = useState({});
  // const history = useHistory();

  const onSubscriptionSelect = (evt, item, index) => {
    const clickedOnShowDetailsBtn = evt.target.className?.includes('show-plan-details');

    if (clickedOnShowDetailsBtn) {
      return;
    }

    setSelectedSub(index);
    setSelectedPackage(item);

    if (!showPaymentBtn) {
      setShowPaymentBtn(true);
    }
  };

  const onCheckout = () => {
    setProcessType('checkout');
    setLoading(false);
    window.scrollTo(0, 0);
  };

  const hideCheckout = () => {
    setProcessType('sub-list');
    setLoading(false);
  };

  const handleCouponModal = (open) => {
    setCouponModal(open);

    if (open) {
      setSelectedCoupon({});
    }
  };

  useEffect(() => {
    setLoading(onPaymentLoadSuccess);
  }, [onPaymentLoadSuccess]);

  useEffect(() => {
    if (showPlanDetailsModal) {
      setPlanDetails(showPlanDetailsModal);
      setPlanDetailsModal(true);
    }
  }, [showPlanDetailsModal]);


  const onCouponSelect = (item) => {
    setSelectedCoupon(item);
  };

  const onCouponApplied = () => {
    setAppliedCoupon(selectedCoupon);
    setCouponModal(false);
  };

  const getDiscountedPrice = (price) => {
    const priceAmt = parseFloat(price);

    if (appliedCoupon.discountType === 'percentage') {
      return priceAmt - (appliedCoupon.discountPercentage / 100 * priceAmt);
    } else {
      return priceAmt - appliedCoupon.discountAmount;
    }
  };

  const getSelectedPrice = () => {
    return selectedCoupon.couponCode ? getDiscountedPrice(selectedPackage.price) : selectedPackage.price;
  };

  const onCheckoutConfirmation = () => {
    setLoading(true);
    onConfirmation({
      planId: selectedPackage.id,
      userId: userData.id
    });
  };

  const showPlanDetails = (plan) => {
    setPlanDetails(plan);
    setPlanDetailsModal(true);
  };

  const hidePlanModal = () => {
    setPlanDetailsModal(false);
    hidePlanDetailsModal();
  };

  return (
    <Box className="sub-wrapper">
      {processType === 'sub-list' &&
        <>
          <Typography variant="h6" className="plan-header">Add Plans</Typography>
          <Card className="subscription-card">
            <CardContent>
              <Typography variant="h6" className="new-plan-header">
                Available QLearn Plans
              </Typography>

              {packageList?.length > 0 &&
                <>
                  <List component="nav" className="sub-list">
                    {packageList?.sort((a, b) => a.name.localeCompare(b.name, undefined, {
                      numeric: true,
                      sensitivity: 'base'
                    }))?.map((item, index) => {
                      return <Fragment key={index}>
                        {item.price !== 0 &&
                          <Fragment>
                            <ListItem button
                              selected={selectedSub === index}
                              onClick={(evt) => onSubscriptionSelect(evt, item, index)}>
                              <ListItemText primary={item.name}
                                secondary={<>
                                  <Typography variant="caption">
                                    Daily Question Limit: {item.questionsPerDay}
                                  </Typography>, &nbsp;
                                  <Typography variant="caption">
                                    Duration: {CONSTANTS.durationObj[item.durationInDay] || item.durationInDay}
                                  </Typography>
                                  <Typography variant="caption" className="show-plan-details"
                                    onClick={() => showPlanDetails(item)}>Show Details</Typography>
                                </>}
                              />
                              <Box className="price-cont">
                                {!appliedCoupon.couponCode &&
                                  <Typography className="price-text">${item.price} </Typography>
                                }
                                {appliedCoupon.couponCode &&
                                  <>
                                    <Typography className="new-price-text">${getDiscountedPrice(item.price)} </Typography>
                                    <Typography className="price-text deselected">${item.price} </Typography>
                                  </>
                                }
                              </Box>
                            </ListItem>

                            <Divider />
                          </Fragment>
                        }
                      </Fragment>
                    })}
                  </List>
                  <Typography color="textSecondary" variant="caption" display="block">
                    Your plan will activate upon purchase. You can purchase and switch between other plans at any time.
                  </Typography>
                </>
              }

              {packageList?.length === 0 &&
                <Typography color="textSecondary" variant="caption" display="block">
                  No plans found.
                </Typography>
              }

            </CardContent>

            {showPaymentBtn &&
              <CardActions>
                {/* <Button color="primary" onClick={() => handleCouponModal(true)}>
                  Apply Coupon
                </Button> */}
                <Button variant="contained" color="primary" onClick={onCheckout}>
                  Proceed to Checkout
                </Button>
              </CardActions>
            }
          </Card>
        </>
      }
      {processType === 'checkout' &&
        <Box className="checkout-wrapper">
          <Box className="checkout-header">
            <KeyboardBackspace onClick={hideCheckout} color="primary" />
            <Box className="checkout-header-cont">
              <Typography className="bold">You're almost done!</Typography>
              <Typography variant="caption">Make sure the below information is correct before you proceed</Typography>
            </Box>
          </Box>
          <Box className="checkout-details">
            <Box className="checkout-details-items">
              <Typography>Package: <b>{selectedPackage.name}</b></Typography>
              <Typography>Price: <b>${getSelectedPrice()}</b></Typography>
              <Typography>Email: <b>{userData.email}</b></Typography>
            </Box>
            <Button variant="contained" color="primary" onClick={onCheckoutConfirmation}
              disabled={loading} className="confirm-btn">
              Confirm
              {loading && (
                <CircularProgress size={24} className="loader" />
              )}
            </Button>
          </Box>
        </Box>
      }

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='coupon-modal sub-coupon-modal'
        open={couponModal}
        onClose={() => handleCouponModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={couponModal}>
          <Box className='modal-container'>
            <Typography variant="h5" className="header">Select a Coupon</Typography>
            <Box className="coupon-list">
              {couponList.length > 0 && couponList.map((item, index) =>
                <Box key={index}
                  onClick={() => onCouponSelect(item)}
                  className={item.couponCode === selectedCoupon.couponCode ? 'coupon-selected list-item' : 'list-item'}>
                  <Box className='coupon-dtls'>
                    Code: {item.couponCode}
                    <Box className='coupon-exp'>
                      Expiry: {new Date(item.expiryDate).toDateString()}
                    </Box>
                  </Box>

                  {item.discountType === 'percentage' &&
                    <Box className='coupon-amt'>
                      {item.discountPercentage}%
                    </Box>
                  }
                  {item.discountType !== 'percentage' &&
                    <Box className='coupon-amt'>
                      ${item.discountAmount}
                    </Box>
                  }
                </Box>
              )
              }
            </Box>

            {selectedCoupon.couponCode &&
              <Box className="apply-btn">
                <Button color="primary" onClick={() => handleCouponModal(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={onCouponApplied}>
                  Apply
                </Button>
              </Box>
            }
          </Box>
        </Fade>
      </Modal>

      <Modal
        className='coupon-modal plan-details-modal'
        open={planDetailsModal}
        onClose={() => hidePlanModal()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={planDetailsModal}>
          <Box className='modal-container'>
            <Button className="close-btn" size="small" onClick={() => hidePlanModal()}>
              <Close></Close>
            </Button>
            <Typography variant="h5" className="header">Plan Details</Typography>
            <Box className="plan-details-wrapper">
              <Box>
                <b>Name:</b> {planDetails.name}
              </Box>
              <Box>
                <b>Exam Types:</b> {planDetails.examTypes?.join(", ", " ")}
              </Box>
              <Box>
                <b>Price:</b> {planDetails.price}
              </Box>
              <Box>
                <b>Duration:</b> {CONSTANTS.durationObj[planDetails.durationInDay] || planDetails.durationInDay}
              </Box>
              <Box>
                <b>Questions Per Day:</b> {planDetails.questionsPerDay}
              </Box>
              {planDetails.description &&
                <Box>
                  <b>Description:</b> <br />
                  <ReactMarkdown>{planDetails.description}</ReactMarkdown>
                </Box>
              }
            </Box>

            <Box className="apply-btn">
              <Button variant="contained" color="primary" onClick={() => hidePlanModal()}>
                Ok
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box >
  );
}
