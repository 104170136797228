import React, { useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import {
  RemoveCircleRounded,
  CheckCircle,
  Cancel,
  Check,
} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTutorFeedbackBlock, resetCreateTutorFeedbackBlock } from "../../actions";
import "./exam-result-table.scss";
let questionIndex;

const ExamResultTable = (props) => {
  const { examId, questions } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const createFeedbackBlock = useSelector((state) => {
    return state.createTutorFeedbackBlock?.createBlock;
  });

  useEffect(() => {
    if (createFeedbackBlock?.blockId) {
      window.localStorage.setItem("feedbackBlockId", createFeedbackBlock?.blockId);
      dispatch(resetCreateTutorFeedbackBlock());
      history.push(`/exam/${examId}/${questionIndex}/review`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFeedbackBlock]);


  const reviewQuestion = (questionIdx) => {
    const userId = window.localStorage.getItem("userId");
    questionIndex = questionIdx;

    const role = window.localStorage.getItem('role');

    if (role === 'ROLE_TUTOR' || role === 'ROLE_STUDENT') {
      dispatch(createTutorFeedbackBlock({
        quizId: examId,
        userId
      }));
    } else {
      history.push(`/exam/${examId}/${questionIdx}/review`);
    }
  };

  if (!questions || !questions.length) {
    return null;
  }

  return (
    <Paper elevation={3} className="exam-table-container">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Question</TableCell>
              <TableCell className="mobile-view-hide">Discipline</TableCell>
              <TableCell className="mobile-view-hide">Organ System</TableCell>
              <TableCell className="mobile-view-hide">Exam Type</TableCell>
              <TableCell className="mobile-view-hide">Time Spent Testing</TableCell>
              <TableCell className="mobile-view-hide">Time Spent Reviewing</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions?.sort((a, b) => a.questionNo - b.questionNo)?.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>
                  {row.correct && <CheckCircle style={{ color: green[500] }} />}
                  {!row.answerGiven && <RemoveCircleRounded color="action" className="action-icon" />}
                  {row.answerGiven && !row.correct && <Cancel color="error" />}
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">
                    Question {index + 1}
                  </Typography>
                  <Box component="div" className="text-ellipsis-multiline">
                    {row.questionStem}
                  </Box>
                </TableCell>
                <TableCell className="mobile-view-hide">{row.categories?.discipline?.join(", ")}</TableCell>
                <TableCell className="mobile-view-hide">{row.categories?.organSystem?.join(", ")}</TableCell>
                <TableCell className="mobile-view-hide">{row.categories?.examType?.join(", ")}</TableCell>
                <TableCell className="mobile-view-hide">
                  {Math.floor((row.timeSpent / 60) % 60) < 10 && 0}
                  {Math.floor((row.timeSpent / 60) % 60)}:
                  {Math.floor(row.timeSpent % 60) < 10 && 0}
                  {Math.floor(row.timeSpent % 60)}
                </TableCell>
                <TableCell className="mobile-view-hide">
                  {Math.floor((row.reviewTimeSpent / 60) % 60) < 10 && 0}
                  {Math.floor((row.reviewTimeSpent / 60) % 60)}:
                  {Math.floor(row.reviewTimeSpent % 60) < 10 && 0}
                  {Math.floor(row.reviewTimeSpent % 60)}
                </TableCell>
                <TableCell className="review-btn-cont">
                  {row.reviewed && (
                    <Button
                      className="reviewed-button"
                      onClick={() => reviewQuestion(index + 1)}
                    >
                      <Check />
                      Reviewed
                    </Button>
                  )}
                  {!row.reviewed && (
                    <Button
                      color="primary"
                      className="review-button"
                      onClick={() => reviewQuestion(index + 1)}
                    >
                      Review
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ExamResultTable;
