import { useState, useMemo } from "react";
import {
  Box,
  Button,
  TextField,
  Link,
  Typography,
  Snackbar
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import url from "../../constants/urlList";
const pattern = /\S+@\S+\.\S+/i;
const emailImg = require("../../images/email.png").default;

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ForgetPwd = ({ hasId }) => {
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [error, setError] = useState('');
  const [linkSent, setLinkSent] = useState(false);
  const [open, setOpen] = useState(false);
  const [apiError, setApiError] = useState('');
  const history = useHistory();
  const query = useQuery();
  const token = query.get("token");
  const emailId = query.get("email");

  const setEmailText = (evt) => {
    const val = evt.target.value.trim();
    setEmail(val);

    if (!pattern.test(val)) {
      setError('Email not valid');
    } else {
      setError('');
    }
  };

  const onPasswordChange = (evt, type) => {
    const val = evt.target.value.trim();

    if (type === 'pwd') {
      setPwd(val);
    } else {
      setConfirmPwd(val);
    }

    if (val.length < 6) {
      setError('Password must be of 6 characters');
      return;
    }

    if (pwd && confirmPwd && pwd !== val) {
      setError('Password does not match');
    } else {
      setError('');
    }
  };

  const initiateForgetPwd = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };

    return fetch(url.forgetPwd + email, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return setLinkSent(true);
        }

        return response.json();
      }).then(res => {
        if (res?.message) {
          setApiError(res?.message);
          setOpen(true);
        }
      })
      .catch((err) => {
        console.log("Forget password err");
      });
  };

  const changePassword = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: emailId,
        password: pwd
      })
    };

    return fetch(url.changePwd + token, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          history.push('/login');
        }
      })
      .catch(() => {
        console.log("Forget password err");
      });
  };

  const goToLogin = (e) => {
    e.stopPropagation();
    history.push("/login");
  };

  const handleClose = () => {
    setOpen(false);

    setTimeout(() => setApiError(''), 300);
  };

  return (
    <Box className="login-body">
      <Snackbar open={open} onClose={handleClose} autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <>
          {apiError &&
            <Box className="sub-alert">
              {apiError}
            </Box>
          }
          {!apiError &&
            <Box className="sub-alert">
              Something went wrong...
            </Box>
          }
        </>
      </Snackbar>
      <Box component="div" className="app-name">
        QLearn
      </Box>
      <Box component="div" className="login-container">
        <Box className="login-form">
          {!hasId && !linkSent &&
            <>
              <TextField
                label="Email"
                onChange={setEmailText}
                value={email}
              />
              <Typography className="error">{error}</Typography>
              <Button
                variant="contained"
                color="primary"
                className="register-btn"
                disabled={email.length === 0 || error.length > 0}
                onClick={initiateForgetPwd}
              >
                Forget Password
              </Button>
              <Link onClick={goToLogin} variant="body2" rel="noopener">
                Login
              </Link>
            </>
          }
          {!hasId && linkSent &&
            <Box className="mail-sent">
              <img alt="email" src={emailImg} width='100' height='100'></img>
              <Typography>
                A link has been sent to your mail. Please open that link to reset password.
              </Typography>
            </Box>
          }

          {hasId &&
            <>
              <TextField
                label="New Password"
                onChange={(evt) => onPasswordChange(evt, 'pwd')}
                value={pwd}
                type="password"
              />
              <TextField
                label="Confirm Password"
                onChange={(evt) => onPasswordChange(evt, 'confirmPwd')}
                value={confirmPwd}
                type="password"
              />
              <Typography className="error">{error}</Typography>
              <Button
                variant="contained"
                color="primary"
                className="register-btn"
                disabled={confirmPwd.length === 0 || pwd.length === 0 || error.length > 0}
                onClick={changePassword}
              >
                Change Password
              </Button>
            </>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default ForgetPwd;
