import types from "../actions/types";
const initialState = {};

export function createQuiz(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_QUIZ_RESET:
            return {};
        case types.CREATE_QUIZ_REQUEST:
            return { loading: true };
        case types.CREATE_QUIZ_SUCCESS:
            return { createQuizRes: action.createQuiz, loading: false };
        case types.CREATE_QUIZ_ERROR:
            return { createQuizRes: null, errMsg: action?.errMsg, loading: false };
        default:
            return state;
    }
}

export function submitQuiz(state = initialState, action) {
    switch (action.type) {
        case types.SUBMIT_QUIZ_RESET:
            return {};
        case types.SUBMIT_QUIZ_REQUEST:
            return { loading: true };
        case types.SUBMIT_QUIZ_SUCCESS:
            return { submitQuiz: action.submitQuiz, loading: false };
        case types.SUBMIT_QUIZ_ERROR:
            return { submitQuiz: null, loading: false };
        default:
            return state;
    }
}

export function getCategories(state = initialState, action) {
    switch (action.type) {
        case types.GET_CATEGORIES_REQUEST:
            return { categoriesList: null, loading: true };
        case types.GET_CATEGORIES_SUCCESS:
            return { categoriesList: action.categoriesList, loading: false };
        case types.GET_CATEGORIES_ERROR:
            return { categoriesList: null, loading: false };
        default:
            return state;
    }
}

export function getQuesCategories(state = initialState, action) {
    switch (action.type) {
        case types.GET_QUES_CATEGORIES_REQUEST:
            return { quesCategoriesList: null, loading: true };
        case types.GET_QUES_CATEGORIES_SUCCESS:
            return { quesCategoriesList: action.quesCategoriesList.result.data, loading: false };
        case types.GET_QUES_CATEGORIES_ERROR:
            return { quesCategoriesList: null, loading: false };
        default:
            return state;
    }
}

export function getQuiz(state = initialState, action) {
    switch (action.type) {
        case types.GET_QUIZ_RESET:
            return initialState;
        case types.GET_QUIZ_REQUEST:
            return { loading: true };
        case types.GET_QUIZ_SUCCESS:
            return { quizDetails: action.quizDetails, loading: false };
        case types.GET_QUIZ_ERROR:
            return { quizDetails: null, loading: false };
        default:
            return state;
    }
}

export function getQuestionHistory(state = initialState, action) {
    switch (action.type) {
        case types.GET_QUESTION_HISTORY_RESET:
            return initialState;
        case types.GET_QUESTION_HISTORY_REQUEST:
            return { loading: true };
        case types.GET_QUESTION_HISTORY_SUCCESS:
            return { questionHistory: action.questionHistory, loading: false };
        case types.GET_QUESTION_HISTORY_ERROR:
            return { questionHistory: null, loading: false };
        default:
            return state;
    }
}

export function getQuizHistory(state = initialState, action) {
    switch (action.type) {
        case types.GET_QUIZ_HISTORY_RESET:
            return initialState;
        case types.GET_QUIZ_HISTORY_REQUEST:
            return { loading: true };
        case types.GET_QUIZ_HISTORY_SUCCESS:
            return { quizHistory: action.quizHistory, loading: false };
        case types.GET_QUIZ_HISTORY_ERROR:
            return { quizHistory: null, loading: false };
        default:
            return state;
    }
}

export function getStudentQuizHistory(state = initialState, action) {
    switch (action.type) {
        case types.GET_STUDENT_QUIZ_HISTORY_RESET:
            return initialState;
        case types.GET_STUDENT_QUIZ_HISTORY_REQUEST:
            return { loading: true };
        case types.GET_STUDENT_QUIZ_HISTORY_SUCCESS:
            return { studentQuizHistory: action.studentQuizHistory, loading: false };
        case types.GET_STUDENT_QUIZ_HISTORY_ERROR:
            return { studentQuizHistory: null, loading: false };
        default:
            return state;
    }
}

export function getOverallQuizHistory(state = initialState, action) {
    switch (action.type) {
        case types.GET_OVERALL_QUIZ_HISTORY_RESET:
            return initialState;
        case types.GET_OVERALL_QUIZ_HISTORY_REQUEST:
            return { loading: true };
        case types.GET_OVERALL_QUIZ_HISTORY_SUCCESS:
            return { overallQuizHistory: action.overallQuizHistory, loading: false };
        case types.GET_OVERALL_QUIZ_HISTORY_ERROR:
            return { overallQuizHistory: null, loading: false };
        default:
            return state;
    }
}

export function getOverallStudentQuizHistory(state = initialState, action) {
    switch (action.type) {
        case types.GET_OVERALL_STUDENT_QUIZ_HISTORY_RESET:
            return initialState;
        case types.GET_OVERALL_STUDENT_QUIZ_HISTORY_REQUEST:
            return { loading: true };
        case types.GET_OVERALL_STUDENT_QUIZ_HISTORY_SUCCESS:
            return { overallStudentQuiz: action.overallStudentQuiz, loading: false };
        case types.GET_OVERALL_STUDENT_QUIZ_HISTORY_ERROR:
            return { overallStudentQuiz: null, loading: false };
        default:
            return state;
    }
}

export function getQuestionMetadata(state = initialState, action) {
    switch (action.type) {
        case types.GET_QUESTION_METADATA_RESET:
            return initialState;
        case types.GET_QUESTION_METADATA_REQUEST:
            return { loading: true };
        case types.GET_QUESTION_METADATA_SUCCESS:
            return { questionMetadata: action.questionMetadata, loading: false };
        case types.GET_QUESTION_METADATA_ERROR:
            return { questionMetadata: null, loading: false };
        default:
            return state;
    }
}

export function reviewQuestion(state = initialState, action) {
    switch (action.type) {
        case types.GET_QUESTION_REVIEW_RESET:
            return initialState;
        case types.GET_QUESTION_REVIEW_REQUEST:
            return { loading: true };
        case types.GET_QUESTION_REVIEW_SUCCESS:
            return { reviewQuestion: action.reviewQuestion, loading: false };
        case types.GET_QUESTION_REVIEW_ERROR:
            return { reviewQuestion: null, loading: false };
        default:
            return state;
    }
}


export function getImage(state = initialState, action) {
    switch (action.type) {
        case types.GET_IMAGE_REQUEST:
            return { ...state, loading: true };
        case types.GET_IMAGE_SUCCESS:
            return { image: { ...state?.image, ...action.image }, loading: false };
        case types.GET_IMAGE_ERROR:
            return { ...state, image: null, loading: false };
        default:
            return state;
    }
}

export function getQuesFeedback(state = initialState, action) {
    switch (action.type) {
        case types.GET_QUES_FEEDBACK_REQUEST:
            return { ...state, loading: true };
        case types.GET_QUES_FEEDBACK_SUCCESS:
            return { quesFeedback: action.quesFeedback || [], loading: false };
        case types.GET_QUES_FEEDBACK_ERROR:
            return { ...state, quesFeedback: null, loading: false };
        default:
            return state;
    }
}

export function setQuesFeedback(state = initialState, action) {
    switch (action.type) {
        case types.SET_QUES_FEEDBACK_REQUEST:
            return { ...state, loading: true };
        case types.SET_QUES_FEEDBACK_RESET:
            return {};
        case types.SET_QUES_FEEDBACK_SUCCESS:
            return { quesFeedback: { ...action.quesFeedback }, loading: false };
        case types.SET_QUES_FEEDBACK_ERROR:
            return { ...state, errorMsg: action?.status?.message, quesFeedback: true, loading: false };
        default:
            return state;
    }
}

export function setQuesCount(state = initialState, action) {
    switch (action.type) {
        case types.SET_QUES_COUNT_REQUEST:
            return { ...state, loading: true };
        case types.SET_QUES_COUNT_RESET:
            return {};
        case types.SET_QUES_COUNT_SUCCESS:
            return { quesCountDtls: action.quesCount, loading: false };
        case types.SET_QUES_COUNT_ERROR:
            return { ...state, quesCount: null, loading: false };
        default:
            return state;
    }
}
export function getQuesTutorFeedback(state = initialState, action) {
    switch (action.type) {
        case types.GET_QUES_TUTOR_FEEDBACK_REQUEST:
            return { ...state, loading: true };
        case types.GET_QUES_TUTOR_FEEDBACK_SUCCESS:
            return { quesTutorFeedback: action.quesTutorFeedback || [], loading: false };
        case types.GET_QUES_TUTOR_FEEDBACK_ERROR:
            return { ...state, quesTutorFeedback: null, loading: false };
        default:
            return state;
    }
}

export function setQuesTutorFeedback(state = initialState, action) {
    switch (action.type) {
        case types.SET_QUES_TUTOR_FEEDBACK_REQUEST:
            return { ...state, loading: true };
        case types.SET_QUES_FEEDBACK_RESET:
            return {};
        case types.SET_QUES_TUTOR_FEEDBACK_SUCCESS:
            return { quesTutorFeedback: { ...action.quesTutorFeedback }, loading: false };
        case types.SET_QUES_TUTOR_FEEDBACK_ERROR:
            return { ...state, errorMsg: action?.status?.message, loading: false };
        default:
            return state;
    }
}

export function createTutorFeedbackBlock(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_TUTOR_FEEDBACK_BLOCK_REQUEST:
            return { ...state, loading: true };
        case types.CREATE_TUTOR_FEEDBACK_BLOCK_RESET:
            return {};
        case types.CREATE_TUTOR_FEEDBACK_BLOCK_SUCCESS:
            return { createBlock: { ...action.createBlock }, loading: false };
        case types.CREATE_TUTOR_FEEDBACK_BLOCK_ERROR:
            return { ...state, createBlock: null, loading: false };
        default:
            return state;
    }
}

export function endTutorFeedbackBlock(state = initialState, action) {
    switch (action.type) {
        case types.END_TUTOR_FEEDBACK_BLOCK_REQUEST:
            return { ...state, loading: true };
        case types.END_TUTOR_FEEDBACK_BLOCK_SUCCESS:
            return { endBlock: { ...action.endBlock }, loading: false };
        case types.END_TUTOR_FEEDBACK_BLOCK_ERROR:
            return { ...state, endBlock: null, loading: false };
        default:
            return state;
    }
}