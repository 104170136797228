import types from "../actions/types";
import { CONSTANTS } from "../constants/app-constant";
const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export function registerUser(state = initialState, action) {
    switch (action.type) {
        case types.ADD_USER_RESET:
            return initialState;
        case types.ADD_USER_REQUEST:
            return { loading: true };
        case types.ADD_USER_SUCCESS:
            return { addUser: action.addUser, loading: false };
        case types.ADD_USER_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function login(state = initialState, action) {
    switch (action.type) {
        case types.LOGIN_USER_RESET:
            return initialState;
        case types.LOGIN_USER_REQUEST:
            return { loading: true };
        case types.LOGIN_USER_SUCCESS:
            return { user: action.user, loading: false };
        case types.LOGIN_USER_ERROR:
            const msgCode = action.status || 500;
            const msg = msgCode === 401 && action.errMsg?.message !== 'Bad credentials' ? action.errMsg?.message : CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function userDetails(state = initialState, action) {
    switch (action.type) {
        case types.USER_DETAILS_REQUEST:
            return { loading: true };
        case types.USER_DETAILS_SUCCESS:
            return { userDetails: action.userDetails, loading: false };
        case types.USER_DETAILS_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function userDetailsFromToken(state = initialState, action) {
    switch (action.type) {
        case types.USER_DETAILS_FROM_TOKEN_REQUEST:
            return { loading: true };
        case types.USER_DETAILS_FROM_TOKEN_SUCCESS:
            return { userDetails: action.userDetailsFromToken, loading: false };
        case types.USER_DETAILS_FROM_TOKEN_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function getUserActivity(state = initialState, action) {
    switch (action.type) {
        case types.GET_USER_ACTIVITY_REQUEST:
            return { loading: true };
        case types.GET_USER_ACTIVITY_SUCCESS:
            return { userActivity: action.userActivity, loading: false };
        case types.GET_USER_ACTIVITY_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function setUserActivity(state = initialState, action) {
    switch (action.type) {
        case types.SET_USER_ACTIVITY_REQUEST:
            return { loading: true };
        case types.SET_USER_ACTIVITY_SUCCESS:
            return { setUserActivity: action.userActivity, loading: false };
        case types.SET_USER_ACTIVITY_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function getUserPreference(state = initialState, action) {
    switch (action.type) {
        case types.GET_PREFERENCE_REQUEST:
            return { loading: true };
        case types.GET_PREFERENCE_SUCCESS:
            return { preference: action.preference, loading: false };
        case types.GET_PREFERENCE_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function setUserPreference(state = initialState, action) {
    switch (action.type) {
        case types.SET_PREFERENCE_REQUEST:
            return { loading: true };
        case types.SET_PREFERENCE_SUCCESS:
            return { preference: action.preference, loading: false };
        case types.SET_PREFERENCE_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function setFeedback(state = initialState, action) {
    switch (action.type) {
        case types.SET_FEEDBACK_REQUEST:
            return { loading: true };
        case types.SET_FEEDBACK_SUCCESS:
            return { setFeedback: action.feedback, loading: false };
        case types.SET_FEEDBACK_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function menuOpenStatus(state = { open: false }, action) {
    switch (action.type) {
        case 'OPEN':
            return { open: action.open };
        default:
            return state;
    }
}