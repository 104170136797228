import { useState, useEffect } from "react";
import {
  IconButton,
  Tooltip,
  Modal,
  Fade,
  Backdrop,
  Box,
  Typography,
  Button,
  TextareaAutosize,
  Snackbar
} from "@material-ui/core";
import { Feedback } from "@material-ui/icons";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFeedback } from "../../actions";
import mixpanel from 'mixpanel-browser';
const paths = ['/login', '/register', '/admin-portal', '/forget-password', '/reset-password'];
const feedbackPath = {
  '/create-quiz': 'CREATE_QUIZ',
  '/overall-performance': 'OVERALL_PERFORMANCE',
  '/quiz-history': 'QUIZ_HISTORY',
  '/subscriptions': 'SUBSCRIPTION',
  '/plans': 'SELECT_PACKAGE'
};

export default function FeedbackView() {
  const location = useLocation();
  const [showFeedback, setShowFeedback] = useState(true);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [feedbackTxt, setFeedbackTxt] = useState('');
  const [feedbackResSuccess, setFeedbackResSuccess] = useState(true);
  const dispatch = useDispatch();

  const feedbackRes = useSelector((state) => {
    return state.setFeedback;
  });

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    };

    const stopPropagationEvt = e => {
      if (e.stopPropagation) {
        e.stopPropagation();
      } else if (window.event) {
        window.event.cancelBubble = true;
      }
      e.preventDefault();
      return false;
    }

    const handleKeyDown = e => {
      // disable F12 key
      if (e.keyCode === 123) {
        stopPropagationEvt(e);
      }

      // disable I key
      if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
        stopPropagationEvt(e);
      }

      // disable J key
      if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
        stopPropagationEvt(e);
      }

      // disable U key
      if (e.ctrlKey && e.keyCode === 85) {
        stopPropagationEvt(e);
      }
    }

    document.addEventListener('contextmenu', handleContextmenu);
    document.addEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    const isFeedback = paths.indexOf(location.pathname) > -1 || location.pathname.match(/verify-user||guide/)?.length;
    setShowFeedback(!isFeedback);
  }, [location]);

  useEffect(() => {
    if (!feedbackRes?.loading && feedbackRes?.setFeedback?.feedbackId) {
      setFeedbackResSuccess(true)
      onFeedbackCancel(false);
      setSnackbarOpen(true);
      window.analytics.track('Feedback provided', {
        id: feedbackRes?.setFeedback?.feedbackId
      });
      mixpanel.track('Feedback provided', {
        id: feedbackRes?.setFeedback?.feedbackId
      });
    } else if (Object.keys(feedbackRes).length && !feedbackRes?.loading !== undefined && !feedbackRes?.loading) {
      setFeedbackResSuccess();
      onFeedbackCancel(false);
      setSnackbarOpen(true);
    }
  }, [feedbackRes]);

  const onFeedbackCancel = (isopen) => {
    setOpen(isopen);
  };

  const handleFeedbackValChange = (evt) => {
    setFeedbackTxt(evt.target.value);
  };

  const getFeedbackPath = () => {
    if (location.pathname.match(/review/)?.length) {
      return 'REVIEW_QUIZ';
    } else if (location.pathname.match(/exam/)?.length) {
      return 'ATTEMPT_QUIZ';
    } else if (location.pathname.match(/result/)?.length) {
      return 'QUIZ_RESULT';
    }
  };

  const onFeedbackConfirm = () => {
    const param = {
      section: feedbackPath[location.pathname] || getFeedbackPath(),
      feedback: feedbackTxt
    }
    dispatch(setFeedback(param));
  };

  return (
    <>
      {showFeedback &&
        <Tooltip title="Feedback">
          <IconButton
            className="feedback-btn"
            variant="contained"
            color="primary"
            onClick={() => onFeedbackCancel(true)}
          >
            <Feedback fontSize="large" />
          </IconButton >
        </Tooltip>
      }

      <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <>
          {feedbackResSuccess &&
            <Box className="success-alert">
              Thank you for your feedback...
            </Box>
          }
          {!feedbackResSuccess &&
            <Box className="sub-alert">
              Something went wrong, please try again....
            </Box>
          }
        </>
      </Snackbar>

      <Modal
        className='coupon-modal sub-coupon-modal end-sub-modal'
        open={open}
        onClose={() => onFeedbackCancel(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box className='modal-container'>
            <Typography variant="h5" className="header">Questions/Feedback</Typography>
            <TextareaAutosize minRows={5} onChange={handleFeedbackValChange} className="feedback-textarea"
            placeholder="Please enter your feedback or questions..." />

            <Box className="apply-btn">
              <Button variant="contained" onClick={() => onFeedbackCancel(false)}>
                Cancel
              </Button>
              <Button variant="contained" className="end-sub-btn" color="primary"
                disabled={!feedbackTxt.length}
                onClick={onFeedbackConfirm}>
                Send
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
