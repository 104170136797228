import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { AccessTime, PermIdentity } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "./quiz-history-table.scss";
import mixpanel from 'mixpanel-browser';

const QuizHistoryTable = (props) => {
  const { questions } = props;
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const role = window.localStorage.getItem("role");
    setUserRole(role);
  }, []);

  const reviewQuestion = (item) => {
    const userinfos = JSON.parse(localStorage.getItem('userdetails'));

    mixpanel.track('QH-review', {
      $name: userinfos.name,
      "Value": 9.00, // Assign a monetary value to the event
      email: userinfos.email,
      role: userRole
    });
    history.push(`/result/${item.id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box component="div" m={1} className="quiz-history-table-container">
      <Typography variant="h4" className="sub-title">
        History
      </Typography>

      <Paper elevation={3}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SCORE</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell className="hide-in-mobile">Discipline</TableCell>
                <TableCell className="hide-in-mobile">Organ System</TableCell>
                <TableCell className="hide-in-mobile">Exam Type</TableCell>
                <TableCell># Q'S</TableCell>
                <TableCell className="hide-in-mobile">DATE SUBMITTED</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions?.length > 0 && (rowsPerPage > 0 ? questions?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
                : questions
              )?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Box component="div" className="quiz-percent-progress">
                      <CircularProgress
                        variant="determinate"
                        className="circular-progress-bkg"
                        size={80}
                        thickness={2}
                        value={100}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={row.percentageCorrect}
                        size={80}
                        thickness={2}
                      />
                      <Box component="div" className="quiz-progress-text">
                        <Box component="div" className="quiz-progress-text-num">
                          {row.percentageCorrect}%
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="button">{row.title}</Typography>
                    <Box component="div" className="timer-wrapper">
                      <Box
                        component="span"
                        className={row.timerOn ? "blue-text" : ""}
                      >
                        <AccessTime /> Timer <>{row.timerOn ? "ON" : "OFF"}</>
                      </Box>
                      {userRole === 'ROLE_TUTOR' &&
                        <Box
                          component="span"
                          className={row.tutorOn ? "blue-text" : ""}
                        >
                          <PermIdentity /> Tutor <>{row.tutorOn ? "ON" : "OFF"}</>
                        </Box>
                      }
                    </Box>
                  </TableCell>
                  <TableCell className="cell-width hide-in-mobile">{row.category?.discipline?.join(", ")}</TableCell>
                  <TableCell className="cell-width hide-in-mobile">{row.category?.organSystem?.join(", ")}</TableCell>
                  <TableCell className="cell-width hide-in-mobile">{row.category?.examType?.join(", ")}</TableCell>
                  <TableCell className="total-ques">{row.total}</TableCell>
                  <TableCell className="hide-in-mobile">{moment(row.complete).format("lll")}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => reviewQuestion(row)}
                    >
                      Review
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {questions.length === 0 &&
                <tr>
                  <td className="no-data" colSpan={8}>
                    No data found
                  </td>
                </tr>
              }
            </TableBody>
            {(questions?.length > 0) &&
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={questions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            }
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default QuizHistoryTable;
