import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Grid } from "@material-ui/core";
import { Block, Reply } from "@material-ui/icons";
import Modal from "../modal/modal";
import "./exam-footer.scss";
let timeout;

const ExamFooter = (props) => {
  const { isReview, questionsDataLength, onEndBlock, showEndBlock, isTutor, quizDetails, currentQuestionNum } = props;
  const [openEndModal, setOpenEndModal] = useState(false);
  const [openLockModal, setOpenLockModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState({});

  const countDownTimer = () => {
    timeout = setTimeout(() => {
      const timePerQues = 90;
      const totalTime =
        timeLeft.total !== undefined
          ? timeLeft.total - 1
          : questionsDataLength * timePerQues - 1;

      const timer = {
        total: totalTime,
        hours: Math.floor((totalTime / (60 * 60)) % 24),
        minutes: Math.floor((totalTime / 60) % 60),
        seconds: Math.floor(totalTime % 60),
      };

      setTimeLeft(timer);
    }, 1000);

    if (timeLeft.total !== undefined && !timeLeft.total) {
      clearTimeout(timeout);

      if (isReview) {
        onEndBlock(null, "submitted");
      } else {
        onEndBlock(timeLeft.total);
      }
    }
  };

  useEffect(() => {
    return () => {
      setTimeLeft({});
    }
  }, []);

  useEffect(() => {
    if (showEndBlock) {
      setOpenEndModal(showEndBlock);
    }
  }, [showEndBlock]);

  useEffect(() => {
    if (!isReview && quizDetails?.timerOn) {
      countDownTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft.total]);

  const handleEndOpen = () => {
    if (isReview || isTutor) {
      onEndBlock(null, "submitted");
    } else {
      setOpenEndModal(true);
    }
  };

  const handleReviewEnd = () => {
    if (isReview || isTutor) {
      onEndBlock(null, "reviewEnd");
    }
  };

  const onRemainInBlock = () => {
    setOpenEndModal(false);

    if (isReview) {
      onEndBlock(null, "remain-in-block");
    }
  };

  const endBlock = () => {
    setOpenEndModal(false);
    onEndBlock(timeLeft.total);
  };

  const handleLockOpen = () => {
    setOpenLockModal(true);
  };

  const closeLockModal = () => {
    setOpenLockModal(false);
  };

  const lockBlock = () => {
    setOpenLockModal(false);
  };

  const lockModalTitle = () => {
    return (
      <Box component="div" className="modalHeader">
        Unauthorized Break Warning
      </Box>
    );
  };

  const lockModalBody = () => {
    return (
      <Box component="div" className="modalBody">
        <Box component="div">
          You are about to lock the exam and take an Unauthorized Break.
        </Box>
        <Box component="div">
          An Unauthorized Break may be reported as an irregularity for the exam.
        </Box>
      </Box>
    );
  };

  const lockModalButtons = () => {
    return (
      <Box component="div" className="modalButton">
        <Button
          variant="contained"
          color="secondary"
          onClick={lockBlock}
          className="redButton"
        >
          Unauthorized Break
        </Button>
        <Button variant="contained" color="primary" onClick={closeLockModal}>
          Return to Exam
        </Button>
      </Box>
    );
  };

  const endModalTitle = () => {
    return (
      <>
        {currentQuestionNum === questionsDataLength &&
          <Box component="div" className="modalHeader">
            Warning - You are about to end the block!
          </Box>
        }
        {currentQuestionNum !== questionsDataLength &&
          <Box component="div" className="modalHeader">
            Warning - This block is incomplete!
          </Box>
        }
      </>
    );
  };

  const endModalBody = () => {
    return (
      <Box component="div" className="modalBody">
        <Box component="div">
          If you end this block you will not be able to return.
        </Box>
      </Box>
    );
  };

  const endModalButtons = () => {
    return (
      <Box component="div" className="modalButton">
        <Button
          variant="contained"
          color="secondary"
          onClick={endBlock}
          className="redButton"
        >
          End Quiz
        </Button>
        <Button variant="contained" color="primary" onClick={onRemainInBlock}>
          Remain in Block
        </Button>
      </Box>
    );
  };

  return (
    <>
      <footer className="footerContainer">
        {(!isReview) &&
          <>
            {quizDetails?.timerOn &&
              <Grid container alignItems="center">
                <Grid
                  container
                  item
                  xs={8}
                  sm={6}
                  direction="row"
                  alignItems="center"
                >
                  <Box component="div" m={1} className="blockContainer">
                    <Box component="div">
                      Block Time Remaining:
                      <Box component="span" className="timer-label">
                        {timeLeft.hours < 10 && <>0</>}
                        {timeLeft.hours}:{timeLeft.minutes < 10 && <>0</>}
                        {timeLeft.minutes}:{timeLeft.seconds < 10 && <>0</>}
                        {timeLeft.seconds}
                      </Box>
                    </Box>
                    <Box component="div">
                      Day Time Remaining:
                      <Box component="span" className="timer-label">
                        {timeLeft.hours < 10 && <>0</>}
                        {timeLeft.hours}:{timeLeft.minutes < 10 && <>0</>}
                        {timeLeft.minutes}:{timeLeft.seconds < 10 && <>0</>}
                        {timeLeft.seconds}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid container item xs={4} sm={6} justifyContent="flex-end">
                  <Box component="div" m={1}>
                    <IconButton className="footer-btn red" onClick={handleEndOpen}>
                      <Block />
                      <Box component="div">End Block</Box>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            }
            {!quizDetails?.timerOn &&
              <Box component="div" m={1}>
                <IconButton className="footer-btn red" onClick={handleEndOpen}>
                  <Block />
                  <Box component="div">End Block</Box>
                </IconButton>
              </Box>
            }
          </>
        }
        {(isReview) && (
          <>
            <IconButton className="with-review footer-btn red marg-left" onClick={handleEndOpen}>
              <Reply />
              <Box component="div">Return</Box>
            </IconButton>
            <IconButton
              className="with-review footer-btn red"
              onClick={handleReviewEnd}
            >
              <Block />
              <Box component="div">End Review</Box>
            </IconButton>
          </>
        )}
      </footer>
      {openLockModal && (
        <Modal
          modalTitle={lockModalTitle()}
          modalBody={lockModalBody()}
          modalButtons={lockModalButtons()}
        ></Modal>
      )}
      {openEndModal && (
        <Modal
          modalTitle={endModalTitle()}
          modalBody={endModalBody()}
          modalButtons={endModalButtons()}
        ></Modal>
      )}
    </>
  );
};

export default ExamFooter;
