import React from "react";
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Divider,
  Link,
} from "@material-ui/core";
// import { CheckCircle, Cancel, Redo } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import "./exam-result-overview.scss";

const ExamResultOverview = (props) => {
  const { quizOverview, quizDetails } = props;
  const history = useHistory();

  const goToOverallPerformance = () => {
    history.push(`/overall-performance`);
  };

  const hours = Math.floor((quizOverview?.quizDuration / (60 * 60)) % 24);
  const mins = Math.floor((quizOverview?.quizDuration / 60) % 60);
  const sec = Math.floor(quizOverview?.quizDuration % 60);

  return (
    <Paper elevation={3}>
      <Box component="div" className="quiz-details">
        <Box component="div" className="quiz-details-content">
          <Box component="div" className="quiz-details-content-header">
            {quizDetails?.name}
          </Box>
          <Box component="div" className="quiz-text">
            Tutor {quizDetails?.tutorOn ? "On" : "Off"} - Timer
            {quizDetails?.timerOn ? " On" : " Off"}
          </Box>
          <Box component="div" className="quiz-text">
            Completed: {new Date(quizOverview?.quizSubmitDate).toLocaleString()}
          </Box>
        </Box>
        <Link
          className="quiz-details-btn-cont"
          component="button"
          variant="body2"
          onClick={goToOverallPerformance}
        >
          VIEW OVERALL PERFORMANCE
        </Link>
      </Box>
      <Divider />
      <Box component="div" className="quiz-result-numbers">
        <Box component="div" className="quiz-percent-cont">
          <Box component="div" className="quiz-percent-progress">
            <CircularProgress
              variant="determinate"
              className="circular-progress-bkg"
              thickness={2}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              thickness={2}
              value={quizOverview?.correctPercentage}
            />
            <Box component="div" className="quiz-progress-text">
              <Box component="div" className="quiz-progress-text-num">
                {quizOverview?.correctPercentage}%
              </Box>
              CORRECT
            </Box>
          </Box>
          <Box component="div">
            <Box component="div">
              <Box component="span" className="quiz-progress-text-bold">
                {quizOverview?.incorrectPercentage}%
              </Box>
              INCORRECT
            </Box>
            <Box component="div">
              <Box component="span" className="quiz-progress-text-bold">
                {quizOverview?.questionCorrectCount}/
                {quizOverview?.questionAttemptCount}
              </Box>
              QUESTIONS
            </Box>
          </Box>
        </Box>
        <Box component="div" className="quiz-percent-total">
          <Typography variant="body2" display="block">
            TOTAL QUESTIONS
          </Typography>
          <Typography variant="h6">
            {quizOverview?.questionAttemptCount}
          </Typography>
        </Box>
        <Box component="div" className="quiz-percent-total">
          <Typography variant="body2" display="block">
            TOTAL TIME
          </Typography>
          <Typography variant="h6">
            <span>
              {hours > 0 && hours < 10 && <>0{hours}h </>}
              {hours >= 10 && <>{hours}h </>}
            </span>
            <span>
              {mins >= 0 && mins < 10 && <>0{mins}m </>}
              {mins >= 10 && <>{mins}m </>}
            </span>
            <span>
              {sec >= 0 && sec < 10 && <>0{sec}s</>}
              {sec >= 10 && <>{sec}s</>}
            </span>
          </Typography>
        </Box>
        {/* <Box component="div" className="quiz-percent-total text-center">
          <Typography variant="body2" display="block">
            ANSWER CHANGES
          </Typography>
          <Box component="div" className="quiz-ans-changes">
            <Box component="div" className="quiz-ans-changes-container">
              <Box component="div">0</Box>
              <Redo className="icon step-icon"></Redo>
              <Box component="div" className="quiz-ans-changes-icons">
                <CheckCircle className="icon correct-icon"></CheckCircle>
                <Cancel className="icon incorrect-icon"></Cancel>
              </Box>
            </Box>
            <Box component="div" className="quiz-ans-changes-container">
              <Box component="div">0</Box>
              <Redo className="icon step-icon"></Redo>
              <Box component="div" className="quiz-ans-changes-icons">
                <Cancel className="icon incorrect-icon"></Cancel>
                <CheckCircle className="icon correct-icon"></CheckCircle>
              </Box>
            </Box>
            <Box component="div" className="quiz-ans-changes-container">
              <Box component="div">0</Box>
              <Redo className="icon step-icon"></Redo>
              <Box component="div" className="quiz-ans-changes-icons">
                <Cancel className="icon incorrect-icon"></Cancel>
                <Cancel className="icon incorrect-icon"></Cancel>
              </Box>
            </Box>
            <Box component="div" className="quiz-ans-changes-container">
              <Box component="div">0</Box>
              <Redo className="icon step-icon"></Redo>
              <Box component="div" className="quiz-ans-changes-icons">
                <CheckCircle className="icon correct-icon"></CheckCircle>
                <CheckCircle className="icon correct-icon"></CheckCircle>
              </Box>
            </Box>
          </Box>
        </Box> */}
      </Box>
    </Paper>
  );
};

export default ExamResultOverview;
