import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormControl,
  LinearProgress,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import "./exam-result-category.scss";
import { Fragment } from "react";

const ExamResultCategory = (props) => {
  const { quizCategoryData } = props;
  const [viewBy, setViewBy] = useState("");

  useEffect(() => {
    if (
      quizCategoryData &&
      quizCategoryData.categoryPerformance &&
      quizCategoryData.categoryPerformance.length
    ) {
      setViewBy(quizCategoryData?.categoryPerformance[0]?.category);
    }
  }, [quizCategoryData]);

  const handleChange = (event) => {
    setViewBy(event.target.value);
  };

  const splitWord = (word) => {
    return word.replace(/([a-z])([A-Z])/g, '$1 $2');
  };

  return (
    <Accordion className="exam-category-container">
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>Category Breakdown</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="subtitle2">View By</Typography>
        <FormControl variant="outlined">
          <Select value={viewBy} onChange={handleChange}>
            {quizCategoryData?.categoryPerformance?.map((item, index) => (
              <MenuItem value={item.category} key={index}>
                {splitWord(item.category)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {quizCategoryData?.categoryPerformance?.map((item, index) => (
          <Fragment key={index}>
            {item.category === viewBy &&
              item?.subcategories.map((subCatItem, i) => (
                <Box variant="div" key={i} className="progress-container">
                  <Box variant="div" className="progress-name">
                    {subCatItem.subCategory}
                  </Box>
                  <Box variant="div" className="progress-bar">
                    <Typography>
                      {subCatItem.percentageCorrect}% CORRECT
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={subCatItem.percentageCorrect}
                    />
                  </Box>
                </Box>
              ))}
          </Fragment>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ExamResultCategory;
