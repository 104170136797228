import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPreference } from "../../actions";
import './user-agreement.scss';

import {
  Box,
  Modal,
  Fade,
  Backdrop,
  Typography,
  Button,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";

export default function UserAgreement(props) {
  const { onAgreementSubmit } = props;
  const [openAgreementModal, setOpenAgreementModal] = useState(true);
  const [checkAgreement, setCheckAgreement] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

  const dispatch = useDispatch();

  const userPreferenceData = useSelector((state) => {
    return state.setUserPreference?.preference;
  });

  useEffect(() => {
    if (userPreferenceData?.userAcknowledged) {
      onAgreementSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferenceData]);

  useEffect(() => {
    let el, isReachBottom;

    const onscroll = (evt) => {
      el = evt.target;
      isReachBottom = Math.ceil(el.scrollTop) >= Math.ceil(el.scrollHeight - el.offsetHeight);

      if (isReachBottom) {
        setHasScrolledToBottom(true);
      }
    };

    setTimeout(() => {
      document.getElementById("user_agreement")?.addEventListener("scroll", onscroll);
    }, 100);

    return () => {
      document.getElementById("user_agreement")?.removeEventListener("scroll", onscroll);
    };
  }, []);

  const onContinue = () => {
    const userId = window.localStorage.getItem("userId");
    dispatch(setPreference({ userId, body: { userAcknowledged: true } }));
  };

  const renderLink = (link, name = null) => {
    return <a href={link} target="_blank" rel="noreferrer">{name || link}</a>
  }

  return (
    <Modal
      className='user-agreement-modal'
      open={openAgreementModal}
      onClose={(evt, reason) => { if (reason !== 'backdropClick') { setOpenAgreementModal(false) } }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={openAgreementModal}>
        <Box className='modal-container'>
          <Typography variant="h5" className="header">Medlearnity QLearn</Typography>
          <Typography variant="h6" className="header">User Agreement and Terms of Use</Typography>

          <Box className="content-box user-agreement" id="user_agreement">
            <p>
              These Terms of Use set forth the terms and conditions of an agreement (this "agreement") between (a) you, as an individual who has purchased a subscription to products and/or services available through {renderLink('https://qlearn.medlearnity.com')} (collectively the "services"), (b) you, on behalf of an institution or other entity that has purchased a subscription to the services, or (c) you, as a user of the services either (i) under a subscription purchased by you or another subscriber or (ii) in any other capacity. Purchase of a subscription to use the services, or use of the services by other means, constitutes your agreement with these Terms of Use. If you do not agree, or do not intend to comply, with these Terms of Use, please do not purchase a subscription to use, or use, the services.
            </p>
            <p className="list-header">Scope of This Agreement</p>
            <p>
              In this agreement, "you" or "your" means any individual or entity, in one or more capacities described above, subscribing to the services (a "subscriber") or using the services (a "user"). Unless otherwise stated, "Medlearnity," "we," "us," or "our" means Medlearnity LLC, including the services available through {renderLink('https://qlearn.medlearnity.com')} (“QLearn”). QLearn is a knowledge and learning platform sometimes referred to as a question bank or “QBank” that includes questions and explanations for students of human medicine, doctors and other health professionals.
            </p>
            <p>
              This agreement, along with any other written agreements that you have entered into with Medlearnity with respect to the services and your subscription, constitutes the entire understanding between you and Medlearnity with respect to the subject matter hereof. All other prior representations, statements, and undertakings, whether oral or written, are hereby expressly superseded and canceled.
            </p>
            <p>
              This agreement incorporates by reference the Medlearnity Privacy Policy, which is available for your review at {renderLink('https://www.medlearnity.com')} (our "site"). You should read and understand all of the provisions of our Privacy Policy before you become a subscriber to, or user of, the services.
              We may amend this agreement from time to time by posting the amended terms on our site. Except as stated below, all amended terms shall be effective 30 days after they are initially posted on our site, and when they become effective, they will be incorporated into the Terms of Use on this page.
            </p>
            <p>
              PLEASE READ THE TERMS AND CONDITIONS OF THIS AGREEMENT CAREFULLY BEFORE SUBSCRIBING TO OUR SERVICES.
            </p>
            <p className="list-header">Certain Requirements</p>
            <ul>
              In order to subscribe to, or to use, Medlearnity services, you must:
              <li>Be an individual at least 17 years of age or older, and</li>
              <li>Have the power and authority to enter into binding agreements with us without restriction under applicable law or be using the services under a subscription purchased by a subscriber with such power and authority.</li>
            </ul>
            <p>
              You must not subscribe to, or use, Medlearnity services if you are doing so for the benefit of a competitor.
            </p>
            <p>
              You are advised to use the latest version of the Chrome Internet browser to access Medlearnity content (the "licensed materials"). Our recommended system requirements are updated from time to time to account for advancements in technology. Medlearnity reserves the right to discontinue support for any system or device at any time. Compensation will not be provided for your inability to use or access the services on discontinued or unsupported systems or devices.
            </p>
            <p>
              Finally, in accordance with the current United States export restrictions, the Medlearnity software may be used by individuals and entities throughout the world except those in the following embargoed countries and regions: Cuba, Iran, North Korea, Syria and all regions of Ukraine not controlled by the Ukrainian government, including Crimea, Donetsk, Luhansk, Kherson, and Zaporizhzhia regions. If you reside in one of these countries or regions, you may not purchase a subscription to, or use, the Medlearnity software.
            </p>
            <p className="list-header">Grant of License</p>
            <p>
              In consideration of, and subject to, payment of the appropriate subscription fee for the services to which you subscribe, and your agreement to, and compliance with, the terms and conditions in this agreement, Medlearnity grants to you a non-exclusive, non-sub-licensable, non-transferable license and right to use and access the licensed materials from multiple devices and locations with nonconcurrent log-on access.
            </p>
            <p className="list-header">Software Use</p>
            <p>
              The term "software" includes all Medlearnity products, features, applications, services, technologies, and software, including QLearn. The Medlearnity software is designed to work with systems that meet our system requirements. Any attempt to use the Medlearnity software on systems that do not meet those requirements constitutes a violation of this agreement and might result in unexpected behavior and consequences, for which Medlearnity shall not be held responsible or liable for any damages.
            </p>
            <p>
              The Medlearnity software is designed to access your computer system's clipboard during use. While a test is in progress, the Medlearnity software may disable all clipboard functions of your computer system (including copy-paste-print and save-to-disk functions).
            </p>
            <p>
              The Medlearnity software may monitor all processes on your computer to determine whether there are any applications that could be used (intentionally or unintentionally) to copy Medlearnity materials. Use of such applications simultaneously with the Medlearnity software may violate this agreement. When such an application is found by Medlearnity, in its sole discretion, to be active on a user's device, the administrator will be notified of the application name. Medlearnity will make a reasonable effort to notify the user with instructions to disable it. In addition, Medlearnity reserves the right to disable the launch of any test or take other actions if any such application is found to be active on the user's device. The user may not be able to switch between applications when Medlearnity software is running. You understand and acknowledge this disabled function as part of the subscription terms. Medlearnity may discontinue or reintroduce the above-mentioned functionality at any time at its sole discretion.
            </p>
            <p>
              Medlearnity will always keep your account and self-assessment exam performance results confidential and will not provide such information to third parties without your written consent. However, if an educational or other institution (e.g., a medical school, residency program) pays for the services on your behalf and requests the performance results from Medlearnity, then that institution will have access to your results.
            </p>
            <p>
              The Medlearnity software has been tested with a wide range of computer configurations; however, due to the rapidly evolving computer industry and unpredictability of various factors affecting computer systems, Medlearnity does not guarantee the predictable behavior of the Medlearnity software or the functionality of the Medlearnity software on every computer configuration. You recognize all of the above terms and accept that the use of the Medlearnity software on your system is done solely at your own risk.
            </p>
            <p className="list-header">Access via Mobile Devices</p>
            <p>
              Access to QLearn and services via certain mobile devices (e.g., smartphones, tablet computers) is provided as an additional feature on some of our services, but it is not intended to be a replacement for computer access. Medlearnity, at its sole discretion, may choose to discontinue offering access via any or all mobile devices at any time with or without notice, reason, or explanation.
            </p>
            <p>
              "Jailbroken" or "rooted" devices are not supported. (Jailbroken and rooted devices refer to any devices that do not have the operating system of the original equipment manufacturer (OEM) and any devices that have operating systems that have been modified in a manner not approved or supported by the OEM). You will not attempt to install or use the Medlearnity software on any jailbroken or rooted device. Further, any attempt to install or use the software on a jailbroken or rooted device will constitute a violation of this agreement and may result in account termination, without refund, at the sole discretion of Medlearnity.
            </p>
            <p>
              You agree that Medlearnity shall not be held liable for any damage to your mobile devices or operating systems, or any data loss resulting from the direct or indirect installation or use of the Medlearnity software.
            </p>
            <p>
              No refund or cancellation will be honored due to your inability to install or use the Medlearnity software on any mobile device.
            </p>
            <p>
              It is your sole responsibility to ensure that your mobile device is appropriately used and safeguarded. If your device is lost or stolen, you must notify Medlearnity immediately so that we may disable access to the account. You will be fully liable for any damages directly or indirectly resulting from any violations of this agreement.
            </p>
            <p className="list-header">Access to Content</p>
            <p>
              You are allowed to use the licensed materials for the specific materials to which you have subscribed. Each material has its own subscription fees and duration. You are not allowed to access the content of materials that you have not subscribed to (e.g., subscribing to Step 2 CS does not mean that you will have access to other materials like Step 2 CK and Step 3).
            </p>
            <p className="list-header">Reasonable Use</p>
            <p>
              Medlearnity and QLearn services and products are intended for individual self-study and exam preparation and are sold in the form of subscriptions. The services and products are designed to facilitate careful review by users of all content contained within the subscriptions to deliver maximum benefit and optimal study experience to our users with a usage pattern of at least 90% of regular users of any particular subscription (hereafter also referred to as "normal" product usage). Normal product usage does not include rapid creation of multiple tests without reasonable time for completion, usage of the entire subscription more than 2.5 times (i.e., usage of every question contained in QLearn at least twice, with up to half of the questions in QLearn being used as many as three times), creation of more than 200 tests during a single pass through of the subscription (i.e., usage of every question contained in the subscription without any reset), or usage which is suspected to be shared between multiple users on the same device or network.
            </p>
            <p>
              Usage of Medlearnity and QLearn subscriptions that is determined not to fall under reasonable use may result in action taken against the account, at Medlearnity's sole discretion. Subscription utilization that is indicative of, or arising from, impermissible business, multiple users, community use, or fraudulent use may result in suspension or termination of the subscription in question. Medlearnity may contact any subscriber or user at any time to request clarification of a usage pattern that is suspected of unreasonable use.
            </p>
            <p className="list-header">Orders</p>
            <p>
              Most of the information collected in the registration process will be used to process orders. During the order process, you will have to provide financial information such as your credit/debit card number, expiration date, CVV2 code, payee name, and billing address. This information is used for billing purposes and to fulfill your order. To properly process your credit/debit card information, we must share your personal and financial information with the merchant bank for authorization and approval. This process is protected by an enhanced security system. We do not share your personal and financial information with any other third parties except those specified in our {renderLink('https://www.medlearnity.com/privacy-policy', 'Privacy Policy')}.
            </p>
            <p>
              When you attempt a transaction on our website, the card processor verifies available funds by placing a hold, or pending charge, in the amount of the intended transaction against your card. The card processor then confirms the accuracy of your remaining information (e.g., your address, CVV2 Code, expiration date, etc.) before processing the transaction. If you enter an incorrect address or other associated detail, then the transaction is declined. This is NOT a situation unique to this website; it is how virtually all online transactions are handled. Typically, it takes the card-issuing bank 2-5 days to clear associated pending charges resulting from failed/declined attempts.
            </p>
            <p>
              We charge for each successful transaction attempt, provide a confirmation on our website, and send a confirmation email to the registered email address. You can also log in to your account on our website and click "Billing" to retrieve a copy of your payment receipt.
            </p>
            <p className="list-header">Refunds</p>
            <p>
              Except as otherwise provided in this agreement or at the sole discretion of Medlearnity, no refunds, cancellations, or changes to subscriptions will be allowed. Medlearnity reserves the right to refuse a refund if the subscriber or user is found to be in violation of this agreement or acting against Medlearnity's interest.
            </p>
            <p className="list-header">Member Account/Password and Security</p>
            <p>
              You must complete Medlearnity's registration process in order to subscribe to and use the services by providing us with current, complete, and accurate information as prompted by the applicable registration form. You are responsible for the accuracy of the data provided and may update your profile data to reflect the most accurate current information. You will also choose a password. You are entirely responsible for maintaining the confidentiality of your password and account and for any and all activities that occur under your account. You agree to notify Medlearnity immediately of any unauthorized use of your account or any other breach of security. Medlearnity shall not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. However, you could be held liable for losses incurred by Medlearnity or another party due to someone else using your account or password.
            </p>
            <p className="list-header">Ownership</p>
            <p>
              The licensed materials, including all intellectual property rights in and to the licensed materials (such as copyright), are the sole and exclusive property of Medlearnity and its licensors. You have no rights in or to the licensed materials, but you are entitled to use them according to the terms of this agreement.
            </p>
            <p className="list-header">Subscription Term</p>
            <p>
              You have the right to use and access the licensed materials for the courses and materials you have subscribed to for the duration of your subscription period unless an interval of maintenance downtime has been scheduled. The licensed materials will be provided as described in the respective product descriptions and may change, at Medlearnity’s sole discretion, during the course of a subscription period. For QLearn and similar products, the number of questions may vary during the subscription term based on content changes and updates. However, QLearn subscriptions will always have a minimum number of active questions, as described in the corresponding product descriptions. The subscription period will start immediately after your activation unless you notify Medlearnity of a desired later start date before using any of the licensed materials. Subscriptions cannot be suspended temporarily and reactivated at a later date. You must use the course material within the subscription period, and no free extensions are offered in exchange for any unused time.
            </p>
            <p>
              At the end of your subscription period, your right to use and access the licensed materials will expire automatically unless you renew under Medlearnity's then-current terms and conditions. A renewal will allow you to access existing QLearn test information for an extended preset period. Renewals are offered as a continuation of the current subscription period but not for later use after the term has expired. You must renew the subscription prior to its expiration date. A renewal will be provided only for the course subscribed. Some courses are offered as a combination package, and renewal will only be permitted for individual courses in the package but not for the original combined package. This agreement and your right to use the licensed materials will also terminate at Medlearnity's sole discretion if you fail to comply with any terms or conditions in this agreement. QLearn test information will not be reset (deletion of test/performance/usage information) during the subscription period unless it meets the eligibility criteria described below.
            </p>
            <p>
              A reset option for the QLearn question bank may be available for eligible products. If the option is available, it is based on the continuous duration (without any intermediate breaks) of a currently active subscription and does not take previous subscriptions into account. A reset is a permanent and irreversible purge (deletion) of all your test and performance data from our system. You may perform a reset directly from your account on our website.
            </p>
            <p>
              Renewal of an active subscription does NOT qualify as a new subscription purchase. An eligible QLearn subscription can be reset only once during its active period. Once it has been reset, another reset is not possible, regardless of the remaining subscription time or purchase of a renewal.

            </p>
            <p className="list-header">Accessibility</p>
            <p>
              Medlearnity is committed to ensuring that we provide access to our electronic and information technologies, learning platform, and content made available through our online applications for users with disabilities. We make reasonable efforts to follow the guidelines put forth by the World Wide Web Consortium's (W3C) Web Content Accessibility Guidelines 2.0 (Level AA) in accordance with section 508 of the Americans with Disabilities Act in order to provide accessible products and user experiences for all users.
            </p>
            <p>
              We have taken steps to make our product reasonably accessible, including adjustable font sizes and colors, background brightness and contrast settings. We test with popular browsers but cannot cover all custom platforms. We hope to provide a consistent user experience (subject to certain limitations) across devices, regardless of input tools.
            </p>
            <ul>
              If you are enrolled in Medlearnity's products and have any questions, comments, or suggestions on web accessibility issues relating to our website or mobile apps, please contact us to initiate our accommodation request process as follows:
              <li>By email, to: support@medlearnity.com</li>
              <li>By phone, to: 631-540-2499</li>
              <li>Once your request form is received, Medlearnity will send you a confirmation email. We may request additional information or documentation to better understand your request. Please be sure to include your contact information in your request form.</li>
              <li>After your request has been processed, we will notify you and provide any information that you will need.</li>
              Please note that Medlearnity does not make arrangements on your behalf with the test administrator for the official exam you are seeking to take. Please consult with the individual test administrator on how to apply.
            </ul>
            <p className="list-header">Search Engines and Web Crawlers</p>
            <p>
              The information that is posted on Medlearnity forums is routinely indexed by search engines and web crawlers (like Google, MSN, Yahoo, etc.). Therefore, when an online search is conducted, the information posted might appear in search results. If you do not wish for your posting to be made publicly available or indexed by third-party search engines, then do not make a post on our forums.
            </p>
            <p className="list-header">Linked Internet Sites</p>
            <p>
              Medlearnity is not responsible for the content available on any other Internet sites linked to the site. Accessing other Internet sites linked to the site is at your own risk.
            </p>
            <p>
              You may link to the home page of our website as long as the link does not cast us in a false or misleading light.
            </p>
            <p className="list-header">Postings</p>
            <ul>
              Medlearnity is under no obligation to review any messages, information, or content ("postings") posted on the site by users and shall not be responsible for, or liable relating to, any such postings. Notwithstanding the above, Medlearnity may from time to time review the postings on the site and may decline to accept and/or remove any postings, including the following:
              <li>Any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, profane, hateful, racial, ethnic, or otherwise objectionable material of any kind, including any material which encourages conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable local, state, national, or international law</li>
              <li>Advertisements or solicitations of any kind</li>
              <li>Messages posted by users impersonating others</li>
              <li>Personal information such as messages which state phone numbers, social security numbers, account numbers, addresses, or employer references</li>
              <li>Messages by non-spokesperson employees of Medlearnity purporting to speak on behalf of Medlearnity</li>
              <li>Messages that offer unauthorized download of any copyrighted or private information</li>
              <li>Multiple messages placed within individual folders by the same user restating the same point</li>
            </ul>
            <p className="list-header">Prohibitions</p>
            <p>
              While you are using the services, you shall not copy, or attempt in any way to copy, or capture the contents of any screen (including via any OEM-provided functionality or third-party applications), or upload Medlearnity materials to any other application. Medlearnity reserves the right to disable your account without refund if you copy or attempt to copy, screen shot, or reproduce any content on Medlearnity or QLearn. If you copy or attempt to copy any content on Medlearnity or QLearn for commercial use, then we reserve the right to pursue any and all legal remedies available to us.
            </p>
            <p>
              You shall not reverse engineer or modify any of the licensed materials. No part of the licensed materials may be copied, printed, or reproduced for resale or other commercial use or offered for sale or reproduced on any bulletin boards, websites, discussion forums, Internet domains, or online chat rooms. You shall not use any device, software, or routine to interfere or attempt to interfere with the proper working of the site or any activity being conducted at the site. You shall not disclose or share your password with any third parties or use your password for any unauthorized purpose.
            </p>
            <p>
              You shall not sublicense, assign, share, sell, rent, lease, lend, distribute or otherwise transfer your right to use the licensed materials to any other third party. All accounts at Medlearnity are monitored for multiple logins and other potential violations. If Medlearnity believes that an account is being used by multiple users at the same time, Medlearnity reserves the right to terminate that account without any notice or refund, at its sole discretion. We also retain the right to sue on grounds of breach of contract. Therefore, please do not disclose your account details to anyone else.
            </p>
            <p className="list-header">Marks</p>
            <ul>
              None of the trademarks displayed on the site are the property of Medlearnity:
              <li>ACT® is a registered trademark of ACT, Inc.</li>
              <li>AICPA® is a registered trademark of the American Institute of CPAs.</li>
              <li>CFA Institute®, CFA®, and Chartered Financial Analyst® are registered trademarks of the CFA Institute.</li>
              <li>MCAT® is a registered trademark of the Association of American Medical Colleges (AAMC).</li>
              <li>NAPLEX® and MPJE® are registered trademarks of the National Association of Boards of Pharmacy (NABP).</li>
              <li>NCBE, MBE, MPRE, UBE, MEE, and MPT are marks of the National Conference of Bar Examiners.</li>
              <li>NCLEX-RN™ and NCLEX-PN™ are registered trademarks of the National Council of State Boards of Nursing, Inc. (NCSBN®).</li>
              <li>SAT® and AP® are registered trademarks of the College Board.</li>
              <li>The United States Medical Licensing Examination™ (USMLE™) is a joint program of the Federation of State Medical Boards (FSMB) and National Board of Medical Examiners® (NBME®). The other trademarks, logos, and service marks displayed on the site are the property of Medlearnity. Users are prohibited from using any Medlearnity marks without the written permission and consent of Medlearnity. All content on the site is protected by copyright.
              </li>
            </ul>
            <p className="list-header">Affiliation</p>
            <ul>
              Medlearnity is a commercial educational service provider for individuals who are preparing for various medical and other exams. Medlearnity is in no way affiliated with any official testing or educational institutions.
              <li>American Bar Association (ABA)</li>
              <li>American Institute of Certified Public Accountants (AICPA)</li>
              <li>Association of American Medical Colleges (AAMC)</li>
              <li>College Board</li>
              <li>Federation of State Medical Boards (FSMB)</li>
              <li>National Association of Boards of Pharmacy (NABP)</li>
              <li>National Board of Medical Examiners (NBME)</li>
              <li>National Conference of Bar Examiners (NCBE)</li>
              <li>National Council of State Boards of Nursing, Inc. (NCSBN)</li>
              <li>State bar associations</li>
              <li>Any other official organization related to any of the foregoing Medlearnity reserves the right to update our materials each year, and these updates are validated internally to ensure quality. However, we do not claim endorsement, promotion, or review by any testing or educational institutions for the accuracy or quality of the product and services offered by Medlearnity.</li>
            </ul>
            <p className="list-header">No Warranties</p>
            <p>
              MEDLEARNITY PROVIDES ITS SERVICES "AS IS" AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED, OR STATUTORY. WE SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
            </p>
            <p>
              Medlearnity does not endorse and is not responsible for the accuracy or reliability of any opinion, advice, or statement made through the site by any party other than Medlearnity. Other than as required under consumer-protection law, under no circumstance shall Medlearnity be liable for any loss or damage caused by your reliance on information obtained through the site. It is your responsibility to evaluate the accuracy, completeness, or usefulness of any information, opinion, advice, or other content available through the site. Please seek the advice of professionals, as appropriate, regarding the evaluation of any specific information, opinion, advice, or other content.
            </p>
            <p>
              Medlearnity materials are for educational and instructional purposes only and do not constitute medical, financial, or legal advice. Medlearnity materials are not a substitute for the exercise of professional care and judgment. For example, Medlearnity materials are not a substitute for (i) individual patient assessment based upon the healthcare provider's examination of each patient and consideration of laboratory data and other factors unique to the patient or (ii) individual legal advice provided by a lawyer to a client.
            </p>
            <p>
              Medlearnity reserves the right to withdraw subscriptions and the complete site if necessary. If this ever becomes necessary, you will only pay for the time of your subscription that has elapsed (on a pro-rata basis).
            </p>
            <p>
              Medlearnity shall use reasonable efforts to rectify any error that occurs on its site but does not guarantee access to the site in case of software errors, viruses, or other natural or technical catastrophes that cannot be repaired in spite of a reasonable effort.
            </p>
            <p>
              Medlearnity shall use reasonable efforts to keep its site online. You understand that scheduled maintenance and problems out of the control of Medlearnity can cause the site to be temporarily unavailable.
            </p>
            <p className="list-header">Limitation of Liability</p>
            <p>
              MEDLEARNITY SHALL NOT BE LIABLE TO YOU (WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE) FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, PUNITIVE, OR SPECIAL DAMAGES, INCLUDING LOST PROFITS OR REVENUES, RESULTING FROM, OR ARISING OUT OF OR IN CONNECTION WITH, ACCESS TO OR USE OF THE SITE, OUR SERVICES OR THIS AGREEMENT, EVEN IF MEDLEARNITY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING LIABILITY ASSOCIATED WITH ANY MALPRACTICE OR VIRUSES THAT MAY INFECT A USER’S COMPUTER EQUIPMENT.
            </p>
            <p>
              MEDLEARNITY’S MAXIMUM LIABILITY RESULTING FROM, OR ARISING OUT OF OR IN CONNECTION WITH, YOUR ACCESS TO OR USE OF THE SITE, OUR SERVICES OR THIS AGREEMENT, REGARDLESS OF THE CAUSE OF ACTION (WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE), SHALL NOT EXCEED YOUR SUBSCRIPTION FEE.
            </p>
            <p className="list-header">Indemnification</p>
            <p>
              You agree to indemnify, defend, and hold harmless Medlearnity and its members, directors, officers, employees, agents, contractors, and licensors from and against any and all liabilities, claims, and expenses, including attorneys' fees, that arise from your violation of any of the terms or conditions of this agreement.
            </p>
            <p className="list-header">Confidentiality of Communications</p>
            <p>
              Except as required by law, Medlearnity will maintain the confidentiality of all user communications, which contain personal user information and are transmitted directly to Medlearnity. Your postings in any public forum or arena, such as a message board or a chat room, will not be protected as confidential, and Medlearnity may use and disclose the information contained in any such postings (including any ideas, concepts, know-how, or other intellectual property) for any purpose deemed appropriate by Medlearnity.
            </p>
            <p className="list-header">Governing Law</p>
            <p>
              This agreement is governed by the internal substantive laws of the state of New York in the United States of America, without respect to the conflict of laws principles of the State of New York. Jurisdiction for any claims arising under this agreement shall lie exclusively with the state or federal courts within New York, New York, in the United States of America. If any provision of this agreement is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this agreement, which shall remain in full force and effect. No waiver of any term of this agreement shall be deemed a further or continuing waiver of such term or any other term.
            </p>
            <p className="list-header">Violations</p>
            <p>
              If you breach any term of this agreement, Medlearnity may, at its sole discretion, terminate this agreement, your access to the site, and its provision of services to you without refund. Medlearnity reserves the right to seek all remedies available at law and in equity for such breaches.
            </p>
            <p className="list-header">Severability; Remedies</p>
            <p>
              Should any provision of these Terms of Use be invalid in whole or in part, this shall not affect the legal validity of the remaining provisions.
            </p>
            <p>
              The failure of a party to act upon any right, remedy, or breach of these Terms of Use shall not constitute a waiver of that or any other right, remedy, or breach. No waiver shall be effective unless made in writing and signed by an authorized representative of the waiving party.
            </p>
            <p className="list-header">DMCA Guidelines</p>
            <p>
              In accordance with the Digital Millennium Copyright Act (DMCA), Medlearnity encourages all copyright infringement claims to be made in writing. Any registered user or visitor to the Medlearnity website who believes they are a victim of copyright infringement should notify the Medlearnity DMCA agent immediately.
            </p>
            <ul>
              If you are the copyright owner or an agent acting on behalf of the copyright owner, please provide us with the following information:
              <li>A description of the alleged copyright infringement material.</li>
              <li>Any information that can assist Medlearnity agents in properly pinpointing the information, including the infringer's user ID, page URL, date of posting, etc.</li>
              <li>Detailed contact information, including your full name, address, email, and telephone number.</li>
              <li>Include the following statement: "I have a good faith belief that use of the copyrighted materials described above as allegedly infringing is not authorized by the copyright owner, its agent, or the law."</li>
              <li>Include the following statement: "I swear, under penalty of perjury, that the information in the notification is accurate and that I am the copyright owner or am authorized to act on behalf of the owner of an exclusive right that is allegedly infringed."</li>
            </ul>
            <p className="list-header">Send the written communication to:</p>
            <p>
              Medlearnity LLC<br />
              ATTN.: DMCA Agent<br />
              447 BROADWAY, 2ND FL #507<br />
              New York, NY 10013, USA
            </p>
            <p>
              Or fax it to:
            </p>
            <p>
              Medlearnity, DMCA Claims<br />
              Fax: (631) 540-2499
            </p>
            <p className="list-header">Questions</p>
            <p>
              If you have any questions regarding this agreement, you may contact us at any time, by email,
              to: {renderLink('support@medlearnity.com')} or by phone at 631-540-2499.
            </p>
          </Box>

          <Box className="apply-btn">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(evt) => setCheckAgreement(evt.target.checked)}
                  checked={checkAgreement}
                  name="unused"
                  color="primary"
                />
              }
              label={`I agree to this User Agreement`}
            />
            <Button variant="contained" disabled={!(checkAgreement && hasScrolledToBottom)} color="primary" onClick={onContinue}>
              Continue
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

