import { fork } from "redux-saga/effects";
import userSaga from "./user";
import quizSaga from "./quiz";
import paymentSaga from "./payment";
import adminSaga from "./admin";

export default function* rootSaga() {
    yield fork(userSaga);
    yield fork(quizSaga);
    yield fork(paymentSaga);
    yield fork(adminSaga);
}