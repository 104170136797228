import React from 'react';
// import url from "../../constants/urlList";

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const AuthRedirect = () => {
    try {
        const userInfo = {
            accessToken: getParameterByName('token')
        }
        window.opener.postMessage({ msg: "Login Successful", results: userInfo }, '*');

        setTimeout(() => { window.close() }, 300);
        return (<></>);
    } catch (err) {
       return (<div>UnAuthorized User</div>);
    }
}
