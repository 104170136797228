import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  FormControlLabel,
  IconButton,
  TextField,
  RadioGroup,
  Radio,
  FormLabel
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { setQuesFeedback, setQuesTutorFeedback } from "../../actions";
import { useDispatch } from "react-redux";
import "../exam-header/exam-header.scss";

const ratingArr = [1, 2, 3, 4, 5];
const YNArr = [{
  name: 'Yes',
  val: 'Y'
},
{
  name: 'No',
  val: 'N'
}];
const topicArr = [{
  name: 'General',
  val: 'OTHER_GENERAL'
}, {
  name: 'Stem',
  val: 'STEM'
},
{
  name: 'Explanation',
  val: 'EXPLANATION'
}];
const difficultyArr = [{
  name: 'Easy',
  val: 'EASY'
}, {
  name: 'Medium',
  val: 'MEDIUM'
},
{
  name: 'Hard',
  val: 'HARD'
}
];
const expArr = [
  {
    name: 'Too Short',
    val: 'TOO_SHORT',
  },
  {
    name: 'Too Verbose',
    val: 'TOO_VERBOSE',
  },
  {
    name: 'Just Right',
    val: 'JUST_RIGHT',
  }];
const qualityArrNew = [
  {
    name: 'Excellent',
    val: 'EXCELLENT',
  },
  {
    name: 'Good',
    val: 'GOOD',
  },
  {
    name: 'Fair',
    val: 'FAIR',
  }];

const errorArr = [{
  name: 'None',
  val: 'NONE'
}, {
  name: 'Factual',
  val: 'FACTUAL'
},
{
  name: 'Typo/Grammar',
  val: 'GRAMMAR_TOPOLOGICAL'
}];

const fullExpArr = [
  {
    name: 'Relevant AND Accurate',
    val: 'RELEVANT',
  },
  {
    name: 'Less Relevant OR Less Accurate',
    val: 'LESS_RELEVANT',
  }];

const QuestionFeedback = (props) => {
  const {
    questionData,
    currentQuestion,
    changeQuesFeedbackStatus,
    isGuide
  } = props;
  const [isStudent, setIsStudent] = useState(false);
  const [isTutorRole, setIsTutorRole] = useState(false);
  const [questionId, setQuestionId] = useState(0);

  useEffect(() => {
    if (isGuide) {
      const channel = new BroadcastChannel('app-data');
      channel.addEventListener('message', (event) => {
        setFeedbackForm({
          isError: 'N',
        });
        setQuestionId(event.data?.id);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [feedbackForm, setFeedbackForm] = useState({
    isError: 'N',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const role = window.localStorage.getItem('role');
    setIsStudent(role === 'ROLE_STUDENT');
    setIsTutorRole(role === 'ROLE_TUTOR');
  }, []);

  useEffect(() => {
    resetForm();
  }, [questionData]);

  const handleChange = (evt, type, subtype) => {
    const val = evt.target.value;
    const formVal = { ...feedbackForm };
    if (subtype) {
      formVal[subtype] = { ...formVal[subtype], ...{ [type]: val } }
    } else {
      formVal[type] = val;
    }
    setFeedbackForm(formVal);
  }

  const saveQuesFeedback = (e) => {
    e.preventDefault();
    const questionIdNum = isGuide && questionId ? questionId : isStudent ? questionData[currentQuestion - 1].questionId : questionData.id;

    feedbackForm.questionId = questionIdNum;
    const blockId = window.localStorage.getItem('feedbackBlockId');
    feedbackForm.blockId = blockId;

    if (isStudent) {
      feedbackForm.rating = Number(feedbackForm.rating);
      dispatch(setQuesFeedback(feedbackForm));
      changeQuesFeedbackStatus(false);
    } else {
      delete feedbackForm.isError;
      dispatch(setQuesTutorFeedback(feedbackForm));
    }

    resetForm();
  };

  const resetForm = () => {
    setFeedbackForm({
      isError: 'N',
    });
  };

  const isSubmitDisabled = () => {
    let isDisbaled = false;

    if (isStudent) {
      isDisbaled = (feedbackForm?.isError === 'Y' &&
        feedbackForm?.topic === undefined) ||
        (feedbackForm.isError === 'Y' &&
          feedbackForm.topic === 'EXPLANATION' && feedbackForm.explanationType === undefined);
    } else {
      isDisbaled = (feedbackForm?.stem === undefined ||
        feedbackForm?.stem?.difficulty === undefined ||
        feedbackForm?.stem?.errors === undefined ||
        feedbackForm?.stem?.quality === undefined ||
        feedbackForm?.explanation === undefined ||
        feedbackForm?.explanation?.quality === undefined ||
        feedbackForm?.explanation?.errors === undefined
      )
    }

    return isDisbaled;
  };

  return (
    <Box className="cmt-cont">
      {isStudent &&
        <Box className="cmt-header">
          <> Question Feedback</>
          <IconButton
            className="close-btn"
            onClick={() => changeQuesFeedbackStatus(false)}
          >
            <Close />
          </IconButton>
        </Box>
      }

      {isStudent &&
        <form
          noValidate
          onSubmit={saveQuesFeedback}
        >
          <Box className="feedback-body">
            <Box className="form-row">
              <FormLabel>Quality rating (5 is highest)?</FormLabel>
              <RadioGroup
                row
                defaultValue={feedbackForm.rating}
                onChange={(e) => handleChange(e, 'rating')}
              >
                {ratingArr.map(item =>
                  <FormControlLabel
                    key={item}
                    value={item.toString()}
                    control={<Radio color="primary" />}
                    label={item}
                  />
                )}
              </RadioGroup>
            </Box>
            <Box className="form-row">
              <FormLabel>Is there an error suspected in this question?</FormLabel>
              <RadioGroup
                row
                defaultValue={feedbackForm.isError}
                onChange={(e) => handleChange(e, 'isError')}
              >
                {YNArr.map(item =>
                  <FormControlLabel
                    key={item.val}
                    value={item.val}
                    control={<Radio color="primary" />}
                    label={item.name}
                  />
                )}
              </RadioGroup>
            </Box>
            {feedbackForm.isError === 'Y' &&
              <Box className="form-row">
                <FormLabel>Error belongs to:</FormLabel>
                <RadioGroup
                  row
                  defaultValue={feedbackForm.topic}
                  onChange={(e) => handleChange(e, 'topic')}
                >
                  {topicArr.map(item =>
                    <FormControlLabel
                      key={item.val}
                      value={item.val}
                      control={<Radio color="primary" />}
                      label={item.name}
                    />
                  )}
                </RadioGroup>
              </Box>
            }
            {(feedbackForm.isError === 'Y' && feedbackForm.topic === 'EXPLANATION') &&
              <Box className="form-row">
                <FormLabel>Is the explanation too short, too verbose or just right? </FormLabel>
                <RadioGroup
                  row
                  defaultValue={feedbackForm.explanationType}
                  onChange={(e) => handleChange(e, 'explanationType')}
                >
                  {expArr.map(item =>
                    <FormControlLabel
                      key={item.val}
                      value={item.val}
                      control={<Radio color="primary" />}
                      label={item.name}
                    />
                  )}
                </RadioGroup>
              </Box>
            }

            <Box className="form-row nm">
              <FormLabel>Rate question difficulty:</FormLabel>
              <RadioGroup
                row
                defaultValue={feedbackForm.difficulty}
                onChange={(e) => handleChange(e, 'difficulty')}
              >
                {difficultyArr.map(item =>
                  <FormControlLabel
                    key={item.val}
                    value={item.val}
                    control={<Radio color="primary" />}
                    label={item.name}
                  />
                )}
              </RadioGroup>
            </Box>
            <TextField
              className="wd-100"
              label="Comment (optional)"
              onChange={(e) => handleChange(e, 'comment')}
            />
            <Box className="btn-row">
              <Button
                type="submit"
                variant="contained"
                onClick={() => changeQuesFeedbackStatus(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitDisabled()}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      }
      {isTutorRole &&
        <form
          noValidate
          onSubmit={saveQuesFeedback}
        >
          <Box className="feedback-body tutor-feedback">
            <Box className="form-row">
              <FormLabel>Rate the following aspects of the question:</FormLabel>
              <FormLabel className="bold marg-top">Question Stem</FormLabel>

              <Box className="radio-grp">
                <FormLabel>Difficulty:</FormLabel>
                <RadioGroup
                  row
                  onChange={(e) => handleChange(e, 'difficulty', 'stem')}
                  value={feedbackForm?.stem?.difficulty || false}
                >
                  {difficultyArr.map(item =>
                    <FormControlLabel
                      key={item.val}
                      value={item.val}
                      control={<Radio color="primary" />}
                      label={item.name}
                    />
                  )}
                </RadioGroup>
              </Box>
              <Box className="radio-grp">
                <FormLabel>Quality:</FormLabel>
                <RadioGroup
                  row
                  onChange={(e) => handleChange(e, 'quality', 'stem')}
                  value={feedbackForm?.stem?.quality || false}
                >
                  {qualityArrNew.map(item =>
                    <FormControlLabel
                      key={item.val}
                      value={item.val}
                      control={<Radio color="primary" />}
                      label={item.name}
                    />
                  )}
                </RadioGroup>
              </Box>
              <Box className="radio-grp">
                <FormLabel>Errors:</FormLabel>
                <RadioGroup
                  row
                  onChange={(e) => handleChange(e, 'errors', 'stem')}
                  value={feedbackForm?.stem?.errors || false}
                >
                  {errorArr.map(item =>
                    <FormControlLabel
                      key={item.val}
                      value={item.val}
                      control={<Radio color="primary" />}
                      label={item.name}
                    />
                  )}
                </RadioGroup>
              </Box>
            </Box>
            <Box className="form-row">
              <FormLabel className="bold">Explanation (Top)</FormLabel>
              <Box className="radio-grp">
                <FormLabel className="box">Quality:</FormLabel>
                <RadioGroup
                  row
                  onChange={(e) => handleChange(e, 'quality', 'explanation')}
                  value={feedbackForm?.explanation?.quality || false}
                >
                  {qualityArrNew.map(item =>
                    <FormControlLabel
                      key={item.val}
                      value={item.val}
                      control={<Radio color="primary" />}
                      label={item.name}
                    />
                  )}
                </RadioGroup>
              </Box>
              <Box className="radio-grp">
                <FormLabel>Errors:</FormLabel>
                <RadioGroup
                  row
                  onChange={(e) => handleChange(e, 'errors', 'explanation')}
                  value={feedbackForm?.explanation?.errors || false}
                >
                  {errorArr.map(item =>
                    <FormControlLabel
                      key={item.val}
                      value={item.val}
                      control={<Radio color="primary" />}
                      label={item.name}
                    />
                  )}
                </RadioGroup>
              </Box>
            </Box>
            <Box className="form-row line-border">
              <FormLabel className="bold">Full Explanation (Bottom)</FormLabel>
              <Box className="radio-grp">
                <FormLabel>Pick one:</FormLabel>
                <RadioGroup
                  row
                  onChange={(e) => handleChange(e, 'expandedExplanation')}
                  value={feedbackForm?.expandedExplanation || false}
                >
                  {fullExpArr.map(item =>
                    <FormControlLabel
                      key={item.val}
                      value={item.val}
                      control={<Radio color="primary" />}
                      label={item.name}
                    />
                  )}
                </RadioGroup>
              </Box>
            </Box>

            <Box className="form-row nm comment-wrapper">
              <FormLabel className="bold">Comments (Optional, but we appreciate feedback):</FormLabel>
              <FormLabel className="box comment-label">
                If you have specific suggestions for any section, please enter it below. <br />
                For example: <br />
                Question Stem: <i>“Introduce the pertinent negative of a clear CXR to clarify the answer, especially with regard to option B.”</i>
              </FormLabel>

              <TextField
                className="wd-100 cmt-impt"
                label="Question Stem:"
                value={feedbackForm?.improvementSuggestions?.stem || ''}
                onChange={(e) => handleChange(e, 'stem', 'improvementSuggestions')}
              />
              <TextField
                className="wd-100 cmt-impt"
                label="Explanation:"
                value={feedbackForm?.improvementSuggestions?.explanation || ''}
                onChange={(e) => handleChange(e, 'explanation', 'improvementSuggestions')}
              />
              <TextField
                className="wd-100 cmt-impt"
                label="Expanded Explanation:"
                value={feedbackForm?.improvementSuggestions?.expandedExplanation || ''}
                onChange={(e) => handleChange(e, 'expandedExplanation', 'improvementSuggestions')}
              />
            </Box>

            <Box className="btn-row">
              {/* <Button
                type="submit"
                variant="contained"
                onClick={() => changeQuesFeedbackStatus(false)}
              >
                Cancel
              </Button> */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitDisabled()}
              >
                Submit Feedback
              </Button>
            </Box>
          </Box>
        </form>
      }
    </Box>
  );
};

export default QuestionFeedback;
