import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  FormControlLabel,
  IconButton,
  TextField
} from "@material-ui/core";
import { Close, Send, Person, PersonOutline, Reply } from "@material-ui/icons";
import { getComment, setComment } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Tooltip from '@material-ui/core/Tooltip';
import "../exam-header/exam-header.scss";

const CommentsModal = (props) => {
  const {
    questionData,
    currentQuestion,
    changeCommentModalStatus
  } = props;
  const [commentVal, setCommentVal] = useState('');
  const [replyCommentVal, setReplyCommentVal] = useState('');
  const [replyId, setReplyId] = useState('');
  const [commentList, setCommentList] = useState([]);
  const dispatch = useDispatch();

  const comments = useSelector((state) => {
    return state.getComment?.comments;
  });

  const newComment = useSelector((state) => {
    return state.setComment?.newComment;
  });

  useEffect(() => {
    showComment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newComment) {
      showComment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newComment]);

  useEffect(() => {
    if (comments) {
      setCommentList(comments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  const showComment = () => {
    const questionId = questionData[currentQuestion - 1].questionId;
    dispatch(getComment({ quesId: questionId }));
    changeCommentModalStatus(true);
    setCommentVal('');
  };

  const handleComentValChange = (event) => {
    setCommentVal(event.target.value);
  };

  const handleReplyComentValChange = (event) => {
    setReplyCommentVal(event.target.value);
  };

  const saveComment = () => {
    const questionId = questionData[currentQuestion - 1].questionId;

    if (commentVal.length) {
      dispatch(setComment({ quesId: questionId, commentParam: { comment: commentVal } }));
      setCommentVal('');
    }
  };

  const saveReplyComment = (item) => {
    const questionId = questionData[currentQuestion - 1].questionId;

    if (replyCommentVal.length) {
      dispatch(setComment({ quesId: questionId, commentParam: { parentCommentId: item.commentId, comment: replyCommentVal } }));
      setReplyCommentVal('');
    }
  };

  const showReply = (item) => {
    const id = replyId === item.commentId ? '' : item.commentId;
    setReplyId(id);
  };

  return (
    <Box className="cmt-cont">
      <Box className="cmt-header">Comments
        <IconButton
          className="close-btn"
          onClick={() => changeCommentModalStatus(false)}
        >
          <Close />
        </IconButton>
      </Box>
      <Box className="cmt-body">
        {commentList?.length > 0 && commentList?.map(item =>
          <Box key={item.commentId} className="cmt-item-cont">
            <Box className="cmt-item">
              <Box className="cmt-user">
                <Person className="user-icon" />
                <Box component="span" className="cmt-user-text green">
                  {item.userId}
                </Box>
                <Box component="span" className="cmt-date"> {moment(item.dateCreated).format("lll")}</Box>
              </Box>
              <Box className="cmt-reply">
                <Box className="cmt-text">{item.comment}</Box>
                <Tooltip title="Reply">
                  <IconButton
                    color="primary"
                    onClick={() => showReply(item)}
                  >
                    <Reply />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            {item?.replies?.length > 0 && item.replies.map(reply =>
              <Box className="cmt-item reply" key={reply.commentId}>
                <Box className="cmt-user">
                  <Box component="span" className="cmt-date"> {moment(reply.dateCreated).format("lll")}</Box>

                  {item.userId === reply.userId &&
                    <Box className="cmt-user-text-wrap">
                      <Box component="span" className="cmt-user-text green">{reply.userId}</Box>
                      <Person className="user-icon" />
                    </Box>
                  }
                  {item.userId !== reply.userId &&
                    <Box className="cmt-user-text-wrap">
                      <Box component="span" className="cmt-user-text blue">{reply.userId}</Box>
                      <PersonOutline className="user-icon" />
                    </Box>
                  }
                </Box>
                <Box className="cmt-text">{reply.comment}</Box>
              </Box>
            )}

            {replyId === item.commentId &&
              <Box className="cmt-footer">
                <TextField label="" variant="outlined" value={replyCommentVal} onChange={handleReplyComentValChange} />
                <IconButton
                  className="send-reply-btn"
                  color="primary"
                  aria-label="reply"
                  disabled={!replyCommentVal.length}
                  onClick={() => saveReplyComment(item)}
                >
                  <Send />
                </IconButton>
              </Box>
            }
          </Box>
        )}
      </Box>
      <Box className="cmt-footer">
        <TextField label="" variant="outlined" className="send-input" value={commentVal} multiline rows={2}
          onChange={handleComentValChange} />
        <IconButton
          className="send-btn"
          color="primary"
          disabled={!commentVal.length}
          onClick={saveComment}
        >
          <Send fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CommentsModal;
