import { useState, useEffect } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Modal,
  Backdrop,
  Fade,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { AccountCircle } from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import FreeMembership from '../../components/freeMembership/freeMembership';
import { useDispatch } from "react-redux";
import "./admin.scss";

export default function AdminView() {
  const [couponList, setCouponList] = useState([]);
  const [isCoupon, setIsCoupon] = useState(false);
  const [modal, setModal] = useState(false);
  const [couponType, setCouponType] = useState('random');
  const [discountType, setDiscountType] = useState('percentage');
  const [couponCode, setCouponCode] = useState();
  const [discountPercentage, setDiscountPercentage] = useState(5);
  const [discountAmount, setDiscountAmount] = useState(10);
  const [expiryDate, setExpiryDate] = useState(new Date().toJSON().slice(0, 10));
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    dispatch({
      type: "LOGOUT",
    });
    window.localStorage.clear();
    history.push('login');
  };

  useEffect(() => {
    const coupons = window.localStorage.getItem('coupons');

    if (coupons) {
      setCouponList(JSON.parse(coupons));
    }
  }, []);

  const handleCreateCouponModal = (isOpen) => {
    setModal(isOpen);

    if (isOpen) {
      //Reset view
      setCouponCode(Math.random().toString(36).slice(2).toUpperCase());
      setCouponType('random');
      setDiscountType('percentage');
      setDiscountPercentage(5);
      setDiscountAmount('');
      setExpiryDate(new Date().toJSON().slice(0, 10));
    }
  };

  const handleCouponTypeChange = (event) => {
    const val = event.target.value;
    setCouponType(event.target.value);

    if (val !== 'random') {
      setCouponCode('');
    } else {
      setCouponCode(Math.random().toString(36).slice(2).toUpperCase());
    }
  };

  const handleDiscountTypeChange = (event) => {
    const val = event.target.value;
    setDiscountType(val);

    if (val === 'percentage') {
      setDiscountAmount('');
      setDiscountPercentage(5);
    } else {
      setDiscountAmount(10);
      setDiscountPercentage('');
    }
  };

  const onChangeDiscountPercentage = (event) => {
    setDiscountPercentage(event.target.value);
  };

  const onChangeDiscountAmount = (event) => {
    setDiscountAmount(event.target.value);
  };

  const handleExpiryDateChange = (event) => {
    setExpiryDate(event.target.value);
  };

  const onCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const onCreateCoupon = () => {
    const couponData = {
      discountType,
      discountPercentage,
      discountAmount,
      expiryDate,
      couponCode
    };

    setModal(false);

    let coupons = window.localStorage.getItem('coupons');

    if (coupons) {
      coupons = JSON.parse(coupons);
    } else {
      coupons = [];
    }

    coupons.push(couponData);
    window.localStorage.setItem('coupons', JSON.stringify(coupons));
    setCouponList(coupons);
  };

  return (
    <Box className='admin-portal'>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Typography variant="h6" color="inherit" className='header-title'>
            QLearn Admin
          </Typography>
          <Box>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <FreeMembership></FreeMembership>

      {isCoupon &&
        <Box className='body-container'>
          <Box className='list-container'>
            <Typography variant="h5" className="header">Available Coupons:</Typography>
            {couponList.length > 0 && couponList.map((item, index) =>
              <Box key={index} className='list-item'>
                <Box className='coupon-dtls'>
                  Code: {item.couponCode}
                  <Box className='coupon-exp'>
                    Expiry: {new Date(item.expiryDate).toDateString()}
                  </Box>
                </Box>

                {item.discountType === 'percentage' &&
                  <Box className='coupon-amt'>
                    {item.discountPercentage}%
                  </Box>
                }
                {item.discountType !== 'percentage' &&
                  <Box className='coupon-amt'>
                    ${item.discountAmount}
                  </Box>
                }
              </Box>
            )
            }
            {couponList.length === 0 &&
              <Box className='no-list'>No coupons found</Box>
            }
          </Box>
          <Button className="coupon-create-btn" variant="contained" color="primary"
            onClick={() => handleCreateCouponModal(true)}>
            Create Coupon
          </Button>
        </Box>
      }
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='coupon-modal'
        open={modal}
        onClose={() => handleCreateCouponModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <Box className='modal-container'>
            <Typography variant="h5" className="header">Generate Coupon</Typography>
            <RadioGroup name="couponType" value={couponType} onChange={handleCouponTypeChange}>
              <FormControlLabel value="random" control={<Radio />} label="Generate Random Code" />
              <FormControlLabel value="specific" control={<Radio />} label="Provide Specific Code" />
            </RadioGroup>
            <TextField disabled={couponType === 'random'}
              label="Coupon Code"
              onChange={onCouponCodeChange}
              className="coupon-value"
              value={couponCode} />

            <Box className='discount-cont'>
              <FormControl className='type'>
                <InputLabel id="demo-simple-select-label">Discount Type</InputLabel>
                <Select
                  value={discountType}
                  onChange={handleDiscountTypeChange}
                >
                  <MenuItem value='percentage'>Percentage</MenuItem>
                  <MenuItem value='amount'>Amount</MenuItem>
                </Select>
              </FormControl>
              {discountType === 'percentage' &&
                <TextField label="Percentage"
                  value={discountPercentage}
                  onChange={onChangeDiscountPercentage}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }} />
              }
              {discountType === 'amount' &&
                <TextField label="Amount"
                  type="number"
                  value={discountAmount}
                  onChange={onChangeDiscountAmount}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }} />
              }
            </Box>

            <TextField
              id="date"
              label="Expiry date"
              type="date"
              value={expiryDate}
              className='expiry'
              onChange={handleExpiryDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Button className="create-btn" variant="contained" color="primary"
              onClick={onCreateCoupon}>
              Create
            </Button>
            <Button className="create-btn"
              onClick={() => handleCreateCouponModal(false)}>
              Cancel
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
