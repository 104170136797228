const themes = {
    light: {
        'background-color': '#fff',
        'text-color': '#000',
        'label-color': '#272727',
        'border-color': '#c4c4c4',
        'background-image': 'linear-gradient(#fcfcfc 30%, #d7dced 100%)',
        'action-icon': '#0000008a',
        'link': '#2851bf',
        'list-bkg': '#e0e0e0',
        'active-row': '#e4f3ff',
        'active-green': '#2ea86c',
        'sub-text': '#989898',
        'blue-border': '#0303c0',
        'table-header': '#eaeaea',
        'icon-opacity': 0.3,
        'bkg-text-color': '#fff',
        'correct-ans-color': '#EFFFE8',
        'wrong-ans-color': '#FFE6E6',
        'list-item-sel-color': '#3f51b5',
        'exp-bkg-color': '#fff5ca'
    },
    dark: {
        'background-color': '#262626',
        'text-color': '#fff',
        'label-color': '#fff',
        'border-color': '#e6e6e6',
        'background-image': 'linear-gradient(#313030, #0c0c0c 100%)',
        'action-icon': '#c7c7c78a',
        'link': '#fff',
        'list-bkg': '#6d6d6d',
        'active-row': '#5b95c3',
        'active-green': '#1b6b00',
        'sub-text': '#e2e2e2',
        'blue-border': '#e6e6e6',
        'table-header': '#aeaeae',
        'icon-opacity': 0.1,
        'correct-ans-color': '#d8e7d1',
        'wrong-ans-color': '#d8baba',
        'list-item-sel-color': '#7a8ae7',
        'exp-bkg-color': '#5b005b'
    }
};

export default themes;