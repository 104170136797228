import types from "../actions/types";
import { CONSTANTS } from "../constants/app-constant";
const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export function getPackageList(state = initialState, action) {
    switch (action.type) {
        case types.GET_PACKAGE_REQUEST:
            return { loading: true };
        case types.GET_PACKAGE_SUCCESS:
            return { packageList: action.packageList, loading: false };
        case types.GET_PACKAGE_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function getUserPackage(state = initialState, action) {
    switch (action.type) {
        case types.GET_USER_PACKAGE_REQUEST:
            return { loading: true };
        case types.GET_USER_PACKAGE_SUCCESS:
            return { userPackage: action.userPackage, loading: false };
        case types.GET_USER_PACKAGE_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function createPaymentSession(state = initialState, action) {
    switch (action.type) {
        case types.RESET_PAYMENT_SESSION_REQUEST:
            return {};
        case types.GET_PAYMENT_SESSION_REQUEST:
            return { loading: true };
        case types.GET_PAYMENT_SESSION_SUCCESS:
            return { paymentSession: action.paymentSession, loading: false };
        case types.GET_PAYMENT_SESSION_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function updatePaymentStatus(state = initialState, action) {
    switch (action.type) {
        case types.GET_PAYMENT_STATUS_REQUEST:
            return { loading: true };
        case types.GET_PAYMENT_STATUS_SUCCESS:
            return { paymentUpdateStatus: action.paymentUpdate, loading: false };
        case types.GET_PAYMENT_STATUS_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function getUserPayments(state = initialState, action) {
    switch (action.type) {
        case types.GET_USER_PAYMENT_REQUEST:
            return { loading: true };
        case types.GET_USER_PAYMENT_SUCCESS:
            return { paymentHistory: action.paymentHistory, loading: false };
        case types.GET_USER_PAYMENT_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function cancelSubscription(state = initialState, action) {
    switch (action.type) {
        case types.CANCEL_SUBSCRIPTION_RESET:
            return {};
        case types.CANCEL_SUBSCRIPTION_REQUEST:
            return { loading: true };
        case types.CANCEL_SUBSCRIPTION_SUCCESS:
            return { cancelSubscription: action.cancelSubscription, loading: false };
        case types.CANCEL_SUBSCRIPTION_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function selectPlan(state = initialState, action) {
    switch (action.type) {
        case types.SELECT_PLAN_RESET:
            return {};
        case types.SELECT_PLAN_REQUEST:
            return { loading: true };
        case types.SELECT_PLAN_SUCCESS:
            return { selectPlan: action.selectPlan, loading: false };
        case types.SELECT_PLAN_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function getActivePlans(state = initialState, action) {
    switch (action.type) {
        case types.GET_ACTIVE_PLANS_RESET:
            return {};
        case types.GET_ACTIVE_PLANS_REQUEST:
            return { loading: true };
        case types.GET_ACTIVE_PLANS_SUCCESS:
            return { plansList: action.plansList, loading: false };
        case types.GET_ACTIVE_PLANS_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}