import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { api } from "../services/apiService";
import type from "../actions/types";

function* getActivePlans(action) {
    try {
        const activePlansRes = yield call(api.getActivePlans, action.payload);
        yield put({ type: type.GET_ACTIVE_PLANS_SUCCESS, plansList: activePlansRes });
    } catch (e) {
        yield put({ type: type.GET_ACTIVE_PLANS_ERROR, message: e.message });
    }
}

function* getUserPackage(action) {
    try {
        const packageRes = yield call(api.getUserPackage, action.payload);
        yield put({ type: type.GET_USER_PACKAGE_SUCCESS, userPackage: packageRes });
    } catch (e) {
        yield put({ type: type.GET_USER_PACKAGE_ERROR, message: e.message });
    }
}

function* createPaymentSession(action) {
    try {
        const paymentSessionRes = yield call(api.createPaymentSession, action.payload);
        yield put({ type: type.GET_PAYMENT_SESSION_SUCCESS, paymentSession: paymentSessionRes });
    } catch (e) {
        yield put({ type: type.GET_PAYMENT_SESSION_ERROR, message: e.message });
    }
}

function* updatePaymentStatus(action) {
    try {
        const paymentUpdateRes = yield call(api.updatePaymentStatus, action.payload);
        yield put({ type: type.GET_PAYMENT_STATUS_SUCCESS, paymentUpdate: paymentUpdateRes });
    } catch (e) {
        yield put({ type: type.GET_PAYMENT_STATUS_ERROR, message: e.message });
    }
}
function* getUserPayments(action) {
    try {
        const paymentHistRes = yield call(api.getUserPayments, action.payload);
        yield put({ type: type.GET_USER_PAYMENT_SUCCESS, paymentHistory: paymentHistRes });
    } catch (e) {
        yield put({ type: type.GET_USER_PAYMENT_ERROR, message: e.message });
    }
}
function* cancelSubscription(action) {
    try {
        const cancelSubscriptionRes = yield call(api.cancelSubscription, action.payload);
        yield put({ type: type.CANCEL_SUBSCRIPTION_SUCCESS, cancelSubscription: cancelSubscriptionRes });
    } catch (e) {
        yield put({ type: type.CANCEL_SUBSCRIPTION_ERROR, message: e.message });
    }
}
function* selectPlan(action) {
    try {
        const selectPlanRes = yield call(api.selectPlan, action.payload);
        yield put({ type: type.SELECT_PLAN_SUCCESS, selectPlan: selectPlanRes });
    } catch (e) {
        yield put({ type: type.SELECT_PLAN_ERROR, message: e.message });
    }
}

function* watchGetActivePlans() {
    yield takeEvery(type.GET_ACTIVE_PLANS_REQUEST, getActivePlans);
}

function* watchCreatePaymentSession() {
    yield takeEvery(type.GET_PAYMENT_SESSION_REQUEST, createPaymentSession);
}

function* watchUpdatePaymentStatus() {
    yield takeEvery(type.GET_PAYMENT_STATUS_REQUEST, updatePaymentStatus);
}
function* watchGetUserPackage() {
    yield takeEvery(type.GET_USER_PACKAGE_REQUEST, getUserPackage);
}
function* watchGetUserPayments() {
    yield takeEvery(type.GET_USER_PAYMENT_REQUEST, getUserPayments);
}
function* watchCancelSubscription() {
    yield takeEvery(type.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription);
}
function* watchSelectPlan() {
    yield takeEvery(type.SELECT_PLAN_REQUEST, selectPlan);
}

export default function* paymentSaga() {
    yield all([
        fork(watchGetActivePlans),
        fork(watchCreatePaymentSession),
        fork(watchUpdatePaymentStatus),
        fork(watchGetUserPackage),
        fork(watchGetUserPayments),
        fork(watchCancelSubscription),
        fork(watchSelectPlan),
    ]);
}