import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Link,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { resetLogin, userDetailsFromToken, getPreference } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import mixpanel from 'mixpanel-browser';
import url from "../../constants/urlList";
import { isProd } from "../../constants/urlList";
import "./login.scss";
import Hotjar from '@hotjar/browser';

const googleLogo = require("../../images/google-icon.png").default;

const LoginView = (props) => {
  const { onLoginRequest, onLoginSubmit } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [isProdEnv, setIsProdEnv] = useState(isProd);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const savedUserId = window.localStorage.getItem("userId");

    if (savedUserId) {
      goToCreateQuiz();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onLoginRequest.errorMsg) {
      setTimeout(() => {
        dispatch(resetLogin());
      }, 2000);
    } else if (onLoginRequest.user) {
      window.analytics.identify(onLoginRequest.user?.id, {
        name: onLoginRequest.user?.displayName,
        email: onLoginRequest.user?.email
      });
      mixpanel.track('Sign In', {
        $name: onLoginRequest.user?.displayName,
        $user_id: onLoginRequest.user?.displayName,
        email: onLoginRequest.user?.email,
        role: onLoginRequest.user.primaryRole
      });
      mixpanel.identify(onLoginRequest.user?.displayName);
      // Set user properties
      mixpanel.people.set({
        $email: onLoginRequest.user?.email,
        $name: onLoginRequest.user?.displayName,
        $role: onLoginRequest.user.primaryRole
      });
      mixpanel.track("Menu-create-quiz", {
        $name: onLoginRequest.user?.displayName,
        email: onLoginRequest.user?.email,
        role: onLoginRequest.user.primaryRole
      });

      const siteId = process.env.REACT_APP_HOTJAR_KEY;
      const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;
      Hotjar.init(siteId, hotjarVersion);

      const userData = {
        name: onLoginRequest.user?.displayName,
        email: onLoginRequest.user?.email
      };
      window.localStorage.setItem("userdetails", JSON.stringify(userData));

      window.localStorage.setItem("token", onLoginRequest.user.accessToken);
      window.localStorage.setItem("userId", onLoginRequest.user.id);
      window.localStorage.setItem("role", onLoginRequest.user.primaryRole);
      goToCreateQuiz();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onLoginRequest]);

  const userDetailsFromTokenRes = useSelector((state) => {
    return state.userDetailsFromToken?.userDetails;
  });

  useEffect(() => {
    if (userDetailsFromTokenRes?.status === 200 || userDetailsFromTokenRes?.id) {
      goToCreateQuiz(userDetailsFromTokenRes.primaryRole);
    } else if (userDetailsFromTokenRes && (userDetailsFromTokenRes?.status === 401 || !userDetailsFromTokenRes?.id)) {
      alert('User not registered');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetailsFromTokenRes]);

  const goToSignUp = (e) => {
    e.stopPropagation();
    history.push("/register");
  };

  const goToForgetPwd = (e) => {
    e.stopPropagation();
    history.push("/forget-password");
  };

  const goToCreateQuiz = (userRole = null) => {
    const role = userRole || window.localStorage.getItem("role");

    if (role === 'ROLE_STUDENT') {
      const userId = window.localStorage.getItem("userId");

      if (userId) {
        dispatch(getPreference(userId));
      }
    }

    history.push("/create-quiz");
  };

  const signIn = (data) => {
    onLoginSubmit(data);
  };

  const loginSuccessListener = (response) => {
    const result = response.data.results;

    window.analytics.identify(result?.id, {
      name: result?.displayName,
      email: result?.email
    });

    window.localStorage.setItem("token", result.accessToken);
    dispatch(userDetailsFromToken());
  };

  const onGoogleSignUp = () => {
    window.removeEventListener("message", loginSuccessListener);
    window.open(
      `${url.domain}/qlearn/oauth2/authorization/google`,
      "Sign In",
      "height=400,width=600"
    );
    window.addEventListener("message", loginSuccessListener, false);
  };

  return (
    <Box className="login-body">
      <Box component="div" className="app-name">
        QLearn {isProdEnv}
      </Box>

      <Box component="div" className="login-container">
        <Box component="div" className="error-container">
          {onLoginRequest.errorMsg}
        </Box>
        <form noValidate className="login-form" onSubmit={handleSubmit(signIn)}>
          {!isProdEnv &&
            <>
              <TextField
                label="Email"
                {...register("email", { required: true, pattern: /\S+@\S+\.\S+/i })}
                error={
                  errors.email?.type === "required" ||
                  errors.email?.type === "pattern"
                }
                helperText={
                  errors.email?.type === "required"
                    ? "Email is required"
                    : errors.email?.type === "pattern"
                      ? "Please enter a valid email"
                      : undefined
                }
              />
              <TextField
                label="Password"
                type="password"
                {...register("password", { required: true, minLength: 6 })}
                error={
                  errors.password?.type === "required" ||
                  errors.password?.type === "minLength"
                }
                helperText={
                  errors.password?.type === "required"
                    ? "Please enter a password"
                    : errors.password?.type === "minLength"
                      ? "Password must be of 6 characters"
                      : undefined
                }
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="register-btn"
                disabled={onLoginRequest.loading}
              >
                Sign In
                {onLoginRequest.loading && (
                  <CircularProgress size={24} className="loader" />
                )}
              </Button>
            </>
          }
          <Button
            variant="outlined"
            color="secondary"
            className="google-btn"
            id="google_login_btn"
            onClick={onGoogleSignUp}
          >
            <img alt="google" src={googleLogo} width='30' height='30' className="logo" ></img>
            Sign In with Google
          </Button>
        </form>
        {!isProdEnv &&
          <>
            <Box className="signup-link">
              Don't have an account? &nbsp;
              <Link onClick={goToSignUp} variant="body2" rel="noopener">
                Sign Up
              </Link>
            </Box>
            <Link onClick={goToForgetPwd} variant="body2" rel="noopener">
              Forget Password
            </Link>
          </>
        }
      </Box>
    </Box>
  );
};

export default LoginView;
