import React, { useState } from "react";
import { Box, Modal } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  };
}

const AppModal = (props) => {
  const { modalBody, modalTitle, modalButtons, clsNm } = props;
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal open={true}>
      <div style={modalStyle} className={`modal-cont ${classes.paper} ${clsNm}`}>
        {modalTitle && <Box component="div">{modalTitle}</Box>}
        {modalBody}
        {modalButtons && <Box component="div">{modalButtons}</Box>}
      </div>
    </Modal>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: "45%",
      minWidth: 500,
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #fff",
      boxShadow: theme.shadows[5],
    },
  })
);

export default AppModal;
