import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import Menu from "../../pages/menu/menu";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudentFeedbackAll, getTutorFeedbackAll
} from "../../actions";
import "./feedback-dashboard.scss";

export default function FeedbackDashboardView() {
  const [selectedDropdown, setSelectedDropdown] = useState('tutor');
  const [feedbackList, setFeedbackList] = useState([]);
  const [questionData, setQuestionData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const studentFeedbacks = useSelector((state) => {
    return state.getStudentFeedbackAll;
  });

  const tutorFeedbacks = useSelector((state) => {
    return state.getTutorFeedbackAll;
  });

  const questionMetadata = useSelector((state) => {
    return state.questionMetadata?.questionMetadata;
  });

  // const quesStudentFeedback = useSelector((state) => {
  //   return state.getQuesFeedback;
  // });

  // const quesTutorFeedback = useSelector((state) => {
  //   return state.getQuesTutorFeedback;
  // });

  useEffect(() => {
    getFeedbackList(selectedDropdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDropdown]);

  useEffect(() => {
    if (studentFeedbacks && studentFeedbacks.loading !== undefined) {
      setIsLoading(studentFeedbacks.loading);

      if (studentFeedbacks?.feedbacks?.length) {
        showFeedbackDetails();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentFeedbacks]);

  useEffect(() => {
    if (tutorFeedbacks && tutorFeedbacks.loading !== undefined) {
      setIsLoading(tutorFeedbacks.loading);

      if (tutorFeedbacks?.feedbacks?.length) {
        showFeedbackDetails();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorFeedbacks]);


  useEffect(() => {
    if (questionMetadata && questionMetadata.length) {
      const data = { ...questionData };
      data[questionMetadata[0].id] = questionMetadata[0];
      setQuestionData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionMetadata]);

  const getFeedbackList = (type) => {
    setFeedbackList([]);

    if (type === 'student') {
      dispatch(getStudentFeedbackAll());
    } else if (type === 'tutor') {
      dispatch(getTutorFeedbackAll());
    }
  };

  const handleDropdownChange = (event) => {
    setSelectedDropdown(event.target.value);
  };

  const showFeedbackDetails = () => {
    if (selectedDropdown === 'student') {
      const quesFeedback = studentFeedbacks?.feedbacks;
      setFeedbackList(quesFeedback);
    } else if (selectedDropdown === 'tutor') {
      const quesFeedback = tutorFeedbacks?.feedbacks
      setFeedbackList(quesFeedback);
    }
  };

  const getTransformedText = (text) => {
    if (text?.includes('_')) {
      return text.replace('_', ' ');
    }

    return text;
  }

  return (
    <Box component="div" className='body-cont-over feedback-dashboard'>
      <Menu />

      <Backdrop open={isLoading} className="result-loader">
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className="dashboard">
        <FormControl variant="outlined" className="dropdown">
          <InputLabel>Feedback by:</InputLabel>
          <Select
            value={selectedDropdown}
            label="Select"
            onChange={handleDropdownChange}
          >
            <MenuItem value={'student'}>Student</MenuItem>
            <MenuItem value={'tutor'}>Tutor</MenuItem>
            {/* <MenuItem value={'general'}>General</MenuItem> */}
          </Select>
        </FormControl>

        <Box className="list-wrapper">
          {feedbackList?.length > 0 &&
            <Box className={`feedback-list ${selectedDropdown === 'tutor' ? 'tutor' : 'student'}`}>
              {feedbackList.map((item, index) =>
                <React.Fragment key={index}>
                  {item?.questionId && selectedDropdown === 'student' &&
                    <Box component="div" className="info-list-items feedback">
                      <Box className="ques-id-row">
                        <Typography variant="body2" component="span" className="feedback-label">QuestionId: </Typography>
                        <Typography variant="subtitle2" component="span">{item.questionId}</Typography>
                      </Box>
                      <Box className="label1">{item?.user?.email}</Box>
                      <Box className="item-row">
                        <Typography className="item-value">
                          Error Suspected: {item?.isError === 'Y' ? 'Yes' : 'No'}
                        </Typography>
                        {item?.rating > 0 &&
                          <Typography className="item-value">
                            Quality Rating: {item?.rating}
                          </Typography>
                        }
                        {item?.topic &&
                          <Typography className="item-value">
                            Topic: {getTransformedText(item?.topic)}
                          </Typography>
                        }
                        {item?.explanationType &&
                          <Typography className="item-value">
                            Error location: {getTransformedText(item?.explanationType)}
                          </Typography>
                        }
                      </Box>

                      {item?.comment &&
                        <Box className="item-value">
                          <label>Comment:</label>

                          {item?.comment}
                        </Box>
                      }
                    </Box>
                  }
                  {item?.questionId && selectedDropdown === 'tutor' &&
                    <Box component="div" className="info-list-items feedback">
                      <Box>
                        <Typography variant="body2" component="span" className="feedback-label">QuestionId: </Typography>
                        <Typography variant="subtitle2" component="span">{item.questionId}</Typography>
                      </Box>
                      <Box className="label1 marg-10">From: {item?.user?.email}, {item?.user?.id}</Box>
                      <Box className="label1">Date: {moment(item?.dateCreated).format("lll")}</Box>
                      <Box className="item-row-box">
                        <Box className="label-box">STEM</Box>
                        <Typography className="item-value-box">
                          Quality: {getTransformedText(item?.stem?.quality)},
                          &nbsp;  Difficulty: {getTransformedText(item?.stem?.difficulty)},
                          &nbsp; Errors: {getTransformedText(item?.stem?.errors)}.
                        </Typography>
                        <Box className="label-box">EXPLANATION</Box>
                        <Typography className="item-value-box">
                          Quality: {getTransformedText(item?.explanation?.quality)},
                          &nbsp; Errors: {getTransformedText(item?.explanation?.errors)}.
                        </Typography>
                        {item?.expandedExplanation &&
                          <>
                            <Box className="label-box">EXPANDED EXPLANATION</Box>
                            <Typography className="item-value-box">
                              {item?.expandedExplanation}
                            </Typography>
                          </>
                        }
                        {item?.improvementSuggestions &&
                          <>
                            <Box className="label-box">IMPROVEMENT SUGGESTIONS</Box>
                            <Typography className="item-value-box">
                              {item?.improvementSuggestions?.stem &&
                                <>
                                  <span>Stem:</span> {item?.improvementSuggestions?.stem}
                                </>
                              }
                              {item?.improvementSuggestions?.explanation &&
                                <>
                                  <span>Explanation:</span>{item?.improvementSuggestions?.explanation}
                                </>
                              }
                              {item?.improvementSuggestions?.expandedExplanation &&
                                <>
                                  <span>Expanded Explanation:</span> {item?.improvementSuggestions?.expandedExplanation}
                                </>
                              }
                            </Typography>
                          </>
                        }
                      </Box>
                    </Box>
                  }
                </React.Fragment>
              )
              }
            </Box>
          }
        </Box>
      </Box >
    </Box >
  );
}
