import React, { useState, useEffect, useRef } from "react";
import { Box, Tabs, Tab, Typography, Link, Button, IconButton } from "@material-ui/core";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import "./exam-review-drawer.scss";
import samplePdf from '../../pdfs/peds.manual2.pdf';
import { getQuesFeedback, getQuesTutorFeedback } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from 'react-markdown';
import { Forum, Launch, Check, Clear } from "@material-ui/icons";
import QuestionFeedback from '../../components/exam-header/question-feedback';

let guideWindow = null;

const ExamReviewDrawer = (props) => {
  const { questionData, openDrawer, quizHistory, onPdfClose, isOpenPdf, pdfData, onFeedbackOpen,
    isTutor, isGuide, isReview, onGuideOpen, currentQuestionNum } = props;
  const [selectedTab, setSelectedTab] = useState('explanation');
  const [isPdf, setIsPdf] = useState(false);
  const [guideData, setGuideData] = useState({});
  const [pageNumber, setPageNumber] = useState(10);
  const [numPages, setNumPages] = useState(null);
  const [pageWidth, setPageWidth] = useState(600);
  const [searchText, setSearchText] = useState('');
  const [isTutorRole, setIsTutorRole] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [quesFeedbackArr, setQuesFeedbackArr] = useState([]);
  const [quesTutorFeedbackArr, setQuesTutorFeedbackArr] = useState([]);
  // const [searchText, setSearchText] = useState('transfusion');
  const pageRef = useRef(null);
  const dispatch = useDispatch();

  const quesFeedback = useSelector((state) => {
    return state.getQuesFeedback;
  });

  const setQuesFeedbackRes = useSelector((state) => {
    return state.setQuesFeedback;
  });

  const quesTutorFeedback = useSelector((state) => {
    return state.getQuesTutorFeedback;
  });

  useEffect(() => {
    if (isGuide) {
      const channel = new BroadcastChannel('app-data');
      channel.addEventListener('message', (event) => {
        setGuideData(event.data);
      });
    }
    const role = window.localStorage.getItem('role');
    setIsTutorRole(role === 'ROLE_TUTOR');
    setIsAdmin(role === 'ROLE_ADMIN');

    return () => {
      if (guideWindow) {
        guideWindow.close();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (questionData) {
      setGuideData(questionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData]);

  useEffect(() => {
    if (setQuesFeedbackRes?.quesFeedback && setQuesFeedbackRes?.loading === false) {
      const questionId = questionData?.id;
      if (questionId) {
        dispatch(getQuesFeedback(questionId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setQuesFeedbackRes]);

  useEffect(() => {
    if (quesFeedback?.loading !== undefined && !quesFeedback?.loading) {
      setQuesFeedbackArr(quesFeedback.quesFeedback || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quesFeedback]);

  useEffect(() => {
    if (quesTutorFeedback?.loading !== undefined && !quesTutorFeedback?.loading) {
      setQuesTutorFeedbackArr(quesTutorFeedback.quesTutorFeedback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quesTutorFeedback]);

  useEffect(() => {
    setPageWidth(pageRef?.current?.offsetWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageRef.current]);

  useEffect(() => {
    if (pdfData) {
      setPageNumber(pdfData.page);
      setSearchText(pdfData.text);
    }
  }, [pdfData]);

  useEffect(() => {
    const questionId = guideData?.id;
    if (questionId) {
      if (isTutorRole) {
        dispatch(getQuesTutorFeedback(questionId));
      } else {
        dispatch(getQuesFeedback(questionId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, guideData]);

  const getPageProps = () => {
    return {
      className: 'pdf-page',
      scale: 1,
      // renderTextLayer,
      width: pageWidth,
      customTextRenderer: (text) => {
        const splitText = text.str.split(searchText);

        if (splitText.length <= 1) {
          return text.str;
        }

        const matches = text.str.match(new RegExp(searchText, 'i'));

        return splitText.reduce((arr, element, index) => (matches[index] ? [
          ...arr,
          element,
          <mark key={index}>
            {matches[index]}
          </mark>,
        ] : [...arr, element]), []);
      }
    };
  }

  const pageProps = getPageProps();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const closePdf = () => {
    setIsPdf(false);
    onPdfClose();
  };

  useEffect(() => {
    setIsPdf(isOpenPdf);
  }, [isOpenPdf]);

  const convertUrl = (url) => {
    if (url.includes('youtube')) {
      return url.replace('watch?v=', 'embed/') + '?autoplay=1';
    }

    return url;
  }

  const onGuidePopOut = () => {
    if (!guideWindow) {
      let path = window.location.pathname;

      if (isReview) {
        path = path.replace('review', 'tutor/1');
      }

      guideWindow = window.open(path + '/guide/1', '_blank', 'width=800,height=700,left=700,popup');
      onGuideOpen();

      guideWindow.addEventListener('load', () => {
        guideWindow.addEventListener('beforeunload', (e) => {
          // e.preventDefault();
          guideWindow = null;
          // e.returnValue = true;
        });
      });
    } else {
      alert('Guide already open');
    }
  }

  const getTransformedText = (text) => {
    if (text.includes('_')) {
      return text.replace('_', ' ');
    }

    return text;
  }

  return (
    <>
      {isPdf &&
        <Box component="div" m={1} ref={pageRef}>
          <Button
            className="close-btn"
            onClick={closePdf}
          >
            X
          </Button>
          <Document file={samplePdf} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                {...pageProps}
                key={`page_${index + 1}`}
                inputRef={
                  pageNumber === index + 1 ? (ref) => ref && ref.scrollIntoView() : null
                }
                pageNumber={index + 1}
              />
            ))
            }
          </Document>
        </Box>
      }
      {!isPdf &&
        <Box component="div" className="drawer-cont">
          <Box className={`${quizHistory?.correct ? 'correct-ans' : quizHistory?.correct === false ? 'wrong-ans' : ''} exp-header`}>
            <Box className="header-lbls">
              <Box component="h2">Detailed Guidance</Box>
              {!isTutorRole &&
                <Box className="icons-wrap">
                  {quizHistory?.correct && <><Check className="correct-icon"></Check> Correct</>}
                  {!quizHistory?.correct && <><Clear className="incorrect-icon"></Clear>Incorrect</>}
                </Box>
              }
            </Box>
            {(isTutorRole && !isGuide) &&
              <IconButton
                onClick={() => onGuidePopOut()}
                title="Pop Out"
                className="pop-out-icon"
              >
                <Launch />
              </IconButton>
            }
          </Box>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            className={`${quizHistory?.correct ? 'correct-ans' : quizHistory?.correct === false ? 'wrong-ans' : ''} review-tab`}
          >
            <Tab label="Explanation" value="explanation" />
            <Tab label="Info" value="info" />
            <Tab label="Feedback" value="feedback" />
          </Tabs>

          {selectedTab === "explanation" && (
            <Box p={3} className="tabs-cont">
              {openDrawer && guideData?.videoLinks?.length > 0 && (
                // <video controls id="video">
                //   {/* <source src={convertUrl(guideData?.videoLinks[0])} type="video/mp4"></source> */}
                //   <source src="https://youtu.be/q1TKsMM2Zmw" type="video/mp4"></source>
                // </video>
                <iframe title={guideData?.id} allow='autoplay;fullscreen' id="video" width="420" height="345"
                  src={convertUrl(guideData?.videoLinks[0])}>
                </iframe>
              )}
              <Box className="ans-item">The correct answer is <span className="correct-ans-lbl">{guideData?.option?.correctAnswer}</span></Box>

              {isAdmin &&
                <Box className="exp-text MuiTypography-body1"><ReactMarkdown>{guideData?.explanation}</ReactMarkdown></Box>
              }

              {guideData?.briefExplanation &&
                <Box className="exp-text brief-exp MuiTypography-body1 additional-exp"><ReactMarkdown>{guideData?.briefExplanation}</ReactMarkdown></Box>
              }

              {guideData?.additionalExplanation &&
                <Box className="exp-text MuiTypography-body1 additional-exp"><ReactMarkdown>{guideData?.additionalExplanation}</ReactMarkdown></Box>
              }
            </Box>
          )}

          <Box
            p={3}
            className={
              selectedTab === "resources" ? "resource-list" : "resource-list hide"
            }
          >
            {guideData?.refLinks?.map((item, index) => (
              <Link
                href={guideData?.refLinks}
                variant="body2"
                target="_blank"
                rel="noopener"
                key={index}
              >
                Reference link {index + 1}
              </Link>
            ))}

            {guideData?.videoLinks?.length > 0 && (
              <video controls id="video" preload="metadata">
                <source src={guideData?.videoLinks[0]} type="video/mp4"></source>
              </video>
            )}
          </Box>

          {selectedTab === "info" && (
            <Box p={3} className="tabs-cont">
              <Box component="div" className="info-list-items">
                <Typography className="label">Discipline</Typography>
                <Typography className="item-value">
                  {guideData?.categories?.discipline?.join(", ")}
                </Typography>
              </Box>
              <Box component="div" className="info-list-items">
                <Typography className="label"> Organ System </Typography>
                <Typography className="item-value">
                  {guideData?.categories?.organSystem?.join(", ")}
                </Typography>
              </Box>
              <Box component="div" className="info-list-items">
                <Typography className="label"> Exam Type </Typography>
                <Typography className="item-value">
                  {guideData?.categories?.examType?.join(", ")}
                </Typography>
              </Box>
              {guideData?.userEmail && isAdmin &&
                <Box component="div" className="info-list-items">
                  <Typography className="label"> Author </Typography>
                  <Typography className="item-value">
                    {guideData?.userEmail}
                  </Typography>
                </Box>
              }
              {!isTutor &&
                <>
                  <Box component="div" className="info-list-items">
                    <Typography className="label"> Time Spent Testing </Typography>
                    <Typography className="item-value">
                      {Math.floor((quizHistory?.timeSpent / 60) % 60) < 10 && 0}
                      {Math.floor((quizHistory?.timeSpent / 60) % 60)}:
                      {Math.floor(quizHistory?.timeSpent % 60) < 10 && 0}
                      {Math.floor(quizHistory?.timeSpent % 60)}
                    </Typography>
                  </Box>
                  <Box component="div" className="info-list-items">
                    <Typography className="label"> Time Spent Reviewing </Typography>
                    <Typography className="item-value">
                      {Math.floor((quizHistory?.reviewTimeSpent / 60) % 60) < 10 && 0}
                      {Math.floor((quizHistory?.reviewTimeSpent / 60) % 60)}:
                      {Math.floor(quizHistory?.reviewTimeSpent % 60) < 10 && 0}
                      {Math.floor(quizHistory?.reviewTimeSpent % 60)}
                    </Typography>
                  </Box>
                </>
              }
            </Box>
          )}

          {selectedTab === "feedback" &&
            <>
              {!isTutorRole && !isAdmin &&
                <Box p={3} className="tabs-cont">
                  {quesFeedbackArr?.length > 0 && quesFeedbackArr?.map((item, index) =>
                    <Box component="div" className="info-list-items feedback" key={index}>
                      <Box className="label1">{item?.user?.email}</Box>
                      <Box className="item-row">
                        <Typography className="item-value">
                          Error Suspected: {item?.isError === 'Y' ? 'Yes' : 'No'}
                        </Typography>
                        {item?.rating > 0 &&
                          <Typography className="item-value">
                            Quality Rating: {item?.rating}
                          </Typography>
                        }
                        {item?.topic &&
                          <Typography className="item-value">
                            Topic: {getTransformedText(item?.topic)}
                          </Typography>
                        }
                        {item?.explanationType &&
                          <Typography className="item-value">
                            Error location: {getTransformedText(item?.explanationType)}
                          </Typography>
                        }
                      </Box>

                      {item?.comment &&
                        <Typography className="item-value">
                          {item?.comment}
                        </Typography>
                      }
                    </Box>
                  )}
                  {!quesFeedbackArr?.length &&
                    <Box className="no-feedback-cont">
                      <Typography className="item-value">No feedback yet.
                      </Typography>
                      <IconButton
                        className="quesfeedback-btn"
                        onClick={() => onFeedbackOpen(true)}
                        title="Question Feedback"
                      >
                        <Forum />
                      </IconButton>
                    </Box>
                  }
                </Box>
              }
              {isAdmin &&
                <Box p={3} className="tabs-cont">
                  {quesTutorFeedbackArr?.length > 0 && quesTutorFeedbackArr.map((item, index) =>
                    <Box component="div" className="info-list-items feedback" key={index}>
                      <Box className="label1">From: {item?.user?.email}</Box>
                      <Box className="item-row-box">
                        <Box className="label-box">STEM</Box>
                        <Typography className="item-value-box">
                          Quality: {getTransformedText(item?.stem.quality)}.
                          Difficulty: {getTransformedText(item?.stem.difficulty)}.
                          Errors: {getTransformedText(item?.stem.errors)}.
                        </Typography>
                        <Box className="label-box">EXPLANATION</Box>
                        <Typography className="item-value-box">
                          Quality: {getTransformedText(item?.explanation.quality)}.
                          Errors: {getTransformedText(item?.explanation.errors)}.
                        </Typography>
                        {(item?.improvementSuggestions?.stem ||
                          item?.improvementSuggestions?.explanation ||
                          item?.improvementSuggestions?.expandedExplanation)
                          && <>
                            <Box className="label-box">Improvement Suggestions</Box>
                            {item?.improvementSuggestions?.stem &&
                              <Typography component='span' className="item-value-box">
                                Stem: {getTransformedText(item?.improvementSuggestions?.stem)}, &nbsp;
                              </Typography>
                            }
                            {item?.improvementSuggestions?.explanation &&
                              <Typography component='span' className="item-value-box">
                                Explanation: {getTransformedText(item?.improvementSuggestions?.explanation)}, &nbsp;
                              </Typography>
                            }

                            {item?.improvementSuggestions?.expandedExplanation &&
                              <Typography component='span' className="item-value-box">
                                Expanded Explanation: {getTransformedText(item?.improvementSuggestions?.expandedExplanation)}.
                              </Typography>
                            }
                          </>
                        }
                      </Box>
                    </Box>
                  )}
                  {!quesTutorFeedbackArr?.length &&
                    <Box className="no-feedback-cont">
                      <Typography className="item-value">No feedback yet
                      </Typography>

                      {/* <IconButton
                        className="quesfeedback-btn"
                        onClick={() => onFeedbackOpen(true, guideData?.id)}
                        title="Question Feedback"
                      >
                        <Forum />
                      </IconButton> */}
                    </Box>
                  }
                </Box>
              }
              {
                isTutorRole &&
                <QuestionFeedback
                  className="ques-feedbk-modal"
                  questionData={questionData}
                  isGuide={isGuide}
                  currentQuestion={currentQuestionNum} />
              }
            </>
          }
        </Box>
      }
    </>
  );
};

export default ExamReviewDrawer;
