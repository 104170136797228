import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { api } from "../services/apiService";
import type from "../actions/types";

function* addUser(action) {
    try {
        const userRes = yield call(api.addUserApi, action.payload);
        if (userRes.errStatus) {
            yield put({ type: type.ADD_USER_ERROR, status: userRes.errStatus });
        } else {
            yield put({ type: type.ADD_USER_SUCCESS, addUser: userRes });
        }
    } catch (e) {
        yield put({ type: type.ADD_USER_ERROR, message: e.message });
    }
}

function* loginUser(action) {
    try {
        const userRes = yield call(api.doLogin, action.payload);
        if (userRes.errStatus) {
            yield put({ type: type.LOGIN_USER_ERROR, status: userRes.errStatus, errMsg: userRes.errMsg });
        } else {
            yield put({ type: type.LOGIN_USER_SUCCESS, user: userRes });
        }
    } catch (e) {
        yield put({ type: type.LOGIN_USER_ERROR, message: e.message });
    }
}

function* userDetails(action) {
    try {
        const userDetailsRes = yield call(api.getUserDetails, action.payload);
        if (userDetailsRes.errStatus) {
            yield put({
                type: type.USER_DETAILS_ERROR,
                status: userDetailsRes.errStatus,
            });
        } else {
            yield put({
                type: type.USER_DETAILS_SUCCESS,
                userDetails: userDetailsRes,
            });
        }
    } catch (e) {
        yield put({ type: type.USER_DETAILS_ERROR, message: e.message });
    }
}

function* userDetailsFromToken(action) {
    try {
        const userDetailsRes = yield call(api.getUserDetailsFromToken, action.payload);
        if (userDetailsRes.errStatus) {
            yield put({
                type: type.USER_DETAILS_FROM_TOKEN_ERROR,
                status: userDetailsRes.errStatus,
            });
        } else {
            yield put({
                type: type.USER_DETAILS_FROM_TOKEN_SUCCESS,
                userDetailsFromToken: userDetailsRes,
            });
        }
    } catch (e) {
        yield put({ type: type.USER_DETAILS_FROM_TOKEN_ERROR, message: e.message });
    }
}

function* getUserActivity(action) {
    try {
        const userActivityRes = yield call(api.getUserActivity, action.payload);
        yield put({
            type: type.GET_USER_ACTIVITY_SUCCESS,
            userActivity: userActivityRes,
        });
    } catch (e) {
        yield put({ type: type.GET_USER_ACTIVITY_ERROR, message: e.message });
    }
}

function* setUserActivity(action) {
    try {
        const userActivityRes = yield call(api.setUserActivity, action.payload);
        yield put({
            type: type.SET_USER_ACTIVITY_SUCCESS,
            userActivity: userActivityRes,
        });
    } catch (e) {
        yield put({ type: type.SET_USER_ACTIVITY_ERROR, message: e.message });
    }
}

function* getUserPreference(action) {
    try {
        const userPreferenceRes = yield call(api.getPreference, action.payload);
        yield put({
            type: type.GET_PREFERENCE_SUCCESS,
            preference: userPreferenceRes
        });
    } catch (e) {
        yield put({ type: type.GET_PREFERENCE_ERROR, message: e.message });
    }
}

function* setUserPreference(action) {
    try {
        const userPreferenceRes = yield call(api.setPreference, action.payload);
        yield put({
            type: type.SET_PREFERENCE_SUCCESS,
            preference: userPreferenceRes
        });
    } catch (e) {
        yield put({ type: type.SET_PREFERENCE_ERROR, message: e.message });
    }
}

function* setFeedback(action) {
    try {
        const userFeedback = yield call(api.setFeedback, action.payload);
        if (!userFeedback.errStatus) {
            yield put({
                type: type.SET_FEEDBACK_SUCCESS,
                feedback: userFeedback
            });
        } else {
            yield put({ type: type.SET_FEEDBACK_ERROR });
        }
    } catch (e) {
        yield put({ type: type.SET_FEEDBACK_ERROR, message: e.message });
    }
}

function* watchAddUser() {
    yield takeEvery(type.ADD_USER_REQUEST, addUser);
}

function* watchLogin() {
    yield takeEvery(type.LOGIN_USER_REQUEST, loginUser);
}

function* watchUserDetails() {
    yield takeEvery(type.USER_DETAILS_REQUEST, userDetails);
}

function* watchUserDetailsFromToken() {
    yield takeEvery(type.USER_DETAILS_FROM_TOKEN_REQUEST, userDetailsFromToken);
}

function* watchGetUserActivity() {
    yield takeEvery(type.GET_USER_ACTIVITY_REQUEST, getUserActivity);
}

function* watchSetUserActivity() {
    yield takeEvery(type.SET_USER_ACTIVITY_REQUEST, setUserActivity);
}

function* watchGetUserPreference() {
    yield takeEvery(type.GET_PREFERENCE_REQUEST, getUserPreference);
}

function* watchSetUserPreference() {
    yield takeEvery(type.SET_PREFERENCE_REQUEST, setUserPreference);
}

function* watchSetFeedback() {
    yield takeEvery(type.SET_FEEDBACK_REQUEST, setFeedback);
}

export default function* userSaga() {
    yield all([
        fork(watchAddUser),
        fork(watchLogin),
        fork(watchUserDetails),
        fork(watchUserDetailsFromToken),
        fork(watchGetUserActivity),
        fork(watchSetUserActivity),
        fork(watchGetUserPreference),
        fork(watchSetUserPreference),
        fork(watchSetFeedback),
    ]);
}