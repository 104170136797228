import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import CreateQuizPage from "./pages/create-quiz/create-quiz-page";
import ExamPage from "./pages/exam/exam";
import ExamResult from "./pages/exam-result/exam-result";
import OverallPerformance from "./pages/overall-performance/overall-performance";
import QuizHistory from "./pages/quiz-history/quiz-history";
import Login from "./pages/login/login";
import ForgetPwd from "./pages/login/forgetPwd";
import { AuthRedirect } from "./pages/login/googleAuthRedirect";
import Register from "./pages/login/register";
import SubscriptionsView from "./pages/subscriptions/subscriptions";
import AdminPlanView from "./pages/admin/plans";
import AdminView from "./pages/admin/admin";
import FeedbackView from "./pages/feedback/feedback";
import FeedbackDashboardView from "./pages/feedback-dashboard/feedback-dashboard";
import Refund from './pages/admin/refund';

const AppRoutes = () => {

  return (
    <div>
      <Router>
        <FeedbackView></FeedbackView>
        <Switch>
          <Route exact path="/create-quiz">
            <CreateQuizPage />
          </Route>
          <Route exact path="/exam/:examId/:currentQuestion">
            <ExamPage />
          </Route>
          <Route exact path="/exam/:examId/:currentQuestion/:review">
            <ExamPage />
          </Route>
          <Route exact path="/exam/:examId/:currentQuestion/tutor/:tutor">
            <ExamPage />
          </Route>
          <Route exact path="/exam/:examId/:currentQuestion/tutor/:tutor/guide/:guide">
            <ExamPage />
          </Route>
          <Route exact path="/result/:examId">
            <ExamResult />
          </Route>
          <Route exact path="/overall-performance">
            <OverallPerformance />
          </Route>
          <Route exact path="/quiz-history">
            <QuizHistory />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/socialAuth">
            <AuthRedirect />
          </Route>
          <Route exact path="/subscriptions">
            <SubscriptionsView />
          </Route>
          <Route exact path="/plans">
            <SubscriptionsView isPlan={true} />
          </Route>
          <Route exact path="/payment/success/:bookingId">
            <SubscriptionsView />
          </Route>
          <Route exact path="/payment/failure/:bookingId">
            <SubscriptionsView />
          </Route>
          <Route exact path="/manage-plans">
            <AdminPlanView />
          </Route>
          <Route exact path="/refund">
            <Refund />
          </Route>
          <Route exact path="/reset-password">
            <ForgetPwd hasId={true} />
          </Route>
          <Route exact path="/forget-password">
            <ForgetPwd hasId={false} />
          </Route>
          <Route exact path="/verify-user/:id">
            <Register verify={true} />
          </Route>
          <Route exact path="/register">
            <Register verify={false} />
          </Route>
          <Route exact path="/admin-portal">
            <AdminView verify={false} />
          </Route>
          <Route exact path="/feedback-dashboard">
            <FeedbackDashboardView />
          </Route>
          <Route path="/" exact>
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default AppRoutes;
