import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Modal,
  Fade,
  Backdrop,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPlan,
  getUserPackage,
  getActivePlans
} from "../../actions";
import moment from "moment";
import "./subscriptions-list.scss";

export default function CurrentPlanView(props) {
  const { activePackage, paymentHistory, subcriptionEndConfirm, activePlans, showPlanDetails } = props;
  const [endPlanId, setEndPlanId] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();


  const selectPlanRes = useSelector((state) => {
    return state.selectPlan;
  });

  const onSubcriptionEnd = (type, id = null) => {
    setOpen(type);

    if (id) {
      setEndPlanId(id);
    }
  };

  const onSubcriptionEndConfirm = () => {
    subcriptionEndConfirm(endPlanId);
    onSubcriptionEnd(false);
  };

  useEffect(() => {
    if (selectPlanRes?.loading === false) {
      const userId = window.localStorage.getItem("userId");
      dispatch(getUserPackage(userId));
      dispatch(getActivePlans(userId));
    }
  }, [dispatch, selectPlanRes]);

  const onSelectPlan = (row) => {
    const userId = window.localStorage.getItem("userId");

    dispatch(selectPlan({
      userId,
      planId: row?.plan?.id
    }));
  }

  return (
    <Box className="sub-wrapper current-plan">
      <Backdrop open={activePlans?.loading || false} className="quiz-loader">
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className="table-cont-wrapper">
        <Box className="plan-table">
          <Typography variant="h6" className="title">
            Active Plans
            <Typography variant="caption" className="sub-title">Select to switch active plan</Typography>
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Plan</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>Renewal Date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activePlans?.plansList?.length > 0 &&
                <>
                  {activePlans?.plansList?.map((row, index) => (
                    <TableRow key={index} className={`${row.iselected ? 'selected' : ''} ${row.expired ? 'expired-plan-row' : ''}`}>
                      <TableCell>
                        {row?.plan?.name}
                        {row.iselected && !row.expired &&
                          <Typography variant="caption" className="show-plan-details"
                            onClick={() => showPlanDetails(row)}>Show Details</Typography>
                        }
                      </TableCell>
                      <TableCell>
                        ${row?.plan?.price}
                      </TableCell>
                      {/* <TableCell>
                          {row.status}
                        </TableCell> */}
                      <TableCell>
                        {!row.expired &&
                          <>{row.subscriptionEndDt && moment(row.subscriptionStartDt).format("ll")}</>
                        }
                      </TableCell>
                      <TableCell>
                        <>
                          {!row.expired && row?.plan?.category?.toLowerCase() !== 'free' &&
                            <>{row.subscriptionEndDt && moment(row.subscriptionEndDt).format("ll")}</>
                          }
                          {row.expired &&
                            <>Expired on ({moment(row.subscriptionEndDt).format("ll")})</>
                          }
                        </>
                      </TableCell>
                      <TableCell className="active-actions">
                        {!row.iselected && !row.expired &&
                          <Button variant="contained" color="primary" onClick={() => onSelectPlan(row)}>
                            Select
                          </Button>
                        }
                        {row.iselected && !row.expired &&
                          <Box className="active-cell">
                            ACTIVE
                            {row.status === 'CANCELLED' &&
                              <Box component="span" className="cancel-date">(until {moment(row.subscriptionEndDt).format("MMM D")})</Box>
                            }
                          </Box>
                        }
                        {!row.expired && row.status !== 'CANCELLED'
                          && row?.plan?.category?.toLowerCase() !== 'free' &&
                          <Button variant="contained" className="end-sub-btn" onClick={() => onSubcriptionEnd(true, row?.subscriptionId)}>
                            Cancel
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              }
              {(!activePlans?.plansList || activePlans?.plansList?.length === 0) &&
                <TableRow><TableCell colSpan="6" className="row-center">No active subscriptions found.</TableCell></TableRow>
              }
            </TableBody>
          </Table>
        </Box>
      </Box>

      <Box className="table-cont-wrapper">
        {/* <KeyboardBackspace className="nav-btn" onClick={() => setShowBilling(false)} color="primary" /> */}
        <Box className="plan-table">
          <Typography variant="h6" className="title">
            Billing History
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Plan</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Order Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentHistory && paymentHistory?.length > 0 &&
                <>
                  {paymentHistory.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {row?.plan?.name}
                      </TableCell>
                      <TableCell>
                        {row?.plan?.category}
                      </TableCell>
                      <TableCell>
                        ${row.price}
                      </TableCell>
                      <TableCell>
                        {row.status}
                      </TableCell>
                      <TableCell>
                        {row?.subscriptionStartDate && moment(row?.subscriptionStartDate).format("ll")}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              }
              {(!paymentHistory || paymentHistory?.length === 0) &&
                <TableRow><TableCell colSpan="6" className="row-center">No billing history found</TableCell></TableRow>
              }
            </TableBody>
          </Table>
        </Box>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className='coupon-modal sub-coupon-modal end-sub-modal'
        open={open}
        onClose={() => onSubcriptionEnd(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box className='modal-container'>
            <Typography variant="h5" className="header">End Subscription?</Typography>
            <Typography variant="body2" >
              You will lose access to this subscription after the expiration date.
            </Typography>

            <Box className="apply-btn">
              <Button variant="contained" onClick={() => onSubcriptionEnd(false)}>
                No
              </Button>
              <Button variant="contained" className="end-sub-btn" color="primary" onClick={onSubcriptionEndConfirm}>
                Yes
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box >
  );
}
