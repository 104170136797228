import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { api } from "../services/apiService";
import type from "../actions/types";

function* getInvitations() {
    try {
        const invitationRes = yield call(api.getInvitations);
        yield put({
            type: type.GET_INVITATION_SUCCESS,
            invitationList: invitationRes
        });
    } catch (e) {
        yield put({ type: type.GET_INVITATION_ERROR, message: e.message });
    }
}

function* sendInvitation(action) {
    try {
        const sendInvitationRes = yield call(api.sendInvitation, action.payload);
        yield put({
            type: type.SEND_INVITATION_SUCCESS,
            sendInvitation: sendInvitationRes
        });
    } catch (e) {
        yield put({ type: type.SEND_INVITATION_ERROR, message: e.message });
    }
}

function* resendInvitation(action) {
    try {
        const resendInvitationRes = yield call(api.resendInvitation, action.payload);
        yield put({
            type: type.RESEND_INVITATION_SUCCESS,
            resendInvitation: resendInvitationRes
        });
    } catch (e) {
        yield put({ type: type.RESEND_INVITATION_ERROR, message: e.message });
    }
}

function* getComment(action) {
    try {
        const commentRes = yield call(api.getComment, action.payload);
        yield put({
            type: type.GET_COMMENT_SUCCESS,
            comments: commentRes
        });
    } catch (e) {
        yield put({ type: type.GET_COMMENT_ERROR, message: e.message });
    }
}

function* setComment(action) {
    try {
        const commentRes = yield call(api.setComment, action.payload);
        yield put({
            type: type.SET_COMMENT_SUCCESS,
            comments: commentRes
        });
    } catch (e) {
        yield put({ type: type.SET_COMMENT_ERROR, message: e.message });
    }
}

function* getTutorFeedbackAll() {
    try {
        const feedbackRes = yield call(api.getTutorFeedbackAll);
        yield put({
            type: type.GET_TUTOR_FEEDBACK_ALL_SUCCESS,
            feedbacks: feedbackRes
        });
    } catch (e) {
        yield put({ type: type.GET_TUTOR_FEEDBACK_ALL_ERROR, message: e.message });
    }
}

function* getStudentFeedbackAll() {
    try {
        const feedbackRes = yield call(api.getStudentFeedbackAll);
        yield put({
            type: type.GET_STUDENT_FEEDBACK_ALL_SUCCESS,
            feedbacks: feedbackRes
        });
    } catch (e) {
        yield put({ type: type.GET_STUDENT_FEEDBACK_ALL_ERROR, message: e.message });
    }
}

function* getAllPlans() {
    try {
        const planRes = yield call(api.getAllPlans);
        yield put({
            type: type.GET_ALL_PLANS_SUCCESS,
            plans: planRes
        });
    } catch (e) {
        yield put({ type: type.GET_ALL_PLANS_ERROR, message: e.message });
    }
}
function* createPlan(action) {
    try {
        const planRes = yield call(api.createPlan, action.payload);
        yield put({
            type: type.CREATE_PLAN_SUCCESS,
            plan: planRes
        });
    } catch (e) {
        yield put({ type: type.CREATE_PLAN_ERROR, message: e.message });
    }
}
function* updatePlan(action) {
    try {
        const planRes = yield call(api.updatePlan, action.payload);
        yield put({
            type: type.UPDATE_PLAN_SUCCESS,
            plan: planRes
        });
    } catch (e) {
        yield put({ type: type.UPDATE_PLAN_ERROR, message: e.message });
    }
}
function* deletePlan(action) {
    try {
        const planRes = yield call(api.deletePlan, action.payload);
        yield put({
            type: type.DELETE_PLAN_SUCCESS,
            plan: planRes
        });
    } catch (e) {
        yield put({ type: type.DELETE_PLAN_ERROR, message: e.message });
    }
}

function* sendInvites(action) {
    try {
        const sendInvitationRes = yield call(api.sendInvites, action.payload);
        yield put({
            type: type.SEND_INVITES_SUCCESS,
            sendInvites: sendInvitationRes
        });
    } catch (e) {
        yield put({ type: type.SEND_INVITES_ERROR, message: e.message });
    }
}

function* getUsersByRole() {
    try {
        const usersByRoleRes = yield call(api.getUsersByRole);
        yield put({
            type: type.GET_USERS_BY_ROLE_SUCCESS,
            usersByRole: usersByRoleRes
        });
    } catch (e) {
        yield put({ type: type.GET_USERS_BY_ROLE_ERROR, message: e.message });
    }
}

function* getPaymentsByUser(action) {
    try {
        const paymentsByUserRes = yield call(api.getPaymentsByUser, action.payload);
        yield put({
            type: type.GET_PAYMENTS_BY_USER_SUCCESS,
            paymentsByUser: paymentsByUserRes
        });
    } catch (e) {
        yield put({ type: type.GET_PAYMENTS_BY_USER_ERROR, message: e.message });
    }
}

function* refundBooking(action) {
    try {
        const refundBookingRes = yield call(api.refundBooking, action.payload);
        yield put({
            type: type.SET_REFUND_BOOKING_SUCCESS,
            refundBooking: refundBookingRes
        });
    } catch (e) {
        yield put({ type: type.SET_REFUND_BOOKING_ERROR, message: e.message });
    }
}

function* watchGetInvitations() {
    yield takeEvery(type.GET_INVITATION_REQUEST, getInvitations);
}

function* watchSendInvitation() {
    yield takeEvery(type.SEND_INVITATION_REQUEST, sendInvitation);
}

function* watchResendInvitation() {
    yield takeEvery(type.RESEND_INVITATION_REQUEST, resendInvitation);
}

function* watchGetComment() {
    yield takeEvery(type.GET_COMMENT_REQUEST, getComment);
}

function* watchSetComment() {
    yield takeEvery(type.SET_COMMENT_REQUEST, setComment);
}

function* watchGetTutorFeedbackAll() {
    yield takeEvery(type.GET_TUTOR_FEEDBACK_ALL_REQUEST, getTutorFeedbackAll);
}

function* watchGetStudentFeedbackAll() {
    yield takeEvery(type.GET_STUDENT_FEEDBACK_ALL_REQUEST, getStudentFeedbackAll);
}

function* watchGetAllPlans() {
    yield takeEvery(type.GET_ALL_PLANS_REQUEST, getAllPlans);
}

function* watchCreatePlan() {
    yield takeEvery(type.CREATE_PLAN_REQUEST, createPlan);
}

function* watchUpdatePlan() {
    yield takeEvery(type.UPDATE_PLAN_REQUEST, updatePlan);
}

function* watchDeletePlan() {
    yield takeEvery(type.DELETE_PLAN_REQUEST, deletePlan);
}

function* watchSendInvites() {
    yield takeEvery(type.SEND_INVITES_REQUEST, sendInvites);
}

function* watchGetUsersByRole() {
    yield takeEvery(type.GET_USERS_BY_ROLE_REQUEST, getUsersByRole);
}

function* watchGetPaymentsByUser() {
    yield takeEvery(type.GET_PAYMENTS_BY_USER_REQUEST, getPaymentsByUser);
}

function* watchRefundBooking() {
    yield takeEvery(type.SET_REFUND_BOOKING_REQUEST, refundBooking);
}

export default function* adminSaga() {
    yield all([
        fork(watchGetInvitations),
        fork(watchSendInvitation),
        fork(watchResendInvitation),
        fork(watchGetComment),
        fork(watchSetComment),
        fork(watchGetTutorFeedbackAll),
        fork(watchGetStudentFeedbackAll),
        fork(watchGetAllPlans),
        fork(watchCreatePlan),
        fork(watchUpdatePlan),
        fork(watchDeletePlan),
        fork(watchSendInvites),
        fork(watchGetUsersByRole),
        fork(watchGetPaymentsByUser),
        fork(watchRefundBooking),
    ]);
}