import types from "../actions/types";
import { CONSTANTS } from "../constants/app-constant";
const initialState = {};

export function getInvitations(state = initialState, action) {
    switch (action.type) {
        case types.GET_INVITATION_REQUEST:
            return { loading: true };
        case types.GET_INVITATION_SUCCESS:
            return { invitationList: action.invitationList, loading: false };
        case types.GET_INVITATION_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function sendInvitation(state = initialState, action) {
    switch (action.type) {
        case types.SEND_INVITATION_RESET:
            return initialState;
        case types.SEND_INVITATION_REQUEST:
            return { loading: true };
        case types.SEND_INVITATION_SUCCESS:
            return { sendInvitation: action.sendInvitation, loading: false };
        case types.SEND_INVITATION_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function resendInvitation(state = initialState, action) {
    switch (action.type) {
        case types.RESEND_INVITATION_RESET:
            return initialState;
        case types.RESEND_INVITATION_REQUEST:
            return { loading: true };
        case types.RESEND_INVITATION_SUCCESS:
            return { resendInvitation: action.resendInvitation, loading: false };
        case types.RESEND_INVITATION_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function getComment(state = initialState, action) {
    switch (action.type) {
        case types.GET_COMMENT_REQUEST:
            return { loading: true };
        case types.GET_COMMENT_SUCCESS:
            return { comments: action.comments, loading: false };
        case types.GET_COMMENT_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function setComment(state = initialState, action) {
    switch (action.type) {
        case types.SET_COMMENT_REQUEST:
            return { loading: true };
        case types.SET_COMMENT_SUCCESS:
            return { newComment: action.comments, loading: false };
        case types.SET_COMMENT_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function getTutorFeedbackAll(state = initialState, action) {
    switch (action.type) {
        case types.GET_TUTOR_FEEDBACK_ALL_REQUEST:
            return { loading: true };
        case types.GET_TUTOR_FEEDBACK_ALL_SUCCESS:
            return { feedbacks: action.feedbacks, loading: false };
        case types.GET_TUTOR_FEEDBACK_ALL_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function getStudentFeedbackAll(state = initialState, action) {
    switch (action.type) {
        case types.GET_STUDENT_FEEDBACK_ALL_REQUEST:
            return { loading: true };
        case types.GET_STUDENT_FEEDBACK_ALL_SUCCESS:
            return { feedbacks: action.feedbacks, loading: false };
        case types.GET_STUDENT_FEEDBACK_ALL_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function getAllPlans(state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_PLANS_REQUEST:
            return { loading: true };
        case types.GET_ALL_PLANS_SUCCESS:
            return { plans: action.plans, loading: false };
        case types.GET_ALL_PLANS_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function createPlan(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_PLAN_RESET:
            return initialState;
        case types.CREATE_PLAN_REQUEST:
            return { loading: true };
        case types.CREATE_PLAN_SUCCESS:
            return { plan: action.plan, loading: false };
        case types.CREATE_PLAN_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function updatePlan(state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_PLAN_RESET:
            return initialState;
        case types.UPDATE_PLAN_REQUEST:
            return { loading: true };
        case types.UPDATE_PLAN_SUCCESS:
            return { plan: action.plan, loading: false };
        case types.UPDATE_PLAN_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function deletePlan(state = initialState, action) {
    switch (action.type) {
        case types.DELETE_PLAN_RESET:
            return initialState;
        case types.DELETE_PLAN_REQUEST:
            return { loading: true };
        case types.DELETE_PLAN_SUCCESS:
            return { plan: action.plan, loading: false };
        case types.DELETE_PLAN_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function sendInvites(state = initialState, action) {
    switch (action.type) {
        case types.SEND_INVITES_RESET:
            return initialState;
        case types.SEND_INVITES_REQUEST:
            return { loading: true };
        case types.SEND_INVITES_SUCCESS:
            return { sendInvites: action.sendInvites, loading: false };
        case types.SEND_INVITES_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function getUsersByRole(state = initialState, action) {
    switch (action.type) {
        case types.GET_USERS_BY_ROLE_REQUEST:
            return { loading: true };
        case types.GET_USERS_BY_ROLE_SUCCESS:
            return { usersByRole: action.usersByRole, loading: false };
        case types.GET_USERS_BY_ROLE_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}

export function getPaymentsByUser(state = initialState, action) {
    switch (action.type) {
        case types.GET_PAYMENTS_BY_USER_REQUEST:
            return { loading: true };
        case types.GET_PAYMENTS_BY_USER_SUCCESS:
            return { paymentsByUser: action.paymentsByUser, loading: false };
        case types.GET_PAYMENTS_BY_USER_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}


export function refundBooking(state = initialState, action) {
    switch (action.type) {
        case types.SET_REFUND_BOOKING_REQUEST:
            return { loading: true };
        case types.SET_REFUND_BOOKING_SUCCESS:
            return { refundBooking: action.refundBooking, loading: false };
        case types.SET_REFUND_BOOKING_ERROR:
            const msgCode = action.status || 500;
            const msg = CONSTANTS.errorMsgs[msgCode];

            return { errorMsg: msg, loading: false };
        default:
            return state;
    }
}