import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  FormControl,
  LinearProgress,
  Button,
  NativeSelect,
  InputBase,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import "./overall-performance-header.scss";

const OverallPerformanceHeader = (props) => {
  const { navigateToReview, performanceData, quizHistoryList, quizDetails } =
    props;

  const [quizSelected, setQuizSelected] = useState("");

  const handleChange = (event) => {
    setQuizSelected(event.target.value);
  };

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  const latestQuiz = quizHistoryList && quizHistoryList[0];

  return (
    <Box component="div" className="overall-performance-header">
      <Box component="div" className="upper-header">
        <Box component="div" m={1} className="header-sidebar-left">
          <Typography component="h4" variant="h4" className="title">
            Overall Performance
          </Typography>
          <Typography component="h6" variant="h6" className="sub-title">
            Performance by Recent Test
          </Typography>
        </Box>
        <Box component="div" className="quiz-percent-cont">
          <Box component="div" className="quiz-percent-progress">
            <CircularProgress
              variant="determinate"
              className="circular-progress-bkg"
              size={80}
              thickness={2}
              value={100}
            />
            <CircularProgress
              variant="determinate"
              size={80}
              thickness={2}
              value={performanceData?.correctPercentage}
            />
            <Box component="div" className="quiz-progress-text">
              <Box component="div" className="quiz-progress-text-num">
                {performanceData?.correctPercentage}%
              </Box>
              CORRECT
            </Box>
          </Box>
          <Box component="div" className="report-details-wrapper">
            <Box component="div" className="report-details">
              <Box component="span" className="quiz-progress-text-bold">
                {performanceData?.questionCorrect}
              </Box>
              CORRECT
            </Box>
            <Box component="div" className="report-details">
              <Box component="span" className="quiz-progress-text-bold">
                {performanceData?.questionIncorrect}
              </Box>
              INCORRECT
            </Box>
          </Box>
        </Box>
      </Box>

      {quizHistoryList &&
        <Box component="div" className="lower-header">
          <Box
            component="div"
            m={1}
            className="left-sidebar"
            onClick={() => navigateToReview(latestQuiz?.id)}
          >
            <Box component="div" className="sidebar-header">
              <Box component="div" className="sidebar-header-left">
                <Typography component="h6" variant="h6">
                  {latestQuiz?.name}
                </Typography>
                <Typography variant="overline" display="block">
                  {moment(quizDetails?.quizSubmitDate).format("lll")}
                </Typography>
                <Typography
                  variant="overline"
                  display="block"
                  className="sub-title"
                >
                  Tutor {latestQuiz?.tutorOn ? "On" : "Off"} - Timer
                  {latestQuiz?.timerOn ? " On" : " Off"}
                </Typography>
              </Box>
              <Box component="div" m={1} className="sidebar-header-right">
                <Typography variant="subtitle1" className="sub-title-blue">
                  REVIEW
                </Typography>
                <Typography variant="body1">
                  <strong>{quizDetails?.correctPercentage}%</strong> Correct
                </Typography>
              </Box>
            </Box>
            <LinearProgress variant="determinate" value={25} />
          </Box>
          <Box component="div" className="right-sidebar">
            <Typography component="h6" variant="h6">
              All Quizzes:
            </Typography>
            <FormControl className="dropdown">
              <NativeSelect
                id="demo-customized-select"
                value={quizSelected}
                onChange={handleChange}
                input={<BootstrapInput />}
              >
                {quizHistoryList?.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className="review-btn"
              onClick={() => navigateToReview(quizSelected || latestQuiz.id)}
            >
              Review
            </Button>
          </Box>
        </Box>
      }
    </Box>
  );
};

export default OverallPerformanceHeader;
