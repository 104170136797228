import { useState, useEffect } from "react";
import { Box, Backdrop, CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import ExamResultOverview from "../../components/exam-result-overview/exam-result-overview";
import ExamResultTable from "../../components/exam-result-table/exam-result-table";
import ExamResultCategory from "../../components/exam-result-category/exam-result-category";
import {
  getQuizHistory,
  getQuiz,
  getQuestionHistory,
  getUserActivity,
  getUserPackage,
  resetQuestionHistory
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import Menu from "../../pages/menu/menu";

export default function ExamPage() {
  const [quizDetails, setQuizDetails] = useState({});
  const [quizHistoryData, setQuizHistoryData] = useState({});
  const [questionHistoryData, setQuestionHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [isReviewLimit, setIsReviewLimit] = useState(false);
  // const [open, setOpen] = useState(false);
  let { examId } = useParams();
  const dispatch = useDispatch();

  const quizDetailsData = useSelector((state) => {
    return state.quizDetails?.quizDetails;
  });

  const quizHistory = useSelector((state) => {
    return state.quizHistory?.quizHistory;
  });

  const questionHistory = useSelector((state) => {
    return state.questionHistory;
  });

  // const userActivity = useSelector((state) => {
  //   return state.userActivity?.userActivity;
  // });

  // const userPackage = useSelector((state) => {
  //   return state.userPackage?.userPackage;
  // });

  useEffect(() => {
    return () => dispatch(resetQuestionHistory())
  }, [dispatch]);


  useEffect(() => {
    setTimeout(() => {
      dispatch(getQuestionHistory(examId));
    }, 300);
    dispatch(getQuiz(examId));
    dispatch(getQuizHistory(examId));

    const savedUserId = window.localStorage.getItem("userId");

    if (savedUserId && savedUserId !== "undefined") {
      dispatch(getUserActivity(savedUserId));
      dispatch(getUserPackage(savedUserId));
    }
  }, [dispatch, examId]);

  useEffect(() => {
    if (quizDetailsData) {
      setQuizDetails(quizDetailsData);
    }
  }, [quizDetailsData]);

  useEffect(() => {
    if (quizHistory) {
      setQuizHistoryData(quizHistory);
    }
  }, [quizHistory]);

  useEffect(() => {
    if (questionHistory?.loading !== undefined) {
      setIsLoading(questionHistory?.loading);
    }

    if (questionHistory?.questionHistory) {
      setQuestionHistoryData(questionHistory.questionHistory);
    }
  }, [questionHistory]);

  // useEffect(() => {
  //   if (userActivity && userPackage !== undefined) {
  //     const currentPkg = userPackage.filter(item => item.status === 'ACTIVE');

  //     const reviewLimit = currentPkg.length ? currentPkg[0]?.packages?.videoExplanationLimit - userActivity.videoAccessCount :
  //       (userPackage.length === 1 ? userPackage[0]?.packages.videoExplanationLimit - userActivity.videoAccessCount : 0);

  //     if (reviewLimit <= 0) {
  //       // setOpen(true);
  //       setIsReviewLimit(true);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userActivity, userPackage]);


  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <Box component="div" className="body-cont-over">
      {/* <Snackbar open={open} onClose={handleClose} autoHideDuration={8000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Box className="sub-alert">
          You need to upgrade your subscription. Your review limit has exceeded...
        </Box>
      </Snackbar> */}

      <Menu />

      <Backdrop open={isLoading} className="result-loader">
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box component="div" className='exam-result'>
        <ExamResultOverview
          examId={examId}
          quizOverview={quizHistoryData}
          quizDetails={quizDetails}
        />
        <ExamResultCategory quizCategoryData={quizHistoryData} />
        <ExamResultTable examId={examId} questions={questionHistoryData} />
      </Box>
    </Box>
  );
}
