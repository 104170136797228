import React, { useState, Fragment } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TableRow,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  Paper,
  Collapse,
  CircularProgress,
  Button,
  TableSortLabel
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import PropTypes from 'prop-types';
import "./overall-performance-tabs.scss";

const OverallPerformanceTabs = (props) => {
  const { performanceData } = props;
  const [selectedTab, setSelectedTab] = useState("discipline");
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('subCategory');

  const headCells = [
    { id: 'subCategory', align: 'left', label: 'TOPICS' },
    { id: 'percentageCorrect', align: 'center', label: 'PERCENTAGE' },
    { id: 'correctCount', align: 'center', label: 'QUESTIONS CORRECT' },
    { id: 'incorrectCount', align: 'center', label: 'QUESTIONS INCORRECT' },
  ];

  const handleChange = (event, newValue) => {
    setOrderBy('subCategory');
    setOrder('asc');
    setSelectedTab(newValue);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (fullArray, comparator) => {
    const array = fullArray?.filter(item => item.category === selectedTab)[0]?.subcategories;
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className='visually-hidden'>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <Fragment>
        <TableRow
          className={open ? "table-top-row open" : "table-top-row closed"}
        >
          <TableCell onClick={() => setOpen(!open)}>
            <Button color="primary" className="collapse-btn">
              {row.subCategory}
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>
          </TableCell>
          <TableCell align="center">{row.percentageCorrect}</TableCell>
          <TableCell align="center">{row.correctCount}</TableCell>
          <TableCell align="center">{row.incorrectCount}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="collapse-cell" colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={2}>
                <Typography variant="h6" component="div">
                  Topic Summary
                </Typography>
                <Box component="div" className="collapse-content-wrapper">
                  <Box component="div" className="quiz-percent-cont">
                    <Box component="div" className="quiz-percent-progress">
                      <CircularProgress
                        variant="determinate"
                        className="circular-progress-bkg"
                        size={80}
                        thickness={2}
                        value={100}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={row.percentageCorrect}
                        size={80}
                        thickness={2}
                      />
                      <Box component="div" className="quiz-progress-text">
                        <Box component="div" className="quiz-progress-text-num">
                          {row.percentageCorrect}%
                        </Box>
                        CORRECT
                      </Box>
                    </Box>
                    <Box component="div" className="quiz-percent-progress">
                      <strong className="pad-right">
                        {row.correctCount}/
                        {row.correctCount + row.incorrectCount}
                      </strong>
                      QUESTIONS
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
  };

  return (
    <Box component="div" m={1} className="overall-performance-tabs">
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        className="review-tab"
      >
        <Tab label="Discipline" value="discipline" />
        <Tab label="Organ System" value="organSystem" />
        <Tab label="Exam Type" value="examType" />
      </Tabs>

      <Box className="tab-container">
        <TableContainer component={Paper}>
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {performanceData?.length > 0 && stableSort(performanceData, getComparator(order, orderBy))
                .map((item, i) => {
                  return (
                    <Fragment key={i}>
                      <Row row={item} />
                    </Fragment>
                  )
                }
                )}
              {(!performanceData || performanceData?.length === 0) &&
                <tr>
                  <td className="no-data" colSpan={6}>
                    No data found
                  </td>
                </tr>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default OverallPerformanceTabs;
