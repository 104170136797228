// import { useState } from "react";
import LoginView from "../../components/login/login";
import { login } from "../../actions";
import { connect } from "react-redux";

let Login = ({ dispatch, onLogin }) => {
  const onSignIn = (data) => {
    dispatch(login(data));
  };

  return (
    <LoginView
      onLoginSubmit={onSignIn}
      onLoginRequest={onLogin}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    onLogin: state.loggedInUser,
  };
};

Login = connect(mapStateToProps)(Login);

export default Login;
