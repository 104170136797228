import { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { LiveHelp, Restore, Poll, Menu, ExitToApp, Forum, Brightness2, Brightness4, CreditCard, BarChart, AttachMoney } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPreference, setPreference, menuOpenStatus } from "../../actions";
import mixpanel from 'mixpanel-browser';
import themes from '../../constants/themes';
const logo = require("../../images/logo_dark.png").default;

const drawerWidth = 190;
let menuArr = [
  { name: "Create Quiz", route: "/create-quiz", icon: () => <LiveHelp /> },
  { name: "Quiz History", route: "/quiz-history", icon: () => <Restore /> },
  {
    name: "Performance",
    route: "/overall-performance",
    icon: () => <Poll />,
  },
  {
    name: "Subscriptions",
    route: "/subscriptions",
    icon: () => <CreditCard />,
  }
];

const menuArrCopy = [...menuArr];

export default function MenuView() {
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentTheme, setCurrentTheme] = useState('light');
  const dispatch = useDispatch();

  const userPreferenceData = useSelector((state) => {
    return state.setUserPreference?.preference || state.getUserPreference?.preference;
  });

  const userDetailsFromToken = useSelector((state) => {
    return state.userDetailsFromToken?.userDetails;
  });

  const menuStatus = useSelector((state) => {
    return state.menuOpenStatus?.open;
  });

  const [open, setOpen] = useState(menuStatus);

  const setMenuOnRole = (role) => {
    if (role === 'ROLE_ADMIN') {
      setIsAdmin(true);

      if (menuArr.length === 4) {
        menuArr.push({
          name: "Feedbacks",
          route: "/feedback-dashboard",
          icon: () => <Forum />
        });
        menuArr.push({
          name: "Manage Plans",
          route: "/manage-plans",
          icon: () => <BarChart />
        });
        menuArr.push({
          name: "Refund",
          route: "/refund",
          icon: () => <AttachMoney />
        });
      }
    } else {
      menuArr = [...menuArrCopy];
    }

    if (role === 'ROLE_ADMIN' || role === 'ROLE_TUTOR') {
      menuArr.splice(3, 1);
    }
  }

  useEffect(() => {
    let role = window.localStorage.getItem("role");

    if (role) {
      setMenuOnRole(role);
    } else {
      setTimeout(() => {
        role = window.localStorage.getItem("role");
        setMenuOnRole(role);
      }, 500);
    }

    return () => {
      menuArr = [...menuArrCopy];
    }
  }, []);

  useEffect(() => {
    if (menuStatus !== undefined && open !== menuStatus) {
      setOpen(menuStatus);
    }
  }, [menuStatus, open]);

  useEffect(() => {
    const id = window.localStorage.getItem("userId") || userDetailsFromToken?.id;

    if (userPreferenceData === undefined && id) {
      dispatch(getPreference(id));
    } else if (id) {
      const newTheme = userPreferenceData?.darkTheme ? 'dark' : 'light';
      setCurrentTheme(newTheme);
      setTheme(newTheme);
    }
  }, [dispatch, userPreferenceData, userDetailsFromToken]);

  const toggleDrawerOpen = () => {
    setOpen(!open);
    dispatch(menuOpenStatus({ open: !open }));
  };

  const goToHome = () => {
    history.push('/create-quiz');
  };

  const onThemeChange = () => {
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    setCurrentTheme(newTheme);
    setTheme(newTheme);
    const userId = window.localStorage.getItem("userId");
    dispatch(setPreference({ userId, body: { darkTheme: newTheme === 'dark' } }));
  };

  const setTheme = (newTheme) => {
    const theme = themes[newTheme];
    Object.keys(theme).forEach((key) => {
      const cssKey = `--${key}`;
      const cssValue = theme[key];
      document.body.style.setProperty(cssKey, cssValue);
    });
  }

  const onMenuSelect = (route) => {
    const userinfos = JSON.parse(localStorage.getItem('userdetails'));
    const userrole = window.localStorage.getItem("role");
    if (route === "logout") {
      dispatch({
        type: "LOGOUT",
      });
      mixpanel.track("Menu-sign-out", {
        $name: userinfos?.name,
        email: userinfos?.email,
        role: userrole
      });
      window.localStorage.clear();
      history.push('login');
      return;
    }

    window.analytics.track('Menu item opened', {
      menuName: route
    });

    const menuuserinfosstring = route.slice(1);
    mixpanel.track("Menu-" + menuuserinfosstring, {
      $name: userinfos?.name,
      email: userinfos?.email,
      role: userrole
    });

    history.push(route);
  };

  const useStyles = makeStyles(() => ({
    active: {
      backgroundColor: "#12aade",
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      backgroundColor: "#232384",
      color: "#fff",
      width: drawerWidth,
      overflow: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: "#232384",
      color: "#fff",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.down(600)]: {
        width: '40px'
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      [theme.breakpoints.down(600)]: {
        padding: 0
      }
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    icon: {
      color: "#fff",
      minWidth: 41,
      [theme.breakpoints.down(600)]: {
        paddingLeft: 6,
        paddingRight: 6,
      }
    },
    itemText: {
      fontSize: 12,
      textTransform: 'uppercase'
    },
    logout: {
      position: "absolute",
      bottom: 0,
      cursor: "pointer",
    },
    menuLogo: {
      width: '90px',
      height: '60px',
      [theme.breakpoints.down(600)]: {
        width: '50px',
        height: '40px',
      }
    },
    menuBtn: {
      [theme.breakpoints.down(600)]: {
        paddingLeft: 3,
        paddingRight: 3,
      }
    }
  }));
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <img alt="logo" src={logo} className={classes.menuLogo}
        onClick={goToHome} style={{ marginLeft: -15, cursor: 'pointer' }}></img>

      <div className={classes.toolbar}>
        <IconButton onClick={toggleDrawerOpen} className={classes.icon}>
          <Menu />
        </IconButton>
      </div>
      <List>
        {menuArr.map((item, index) => (
          <ListItem
            button
            key={index}
            onClick={() => onMenuSelect(item.route)}
            className={clsx({
              [classes.active]: item.route === currentRoute,
            }, classes.menuBtn)}
          >
            <ListItemIcon className={classes.icon}>{item.icon()}</ListItemIcon>
            <ListItemText primary={item.name} className={classes.itemText} />
          </ListItem>
        ))}
      </List>

      <List className={classes.logout}>
        <ListItem
          button
          onClick={() => onThemeChange()}
          className={classes.menuBtn}
        >
          <ListItemIcon className={classes.icon}>
            {currentTheme === 'light' && <Brightness2 />}
            {currentTheme !== 'light' && <Brightness4 />}
          </ListItemIcon>
          <ListItemText primary={currentTheme === 'light' ? 'Dark Theme' : 'Light Theme'} className={classes.itemText} />
        </ListItem>
        <ListItem onClick={() => onMenuSelect("logout")}
          className={classes.menuBtn}>
          <ListItemIcon className={classes.icon}>
            <ExitToApp />{" "}
          </ListItemIcon>
          <ListItemText className={classes.itemText} primary="Sign out" />
        </ListItem>
      </List>
    </Drawer>
  );
}
