import { useState, useEffect } from "react";
import RegisterView from "../../components/login/register";
import { addUser } from "../../actions";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import url from "../../constants/urlList";
import { Box, Typography, Button } from "@material-ui/core";
const sheildImg = require("../../images/sheild.png").default;
const sheildErrorImg = require("../../images/sheild-wrong.png").default;

let Register = ({ dispatch, registerRequest, verify }) => {
  const [isVerifySuccess, setIsVerifySuccess] = useState(true);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  const onRegister = (data) => {
    delete data.confirmPassword;
    dispatch(addUser(data));
  };

  useEffect(() => {
    if (verify) {
      verifyAccount(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, verify]);

  const verifyAccount = (id) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };

    setLoading(true);

    return fetch(url.verifyAccount + id, requestOptions)
      .then((response) => {
        setLoading(false);
        setIsVerifySuccess(response.status === 200);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const goToLogin = () => {
    history.push("/login");
  }

  return (
    <>
      {!verify &&
        <RegisterView
          onRegisterSubmit={onRegister}
          registerRequest={registerRequest}
        />
      }
      {verify && !loading &&
        <Box className="login-body">
          <Box className="login-container">
            {isVerifySuccess &&
              <>
                <img alt="google" src={sheildImg} width='100' height='100'></img>
                <Typography variant="h6" className="verify-text">
                  ACCOUNT VERIFIED
                </Typography>
                <Typography className="verify-text">
                  Your account has been successfully verified. Thank you for helping us battle scammers.
                  We hope you enjoy our service in Qlearn.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className="register-btn"
                  onClick={goToLogin}
                >
                  Go to Login
                </Button>
              </>
            }
            {!isVerifySuccess &&
              <>
                <img alt="google" src={sheildErrorImg} width='100' height='100'></img>
                <Typography variant="h6" className="verify-text">
                  ACCOUNT NOT VERIFIED
                </Typography>
                <Typography className="verify-text">
                  Account cannot be verified due to invalid link opened or the link has expired.
                  Please try to register again.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className="register-btn"
                  onClick={goToLogin}
                >
                  Go to Login
                </Button>
              </>
            }
          </Box>
        </Box>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    registerRequest: state.registerUser,
  };
};

Register = connect(mapStateToProps)(Register);

export default Register;
